import React from "react";

const RefundPolicy = () => {
  return (
    <div style={styles.container}>
      <h1>Refund Policy</h1>
      <p>
        <strong>Effective Date:</strong> [Insert Date]
      </p>
      <p>
        Thank you for choosing WedMySoul for venue showcase. We strive to
        provide exceptional service and support for all our vendors. This refund
        policy outlines the terms and conditions regarding refunds for
        subscription fees.
      </p>

      <h2>1. No Refunds Policy</h2>
      <ul>
        <li>
          <strong>3-Month Subscriptions:</strong> All 3-month subscription fees
          are non-refundable. Once the subscription is purchased, no refunds
          will be issued.
        </li>
        <li>
          <strong>6-Month Subscriptions:</strong> All 6-month subscription fees
          are non-refundable. Once the subscription is purchased, no refunds
          will be issued.
        </li>
        <li>
          <strong>12-Month Subscriptions:</strong> All 12-month subscription
          fees are non-refundable. Once the subscription is purchased, no
          refunds will be issued.
        </li>
      </ul>

      <h2>2. Subscription Cancellation</h2>
      <p>
        You may cancel your subscription at any time. However, cancellation will
        not result in a refund. Your subscription will remain active until the
        end of the current billing cycle.
      </p>

      <h2>3. Non-Refundable Situations</h2>
      <ul>
        <li>
          Subscriptions canceled at any point during the subscription period.
        </li>
        <li>Partial refunds for unused portions of a subscription.</li>
        <li>
          Fees associated with additional services or features purchased
          separately from the subscription plan.
        </li>
      </ul>

      <h2>4. Changes to the Refund Policy</h2>
      <p>
        WedMySoul reserves the right to modify this refund policy at any time.
        Any changes will be posted on our website, and it is your responsibility
        to review this policy periodically.
      </p>

      <h2>5. Contact Us</h2>
      <p>
        If you have any questions or concerns about our refund policy, please
        contact us at wmsenquiry@usglobalsolutions.com.
      </p>
      <p>
        By subscribing to WedMySoul, you acknowledge that you have read and
        agree to this refund policy.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
};

export default RefundPolicy;
