import React, { useState, useEffect } from "react";
import "./Signin.css";
import signinImage from "../../img/signInImage.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  TextField,
  Box,
  Grid,
  FormControl,
  FormLabel,
  InputAdornment,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useFormik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import * as Yup from "yup";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

const defaultTheme = createTheme();

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

export default function Signin() {
  const [ContinueWith, setContinueWith] = useState(false);
  const [submitWith, setSubmitWith] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [emailMobile, setEmailMobile] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const inputValue = event.target.value;

    const truncatedValue = /^\d/.test(inputValue)
      ? inputValue.slice(0, 10)
      : inputValue;

    setEmailMobile(truncatedValue);
    const mobileRegex = /^[0-9]{10}$/;
    setIsMobile(mobileRegex.test(truncatedValue));
    setError("");
    if (inputValue.length === 0) {
      setEmailMobile("");
      setContinueWith(false);
      setSubmitWith(false);
      formik.handleReset();
    }
  };

  const handleBlur = () => {
    const inputValue = emailMobile.trim();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;

    if (!inputValue) {
      setError("Please enter email or mobile number");
    } else if (emailRegex.test(inputValue) || mobileRegex.test(inputValue)) {
      setError("");
    } else {
      setError("Invalid email or mobile number");
    }
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is required"),

    email: isMobile
      ? Yup.string()
          .email("Invalid email address")
          .required("Email is required")
      : Yup.string(),

    mobilenumber: !isMobile
      ? Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, "Must be at least 10 digits")
          .max(10, "Must be at most 10 digits")
          .required("Mobile number is required")
      : Yup.string(),
    OTP: Yup.string().required("OTP is required"),
  });

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      mobilenumber: "",
      Name: "",
      OTP: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isMobile) {
        values.mobilenumber = emailMobile;
      } else {
        values.email = emailMobile;
      }
    },
  });

  const handleChange1 = (e) => {
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  const [otpTimer, setOtpTimer] = useState(30);
  const [timerId, setTimerId] = useState(null);

  const handleContinueClick = () => {
    if (ContinueWith) {
      setSubmitWith(true);
      setTimerId(
        setInterval(() => setOtpTimer((prevTimer) => prevTimer - 1), 1000)
      );
    } else {
      setContinueWith(true);
    }
  };

  useEffect(() => {
    if (otpTimer > 0) {
      const id = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      setTimerId(id);

      return () => {
        clearInterval(id);
      };
    } else {
      // Handle timer expiration, maybe trigger a resend OTP function
    }
  }, [otpTimer]);

  const handleResendOtp = () => {
    // Logic to resend OTP
    setOtpTimer(30); // Reset timer to 30 seconds
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container className="mainGridSignIn">
        <CssBaseline />
        <Grid item xs={false} sm={0} md={4.5}>
          <img
            src={signinImage}
            alt="signinImage"
            height="500px"
            width="100%"
            className="signinImage"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7.5}
          sx={{
            border: "1px solid orange",
            height: "500px",
          }}
        >
          <Box
            className="signInbox"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#3B2400",
                fontFamily: "Poppins-Medium",
              }}
              className="sigInHeader"
            >
              SIGN IN / SIGN UP
            </p>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
                width: "100%",
              }}
              onSubmit={formik.handleSubmit}
            >
              <div>
                <FormControl sx={{ width: "100%" }} fullWidth>
                  <TextField
                    disabled={ContinueWith}
                    labelId="demo-simple-select-label"
                    fullWidth
                    required
                    placeholder="Enter email or mobile *"
                    id="emailMobile"
                    name="emailMobile"
                    value={emailMobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                      margin: "0",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#867760",
                        },
                      },
                    }}
                    InputLabelProps={{
                      style: { color: "#8A724C" },
                    }}
                    InputProps={{
                      style: { color: "#867760" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon
                            sx={{ color: "#F5AA35", mr: 0.7 }}
                          />{" "}
                          |
                        </InputAdornment>
                      ),
                      endAdornment: emailMobile ? (
                        <InputAdornment
                          sx={{ cursor: "pointer" }}
                          position="end"
                          onClick={() => {
                            setEmailMobile("");
                            setContinueWith(false);
                            setSubmitWith(false);
                            formik.handleReset();
                          }}
                        >
                          <CancelIcon />
                        </InputAdornment>
                      ) : null,
                    }}
                    error={Boolean(error)}
                    helperText={error}
                  />
                </FormControl>

                {submitWith ? (
                  <>
                    <FormControl sx={{ width: "100%", mt: 3 }} fullWidth>
                      <FormLabel sx={{ fontWeight: "bold" }}></FormLabel>
                      <TextField
                        labelId="demo-simple-select-label"
                        fullWidth
                        placeholder="Enter OTP*"
                        id="OTP"
                        name="OTP"
                        type="number"
                        value={formik.values.OTP}
                        onChange={handleChange1}
                        onBlur={formik.handleBlur}
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        error={formik.touched.OTP && Boolean(formik.errors.OTP)}
                        helperText={formik.touched.OTP && formik.errors.OTP}
                        sx={{
                          margin: "0",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#867760",
                            },
                          },
                        }}
                        InputLabelProps={{
                          style: { color: "#8A724C" },
                        }}
                        InputProps={{
                          style: { color: "#867760" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                width="25"
                                height="25"
                                src="https://img.icons8.com/ios/50/000000/sms-token.png"
                                alt="sms-token"
                                style={{ color: "#F5AA35" }}
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment>
                              {otpTimer > 0 ? (
                                <p>{otpTimer} sec</p>
                              ) : (
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={handleResendOtp}
                                >
                                  Resend OTP
                                </p>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <p
                      style={{
                        margin: 0,
                        color: "#604720",
                        textAlign: "left",
                        width: "100%",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      One time password has been sent to your{" "}
                      <span
                        style={{
                          margin: 0,
                          color: "#F5AA35",
                        }}
                      >
                        {" "}
                        {isMobile ? "number." : "email."}
                      </span>
                    </p>
                  </>
                ) : ContinueWith ? (
                  <>
                    <FormControl sx={{ width: "100%", mt: 2 }} fullWidth>
                      <TextField
                        labelId="demo-simple-select-label"
                        fullWidth
                        required
                        placeholder="Enter Name*"
                        id="Name"
                        name="Name"
                        value={formik.values.Name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.Name && Boolean(formik.errors.Name)
                        }
                        helperText={formik.touched.Name && formik.errors.Name}
                        sx={{
                          margin: "0",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#867760",
                            },
                          },
                        }}
                        InputLabelProps={{
                          style: { color: "#8A724C" },
                        }}
                        InputProps={{
                          style: { color: "#867760" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon
                                sx={{ color: "#F5AA35", mr: 0.7 }}
                              />{" "}
                              |
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    {isMobile ? (
                      <FormControl sx={{ width: "100%", mt: 2 }} fullWidth>
                        <FormLabel sx={{ fontWeight: "bold" }}></FormLabel>
                        <TextField
                          labelId="demo-simple-select-label"
                          fullWidth
                          required
                          placeholder="Enter email*"
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          sx={{
                            margin: "0",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#867760",
                              },
                            },
                          }}
                          InputLabelProps={{
                            style: { color: "#8A724C" },
                          }}
                          InputProps={{
                            style: { color: "#867760" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon sx={{ color: "#F5AA35" }} />{" "}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ) : (
                      <FormControl sx={{ width: "100%", mt: 2 }} fullWidth>
                        <FormLabel sx={{ fontWeight: "bold" }}></FormLabel>
                        <TextField
                          labelId="demo-simple-select-label"
                          fullWidth
                          required
                          placeholder="Enter mobile number *"
                          id="mobilenumber"
                          name="mobilenumber"
                          type="number"
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          value={formik.values.mobilenumber}
                          onChange={handleChange1}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.mobilenumber &&
                            Boolean(formik.errors.mobilenumber)
                          }
                          helperText={
                            formik.touched.mobilenumber &&
                            formik.errors.mobilenumber
                          }
                          sx={{
                            margin: "0",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#867760",
                              },
                            },
                          }}
                          InputLabelProps={{
                            style: { color: "#8A724C" },
                          }}
                          InputProps={{
                            style: { color: "#867760" },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SmartphoneIcon sx={{ color: "#F5AA35" }} />{" "}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    )}
                  </>
                ) : null}
              </div>

              <div style={{ width: "100%" }}>
                {emailMobile ? (
                  <Button
                    variant="outlined"
                    sx={{
                      color: "black",
                      backgroundColor: "#F5AA35",
                      borderColor: "#F5AA35",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      width: "100%",
                      borderRadius: 0,
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#F5AA35",
                        borderColor: "#F5AA35",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        width: "100%",
                        borderRadius: 0,
                      },
                    }}
                    onClick={() => {
                      if (ContinueWith) {
                        setSubmitWith(true);
                        setTimerId(
                          setInterval(
                            () => setOtpTimer((prevTimer) => prevTimer - 1),
                            1000
                          )
                        );
                      } else {
                        setContinueWith(true);
                      }
                      // navigate(ROUTINGDATA.VENDORSIGNIN);
                    }}
                    disabled={Boolean(error)}
                    type={submitWith ? "submit" : "ok"}
                  >
                    Continue
                  </Button>
                ) : (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        color: "#3B2400",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        width: "100%",
                      }}
                    >
                      {/* <Divider>OR</Divider> */}
                    </div>
                    {/* <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#3B2400",
                        fontFamily: "Poppins-Medium",
                        marginTop: "0",
                        textAlign: "center",
                      }}
                    >
                      Continue With
                    
                    </p> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        width: "100%",
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        className="signInButtons"
                        sx={{
                          color: "#403421",
                          borderColor: "#A38D6B",
                          fontFamily: "Poppins-Medium",
                          transition: "all 0.3s",
                          borderRadius: 0,
                          py: 1,
                          "&:hover": {
                            color: "#403421",
                            borderColor: "#A38D6B",
                            fontFamily: "Poppins-Medium",
                            transition: "all 0.3s",
                            borderRadius: 0,
                            py: 1,
                          },
                        }}
                      >
                        <img
                          width="25"
                          height="25"
                          src="https://img.icons8.com/cute-clipart/25/facebook-new.png"
                          alt="facebook-new"
                          style={{ marginRight: "5px" }}
                        />
                        Facebook
                      </Button> */}
                      {/* <Button
                        variant="outlined"
                        className="signInButtons"
                        sx={{
                          color: "#403421",
                          borderColor: "#A38D6B",
                          fontFamily: "Poppins-Medium",
                          transition: "all 0.3s",
                          borderRadius: 0,
                          py: 1,
                          "&:hover": {
                            color: "#403421",
                            borderColor: "#A38D6B",
                            fontFamily: "Poppins-Medium",
                            transition: "all 0.3s",
                            borderRadius: 0,
                            py: 1,
                          },
                        }}
                      >
                        <img
                          width="25"
                          height="25"
                          src="https://img.icons8.com/color/25/google-logo.png"
                          alt="google-logo"
                          style={{ marginRight: "5px" }}
                        />
                        Google
                      </Button> */}
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Button
                  className="signInButtons"
                  sx={{
                    color: "#3B2400",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    borderRadius: 0,
                    "&:hover": {
                      color: "#3B2400",
                      borderColor: "#A38D6B",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      borderRadius: 0,
                    },
                  }}
                >
                  Are you a vendor?
                </Button>
                <Button
                  variant="outlined"
                  className="signInButtons"
                  sx={{
                    color: "black",
                    backgroundColor: "#F5AA35",
                    borderColor: "#F5AA35",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    borderRadius: "4px",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#F5AA35",
                      borderColor: "#F5AA35",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      borderRadius: "4px",
                    },
                  }}
                  onClick={() => {
                    navigate(ROUTINGDATA.VENDORSIGNIN);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Business sign in
                </Button>
              </div>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
