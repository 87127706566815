import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ROUTINGDATA } from "./components/ROUTINGDATA";
import Landingpage from "./components/Landingpage";
import Homepage from "./components/services/Homepage";
import Venuespage from "./components/services/Venuespage";
import VenuesPageDetails from "./components/services/VenuesPageDetails";
import Signin from "./components/Login/Signin";
import VendorSignin from "./components/Login/VendorSignin";
import VendorSignup from "./components/Login/VendorSignup";
import VendorPassword from "./components/Login/VendorPassword";
import VendorOtp from "./components/Login/VendorOtp";
import VendorConform from "./components/Login/VendorConform";
import VenuesDashboard from "./components/VendorsDashboard/VenuesDashboard";
import VendorsHomepage from "./components/services/Vendorshomepage/VendorsHomepage";
import VendorsPhotograph from "./components/services/Vendorshomepage/VendorsPhotograph";
import VendorsDecorators from "./components/services/Vendorshomepage/VendorsDecorators";
import Venuespages from "./components/Venderpages/VenueListing";
import Addportfoilo from "./components/Venderpages/Addportfoilo";
import Addphotos from "./components/Venderpages/Addphotos";
import EditVenues from "./components/Venderpages/EditVenues";
import Contactus from "./components/ContactUs/Contactus";
import PricingPolicy from "./components/Policies/PricingPolicy";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import PurchasePolicy from "./components/Policies/PurchasePolicy";
import RefundPolicy from "./components/Policies/RefundPolicy";
import TermsAndConditions from "./components/Policies/TermsAndConditions";
import ShippingPolicy from "./components/Policies/ShippingPolicy";
//import { HashRouter, Routes, Route, Switch } from "react-router-dom";
import SubscriptionPlan from "./components/SubscriptionPlan";
import ForgotPassword from "./components/Login/ForgotPassword";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import Dashboard from "./components/Dashboard/Dashboard";
import AdminVenuesPage from "./components/Dashboard/AdminVenuesPage";
import AdminApprovalVenue from "./components/Dashboard/AdminApprovalVenue";
import AdminApprovalPhoto from "./components/Dashboard/AdminApprovalPhoto";
import AdminVenueApproval from "./components/Dashboard/AdminVenueApproval";
import ApprovalPhotoVideo from "./components/Dashboard/ApprovalPhotoVideo";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path={ROUTINGDATA.ADMINDASHBOARD} element={<AdminDashboard />}>
            <Route path={ROUTINGDATA.ADMINDASHBOARD} element={<Dashboard />} />
            <Route
              path={ROUTINGDATA.AdminVenuesPage}
              element={<AdminVenuesPage />}
            />
            <Route
              path={ROUTINGDATA.ADMINVenueApproval}
              element={<AdminApprovalVenue />}
            />
            <Route
              path={ROUTINGDATA.ADMINVenueApprovalPhoto}
              element={<AdminApprovalPhoto />}
            />
            <Route
              path={`${ROUTINGDATA.ADMINVENUESPAGEDETAILS}/:venueId`}
              element={<VenuesPageDetails />}
            />
            <Route
              path={`${ROUTINGDATA.AdminApprovalVenue}/:venueId`}
              element={<AdminVenueApproval />}
            />
            <Route
              path={`${ROUTINGDATA.ApprovalPhotoVideo}/:venueId`}
              element={<ApprovalPhotoVideo />}
            />
          </Route>
          <Route path={ROUTINGDATA.LAND} element={<Landingpage />}>
            <Route
              path={ROUTINGDATA.SUBSCRIPTIONPLAN}
              element={<SubscriptionPlan />}
            />
            <Route path={ROUTINGDATA.SIGNIN} element={<Signin />} />
            <Route
              path={ROUTINGDATA.FORGOTPASSWORD}
              element={<ForgotPassword />}
            />
            <Route path={ROUTINGDATA.VENDORSIGNIN} element={<VendorSignin />} />
            <Route path={ROUTINGDATA.VENDORSIGNUP} element={<VendorSignup />} />
            <Route
              path={ROUTINGDATA.VENDORPASSWORD}
              element={<VendorPassword />}
            />
            <Route path={ROUTINGDATA.VENDOROTP} element={<VendorOtp />} />
            <Route
              path={ROUTINGDATA.VENDORCONFORM}
              element={<VendorConform />}
            />
            <Route path={ROUTINGDATA.HOMEPAGE} element={<Homepage />} />
            <Route path={ROUTINGDATA.CHARACTER} element={<Venuespages />} />
            <Route path={ROUTINGDATA.PLANNER} element={<Addportfoilo />} />
            {/* <Route path="/Subcription" element={<Subcription/>}/> */}
            <Route path={ROUTINGDATA.ADDPHOTOS} element={<Addphotos />} />
            <Route path={ROUTINGDATA.EDITS} element={<EditVenues />} />
            <Route
              path={ROUTINGDATA.VENUESPAGE}
              element={<Venuespage />}
            ></Route>{" "}
            <Route path={ROUTINGDATA.CONTACTS} element={<Contactus />} />
            <Route
              path={ROUTINGDATA.VENUESPAGEDETAILS}
              element={<VenuesPageDetails />}
            />
            {/* vendors dashboard */}
            <Route
              path={ROUTINGDATA.VENUESDASHBOARD}
              element={<VenuesDashboard />}
            />
            <Route
              path={ROUTINGDATA.VENDORSPAGE}
              element={<VendorsHomepage />}
            />
            <Route
              path={ROUTINGDATA.VENDORSPHOTOGRAPH}
              element={<VendorsPhotograph />}
            />
            <Route
              path={ROUTINGDATA.VENDORSDECPRATORS}
              element={<VendorsDecorators />}
            />
            <Route
              path={ROUTINGDATA.PRICINGPOLICY}
              element={<PricingPolicy />}
            />
            <Route
              path={ROUTINGDATA.PRIVACYPOLICY}
              element={<PrivacyPolicy />}
            />
            <Route
              path={ROUTINGDATA.PURCHASEPOLICY}
              element={<PurchasePolicy />}
            />
            <Route path={ROUTINGDATA.REFUNDPOLICY} element={<RefundPolicy />} />
            <Route
              path={ROUTINGDATA.TERMSANDCONDITIONS}
              element={<TermsAndConditions />}
            />
            <Route path={ROUTINGDATA.SHIPPING} element={<ShippingPolicy />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
