import React, { useState, useEffect } from "react";
import heroimg from "../../img/hero-img.png";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Container,
  Divider,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import locationicon from "../../img/search-icon/location.png";
import gest from "../../img/search-icon/healthicons_travel-alt.png";
import roommemebericon from "../../img/search-icon/fluent_person-24-filled.png";
import Economy_class from "../../img/search-icon/mdi_seat-recline-extra.png";
import details1 from "../../img/details-img/wedding-venues.jpg";
import details2 from "../../img/details-img/wedding-vendors.jpg";
import details3 from "../../img/details-img/hotel.jpg";
import details4 from "../../img/details-img/CHARTER-AIRLINe.jpg";
import details5 from "../../img/details-img/image 52.png";
import details6 from "../../img/details-img/photograph.jpeg";
import venue1 from "../../img/venues-imgs/venue-1.png";
import venue2 from "../../img/venues-imgs/venue-2.png";
import venue3 from "../../img/venues-imgs/venue-3.png";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import vendorimg1 from "../../img/vendors-img/img-1.png";
import vendorimg2 from "../../img/vendors-img/img-2.png";
import vendorimg3 from "../../img/vendors-img/img-3.png";
import hotels1 from "../../img/hotels-img/hotel1.png";
import hotels2 from "../../img/hotels-img/hotel2.png";
import hotels3 from "../../img/hotels-img/hotel3.png";
import airline1 from "../../img/airline-img/img1.png";
import airline2 from "../../img/airline-img/img-2.png";
import airline3 from "../../img/airline-img/img-3.png";
import airline4 from "../../img/airline-img/img-4.png";
import airline5 from "../../img/airline-img/img-5.png";
import airline6 from "../../img/airline-img/img-6.png";
import country1 from "../../img/country-img/img-1.png";
import country2 from "../../img/country-img/img-2.png";
import country3 from "../../img/country-img/img-3.png";
import country4 from "../../img/country-img/img-4.png";
import country5 from "../../img/country-img/img-5.png";
import country6 from "../../img/country-img/img-6.png";
import SearchIcon from "@mui/icons-material/Search";
import Lottie from "lottie-react";
import animationData from "../../img/lotties-files/RIGHT.json";

import useAxios from "../../useAxios";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useTheme } from "@mui/material/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

// import './styles.css';
import { Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Height } from "@mui/icons-material";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const itemData = [
  {
    img: airline1,
    title: "Breakfast",
    cols: 2,
  },
  {
    img: airline2,
    title: "Burger",
    cols: 2,
    rows: 2,
  },
  {
    img: airline3,
    title: "Camera",
    cols: 2,
  },
  {
    img: airline4,
    title: "Coffee",
    cols: 2,
  },
  {
    img: airline5,
    title: "Hats",
  },
  {
    img: airline6,
    title: "Honey",
    author: "@arwinneil",
  },
];

const Homepage = () => {
  const theme = useTheme();
  const [searchvalue, setSearchvalue] = useState("VENUES");
  const [venueDatas, setVenueDatas] = useState([]);
  const [venueImages, setVenueImages] = useState([venue1, venue2, venue3]);
  const axiosData = useAxios();
  const navigate = useNavigate();

  const top100Films = [
    { label: "The Shawshank Redemption" },
    { label: "The Godfather" },
    { label: "The Godfather: Part II" },
    { label: "The Dark Knight" },
  ];

  const placeholderStyle = {
    fontFamily: "Poppins-Medium",
    padding: " 5px 15px", // Change this to your desired font family
    color: "#5c3800",
  };
  const fetchVenues = async () => {
    try {
      const getOneAxios = await axiosData.get(`venues/venues/topListing`);
      const data = getOneAxios?.data;
      if (data) {
        data.reverse();
        setVenueDatas(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  useEffect(() => {
    fetchVenues();
  }, []);

  return (
    <div>
      <div className="hero-img-block">
        <img src={heroimg} alt="" className="homePageImage" />

        <div className="overlay"></div>
        <div className="search-content">
          <h3>Create your Dream Wedding</h3>
          <p>Because the best moments in life deserve the best</p>
          <div className="search-input">
            {searchvalue === "VENUES" ? (
              <div className="input-field-search">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  disableClearable
                  size="small"
                  sx={{ "& fieldset": { border: "none" }, width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Location"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={locationicon} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={top100Films}
                  sx={{ "& fieldset": { border: "none" }, width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Venues type"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={top100Films}
                  sx={{ "& fieldset": { border: "none" }, width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="date"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={top100Films}
                  sx={{ "& fieldset": { border: "none" }, width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Location ?"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={gest} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            ) : searchvalue === "ROOMS" ? (
              <div className="input-field-search">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  disableClearable
                  size="small"
                  sx={{ "& fieldset": { border: "none" }, width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Where are you going "
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={locationicon} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={top100Films}
                  sx={{ "& fieldset": { border: "none" }, width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="date"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                      }}
                    />
                  )}
                />

                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  disableClearable
                  size="small"
                  sx={{ "& fieldset": { border: "none" }, width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="2 Adults - 1 Room"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={roommemebericon} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
              </div>
            ) : (
              <div className="input-field-search">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  disableClearable
                  size="small"
                  sx={{ "& fieldset": { border: "none" }, width: 220 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="From destination"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={locationicon} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  disableClearable
                  size="small"
                  sx={{ "& fieldset": { border: "none" }, width: 220 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="To destination"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={locationicon} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={top100Films}
                  sx={{ "& fieldset": { border: "none" }, width: 220 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="date"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                      }}
                    />
                  )}
                />

                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  disableClearable
                  size="small"
                  sx={{ "& fieldset": { border: "none" }, width: 220 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="2 Adults - 1 Room"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={roommemebericon} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  disableClearable
                  size="small"
                  sx={{ "& fieldset": { border: "none" }, width: 220 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Economy"
                      InputProps={{
                        ...params.InputProps,
                        style: placeholderStyle,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className="img-size-search"
                          >
                            <img src={Economy_class} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            )}
            <Button className="search-btn">SEARCH</Button>
            <div className="btns-for-services">
              <Button
                className={
                  searchvalue === "VENUES" ? "seach-btns-active" : "seach-btns"
                }
                onClick={() => {
                  setSearchvalue("VENUES");
                }}
              >
                <text>VENUES</text>
              </Button>
              <Button
                className={
                  searchvalue === "ROOMS" ? "seach-btns-active" : "seach-btns"
                }
                onClick={() => {
                  setSearchvalue("ROOMS");
                }}
              >
                <text>ROOMS</text>
              </Button>
              <Button
                className={
                  searchvalue === "CHARTERS"
                    ? "seach-btns-active"
                    : "seach-btns"
                }
                onClick={() => {
                  setSearchvalue("CHARTERS");
                }}
              >
                <text>CHARTERS</text>
              </Button>
            </div>
          </div>
        </div>
        <div className="mobile-search">
          <div className="icon-mobile-search">
            <SearchIcon sx={{ color: "#fff" }} />
          </div>
          <div>Search</div>
          <div>
            <Lottie animationData={animationData} style={{ width: "30px" }} />
          </div>
        </div>
      </div>
      <div id="details" className="details">
        <Container>
          <div className="details-block-split">
            <div className="details-box">
              <div className="img-box-details">
                <img src={details1} alt="" />
              </div>
              <div className="title-details">
                <h3>2550+</h3>
                <h3>WEDDING VENUES</h3>
              </div>
            </div>
            <div className="details-box">
              <div className="img-box-details">
                <img src={details2} alt="" />
              </div>
              <div className="title-details">
                <h3>1050+</h3>
                <h3>VENDORS</h3>
              </div>
            </div>
            <div className="details-box">
              {" "}
              <div className="img-box-details">
                <img src={details3} alt="" />
              </div>
              <div className="title-details">
                <h3>2890+</h3>
                <h3>HOTELS</h3>
              </div>
            </div>
            <div className="details-box">
              {" "}
              <div className="img-box-details">
                <img src={details4} alt="" />
              </div>
              <div className="title-details">
                <h3>CHARTERED</h3>
                <h3>FLIGHTS</h3>
              </div>
            </div>
            <div className="details-box">
              {" "}
              <div className="img-box-details">
                <img src={details5} alt="" />
              </div>
              <div className="title-details">
                <h3>BEST</h3>
                <h3>PLANNERS</h3>
              </div>
            </div>
            <div className="details-box">
              {" "}
              <div className="img-box-details">
                <img src={details6} alt="" />
              </div>
              <div className="title-details">
                <h3>BEST</h3>
                <h3>PHOTOGRAPHERS</h3>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div id="venues-block" className="venues-block">
        <div className="common-header-title">
          <h3>Wedding Venues</h3>
          <p>Find the perfect venue for the featured list.</p>
        </div>
        <Container>
          <div className="split-venues-box">
            {venueDatas &&
              venueDatas.map((data, index) => (
                <div
                  className="venues-box"
                  onClick={() => {
                    navigate("/" + ROUTINGDATA.VENUESPAGEDETAILS, {
                      state: {
                        id: data._id,
                        name: data.name,
                        getImage:
                          index === 3 ? venueImages[0] : venueImages[index],
                      },
                    });
                  }}
                >
                  <div className="img-venue">
                    <img
                      src={index === 3 ? venueImages[0] : venueImages[index]}
                      alt=""
                    />
                  </div>
                  <div className="venue-content">
                    <Tooltip title={data.brand_name} arrow>
                      <h3>{data.brand_name}</h3>
                    </Tooltip>
                    <div className="venue-para1">
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <StarRateRoundedIcon
                          sx={{
                            fontSize: 19,
                            color: "#F5AA35",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      <text>
                        4.5<span>(10)</span>
                      </text>{" "}
                      <span className="lastword-venue"> . {data.address}</span>
                    </div>
                    <div className="venue-para2">
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <Groups2RoundedIcon
                          sx={{
                            fontSize: 23,
                            color: "#3F3F3F",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      Capacity {data.capacity_persons} guest .
                    </div>
                    <div className="venue-para3">
                      From <span>${data.min_price_day}</span>
                      <text>/Per day</text>
                    </div>
                  </div>
                  <div className="bage-name-venues">
                    {" "}
                    {data.primary_venue_type.toLowerCase().includes("lawn")
                      ? "Lawn"
                      : data.primary_venue_type
                          .toLowerCase()
                          .includes("banquet")
                      ? "Banquet"
                      : data.primary_venue_type
                          .toLowerCase()
                          .includes("resort") ||
                        data.primary_venue_type
                          .toLowerCase()
                          .includes("restaurant")
                      ? "Resort"
                      : "Party Hall"}
                  </div>
                </div>
              ))}
            {/* <div className="venues-box">
              <div className="img-venue">
                <img src={venue1} alt="" />
              </div>
              <div className="venue-content">
                <h3>BlissfulBarns</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Vegator, Goa</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  Capacity 300 to 500 guest .<text> Lawn</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  <text>/Per day</text>
                </div>
              </div>
            </div> */}
            {/* <div className="venues-box">
              <div className="img-venue">
                <img src={venue2} alt="" />
              </div>
              <div className="venue-content">
                <h3>HarmonyHaven</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Vegator, Goa</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  Capacity 300 to 500 guest .<text> Party Hall</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  <text>/Per day</text>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={venue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>EnchantingEstate</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Vegator, Goa</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  Capacity 300 to 500 guest .<text> Resort</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  <text>/Per day</text>
                </div>
              </div>
            </div> */}
          </div>
          <div className="venue-all-btn">
            <text
              className="btn-venue-done"
              onClick={() => {
                navigate(ROUTINGDATA.VENUESPAGE);
              }}
            >
              {" "}
              View all venues <EastRoundedIcon sx={{ fontSize: 14 }} />
            </text>
          </div>
        </Container>
      </div>
      <div id="Vendors-block" className="Vendors-block">
        <div className="common-header-title">
          <h3>Wedding Vendors</h3>
          <p>
            Get the best rates from Event Managers, Photographers, Makeup
            artists & much, much more!
          </p>
        </div>
        <div className="swiper-center">
          <Swiper
            slidesPerView={3}
            spaceBetween={40}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1350: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            <SwiperSlide className="slide-box">
              {" "}
              <div className="venues-box">
                <div className="img-venue">
                  <img src={vendorimg1} alt="" />
                </div>
                <div className="venue-content">
                  <h3>Blueburry Photography</h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>

                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-box">
              {" "}
              <div className="venues-box">
                <div className="img-venue">
                  <img src={vendorimg2} alt="" />
                </div>
                <div className="venue-content">
                  <h3>Colours </h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>
                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-box">
              <div className="venues-box">
                <div className="img-venue">
                  <img src={vendorimg3} alt="" />
                </div>
                <div className="venue-content">
                  <h3>Frames Photo</h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>
                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-box">
              <div className="venues-box">
                <div className="img-venue">
                  <img src={vendorimg1} alt="" />
                </div>
                <div className="venue-content">
                  <h3>Colours </h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>
                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <Container>
          <div className="vendors-btns-box">
            <text>Photographers</text>
            <text>Decorators</text>
            <text>Caterers</text>
            <text>Wedding planners</text>
          </div>
        </Container>
        <div className="venue-all-btn">
          <text className="btn-venue-done">
            {" "}
            View all venues <EastRoundedIcon sx={{ fontSize: 14 }} />
          </text>
        </div>
      </div>
      <div id="hotels-block" className="hotels-block">
        <div className="common-header-title">
          <h3>Featured Hotels</h3>
          <p>Highly rated hotels with impeccable service</p>
        </div>
        <div className="swiper-center">
          <Swiper
            slidesPerView={3}
            spaceBetween={40}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            <SwiperSlide className="slide-box">
              {" "}
              <div className="venues-box">
                <div className="img-venue">
                  <img src={hotels1} alt="" />
                </div>
                <div className="venue-content">
                  <h3>BlissfulBarns</h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>

                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-box">
              {" "}
              <div className="venues-box">
                <div className="img-venue">
                  <img src={hotels2} alt="" />
                </div>
                <div className="venue-content">
                  <h3>HarmonyHaven </h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>
                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-box">
              <div className="venues-box">
                <div className="img-venue">
                  <img src={hotels3} alt="" />
                </div>
                <div className="venue-content">
                  <h3>EnchantingEstate</h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>
                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide-box">
              <div className="venues-box">
                <div className="img-venue">
                  <img src={hotels1} alt="" />
                </div>
                <div className="venue-content">
                  <h3>BlissfulBarns</h3>
                  <div className="venue-para1">
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <StarRateRoundedIcon
                        sx={{
                          fontSize: 19,
                          color: "#F5AA35",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <text>
                      4.5<span>(10)</span>
                    </text>{" "}
                    <span className="lastword-venue"> . Vegator, Goa</span>
                  </div>
                  <div className="venue-para3">
                    From <span>$1000</span>
                    <text>/Per day</text>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="venue-all-btn">
          <text className="btn-venue-done">
            {" "}
            View all venues <EastRoundedIcon sx={{ fontSize: 14 }} />
          </text>
        </div>
      </div>
      <div id="airline-block" className="airline-block">
        <div className="common-header-title">
          <h3>Chartered Flights</h3>
          <p>Book a private aircraft for your guests</p>
        </div>

        <Container>
          <div className="img-center-airline">
            <ImageList
              variant="quilted"
              cols={6}
              rowHeight={210}
              style={{ display: "grid", gap: "10px" }}
            >
              {itemData.map((item) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                >
                  <img
                    style={{ borderRadius: "8px" }}
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
          <div className="img-center-airline-mobile">
            <ImageList
              variant="quilted"
              cols={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 6 }}
              rowHeight={theme.breakpoints.values.sm > 600 ? 210 : 160}
              style={{ display: "grid", gap: "10px" }}
            >
              {itemData.map((item) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                >
                  <img
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </Container>
      </div>
      <div id="country-block" className="country-block">
        <div className="common-header-title">
          <h3>Plan your destination wedding</h3>
          <p>
            Plan your dream destination wedding with ease by choosing the
            perfect location and a skilled local planner
          </p>
        </div>

        <Swiper
          slidesPerView={6}
          spaceBetween={30}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 6,
              spaceBetween: 40,
            },
            1350: {
              slidesPerView: 6,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide className="slide-box">
            {" "}
            <div className="venues-box-1">
              <div className="img-venue">
                <img src={country1} alt="" />
                <div className="titilebar-for-country">United States</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            {" "}
            <div className="venues-box-1">
              <div className="img-venue">
                <img src={country2} alt="" />
                <div className="titilebar-for-country">Italy</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-box-1">
              <div className="img-venue">
                <img src={country3} alt="" />
                <div className="titilebar-for-country">Switzerland</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-box-1">
              <div className="img-venue">
                <img src={country4} alt="" />
                <div className="titilebar-for-country">Greece</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-box-1">
              <div className="img-venue">
                <img src={country5} alt="" />
                <div className="titilebar-for-country">FRANCE</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-box-1">
              <div className="img-venue">
                <img src={country6} alt="" />
                <div className="titilebar-for-country">Ireland</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-box-1">
              <div className="img-venue">
                <img src={country1} alt="" />
                <div className="titilebar-for-country">United States</div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="venue-all-btn">
          <text className="btn-venue-done">
            View all <EastRoundedIcon sx={{ fontSize: 14 }} />
          </text>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
