import React from "react";

const TermsAndConditions = () => {
  return (
    <div style={styles.container}>
      <h1>Terms & Conditions</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to WedMySoul, your premier platform for booking venues for
        weddings and related events. By using our platform, you agree to comply
        with and be bound by the following Terms & Conditions. Please read them
        carefully.
      </p>

      <h2>2. Definitions</h2>
      <ul>
        <li>
          <strong>Platform:</strong> Refers to the WedMySoul website.
        </li>
        <li>
          <strong>User:</strong> Refers to any individual or entity using the
          platform, including venue seekers and venue providers.
        </li>
        <li>
          <strong>Venue Provider:</strong> An individual or entity that lists
          venues available for booking on the platform.
        </li>
        <li>
          <strong>Venue Seeker:</strong> An individual or entity seeking to
          enquiry a venue listed on the platform.
        </li>
        <li>
          <strong>Enquiry:</strong> The act of expressing interest in a venue
          without making a binding booking.
        </li>
      </ul>

      <h2>3. Account Registration</h2>
      <ul>
        <li>
          <strong>Venue Seekers:</strong> Users not require to register for an
          account to submit enquiries for venues.
        </li>
        <li>
          <strong>Venue Providers:</strong> Venue Providers must subscribe to a
          paid plan for account registration and venue listing services.
        </li>
        <li>
          Users must provide accurate and complete information during the
          registration process and keep their account information updated.
        </li>
        <li>
          Users are responsible for maintaining the confidentiality of their
          account credentials.
        </li>
      </ul>

      <h2>4. Venue Listings</h2>
      <ul>
        <li>
          Venue Providers are responsible for providing accurate, up-to-date,
          and complete information about their venues.
        </li>
        <li>Listings must not be misleading or fraudulent.</li>
        <li>
          WedMySoul reserves the right to remove any listing that violates these
          terms or is otherwise deemed inappropriate.
        </li>
      </ul>

      <h2>5. Enquiries and Bookings</h2>
      <ul>
        <li>
          Venue Seekers may submit enquiries to Venue Providers regarding venue
          availability and booking details.
        </li>
        <li>
          Bookings are subject to the availability of the venue and the terms
          set by the Venue Provider.
        </li>
        <li>
          Venue Seekers must review and agree to the terms of the venue listing
          before making a booking.
        </li>
      </ul>

      <h2>6. Fees and Payments</h2>
      <ul>
        <li>
          Venue Providers agree to pay subscription fees for their use of the
          platform, as specified in the subscription plan chosen.
        </li>
        <li>
          Payments are processed through secure third-party payment providers.
        </li>
      </ul>

      <h2>7. User Conduct</h2>
      <ul>
        <li>Users must use the platform in a lawful and respectful manner.</li>
        <li>
          Users must not engage in any activity that disrupts or interferes with
          the platform’s functionality.
        </li>
        <li>
          Users must not post any content that is offensive, defamatory, or
          violates the rights of others.
        </li>
      </ul>

      <h2>8. Dispute Resolution</h2>
      <ul>
        <li>
          WedMySoul is not a party to any agreements between Venue Seekers and
          Venue Providers and will not mediate disputes.
        </li>
        <li>
          Users are encouraged to resolve disputes directly with each other.
        </li>
      </ul>

      <h2>9. Limitation of Liability</h2>
      <ul>
        <li>
          WedMySoul provides the platform "as is" and makes no warranties
          regarding the accuracy, completeness, or reliability of the listings.
        </li>
        <li>
          WedMySoul is not liable for any direct, indirect, incidental, or
          consequential damages arising from the use of the platform.
        </li>
      </ul>

      <h2>10. Privacy</h2>
      <p>
        WedMySoul is committed to protecting your privacy and personal
        information as outlined in our Privacy Policy.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
};

export default TermsAndConditions;
