import React from "react";
import Navigation from "./Navigation";
import { Outlet } from "react-router-dom";
import "./Landingpage.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./ROUTINGDATA";
import logo from "../img/logo.png";
import { Grid } from "@mui/material";
const Landingpage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navigation />
      <div className="maindiv-for-child-componets">
        <div className="maindiv-for-child-componets">
          <Outlet />
        </div>
        <div className="footer">
          <Grid className="gridContainer" container spacing={2}>
            <Grid
              sx={{ display: "flex", justifyContent: "center" }}
              item
              xs={12}
              sm={4}
            >
              <div className="policies">
                <div
                  className="text"
                  onClick={() => {
                    navigate(ROUTINGDATA.TERMSANDCONDITIONS);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Terms and Conditions
                </div>
                <div
                  className="text"
                  onClick={() => {
                    navigate(ROUTINGDATA.PRIVACYPOLICY);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Privacy Policy
                </div>
                <div
                  className="text"
                  onClick={() => {
                    navigate(ROUTINGDATA.PURCHASEPOLICY);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Purchase Policy
                </div>
                <div
                  className="text"
                  onClick={() => {
                    navigate(ROUTINGDATA.REFUNDPOLICY);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Refund Policy
                </div>
                <div
                  className="text"
                  onClick={() => {
                    navigate(ROUTINGDATA.SHIPPING);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Delivery Policy
                </div>
                <div
                  className="text"
                  onClick={() => {
                    navigate(ROUTINGDATA.PRICINGPOLICY);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Pricing Policy
                </div>
              </div>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", justifyContent: "center" }}
              xs={12}
              sm={4}
            >
              <div className="contact">
                <div
                  className="text"
                  onClick={() => {
                    navigate(ROUTINGDATA.CONTACTS);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Contact us
                </div>
                <div>
                  <CallIcon sx={{ mb: -1 }} />
                  <a href="tel:+91 9080400330" className="link">
                    +91 9080400330
                  </a>
                </div>
                <div>
                  <EmailIcon sx={{ mb: -1 }} />
                  <a href="mailto:wms@usglobalsolutions.com" className="link">
                    wms@usglobalsolutions.com
                  </a>
                </div>
              </div>
            </Grid>
            <Grid
              sx={{ display: "flex", justifyContent: "center" }}
              item
              xs={12}
              sm={4}
              className="logo-container"
            >
              <img src={logo} alt="logo" className="footerLogo" />
            </Grid>
          </Grid>
          <div className="copyright">Copyright © 2024 by WedMySoul</div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
