import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet } from "react-router-dom";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";

import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { ROUTINGDATA } from "../ROUTINGDATA";
import "./Addportfoilo.css";
import useAxios from "../../useAxios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const drawerWidth = 240;

export default function MiniDrawer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const [addDataGets, setaddDataGets] = React.useState({});
  const [vendordataGets, setvendordataGets] = React.useState({});

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };
  const Navigate = useNavigate();
  const appBarClassName = `appbar ${open ? "open" : ""}`;

  const userEmail = localStorage.getItem("userEmail");

  const location = useLocation();

  const axiosData = useAxios();
  const fetchData = async () => {
    try {
      const response = await axiosData.get(`venues/email/${userEmail}`);
      setaddDataGets(response.data);
      const vendorDetials = await axiosData.get(`vendors/email/${userEmail}`);
      setvendordataGets(vendorDetials.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div>
        &nbsp;
        <br></br>
      </div>
      <div className="navigation-vendors">
        <div className="navigation-btn-vendors-dashboard">
          <div
            onClick={() => {
              navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.CHARACTER}`);
            }}
          >
            <text>
              <InfoIcon />
              Venue Listing
            </text>
          </div>
          <div className="addPic">
            <text style={{ color: "#F5AA35" }}>
              <PhotoSizeSelectActualIcon />
              Add PortFolio
            </text>
          </div>
        </div>
      </div>
      <div className="form-for-vendors">
        <Box sx={{ display: "flex", marginLeft: "290px" }}>
          <Box component="main" sx={{ flexGrow: 19, p: 3 }}>
            <Typography paragraph sx={{ fontWeight: "bold", color: "black" }}>
              Portfolio
            </Typography>
            <Table sx={{ margin: 5 }}>
              <TableHead>
                <TableRow
                  style={{
                    marginTop: "1.9%",
                    borderBottom: "3px solid #5F3F3B",
                  }}
                >
                  <TableCell align="center">Venue Thumbnail</TableCell>
                  <TableCell align="center">Venue Name</TableCell>
                  <TableCell align="center">Photos</TableCell>
                  <TableCell align="center">Videos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(addDataGets) &&
                  addDataGets.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <img
                          style={{ width: 100, marginLeft: 30 }}
                          alt="img"
                          src={row.thumbnailUrl}
                        />
                      </TableCell>
                      <TableCell align="center">{row.brand_name}</TableCell>
                      <TableCell align="center">
                        {row.imageUrl.length}
                      </TableCell>
                      <TableCell align="center">
                        {row.videoUrl.length}
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          <Button
                            sx={{ backgroundColor: "#F5AA35", borderRadius: 5 }}
                            onClick={() =>
                              navigate(
                                `${ROUTINGDATA.LAND}${ROUTINGDATA.ADDPHOTOS}`,
                                {
                                  state: {
                                    id: row._id,
                                    VenuesName: row.brand_name,
                                    vendor_id: vendordataGets.vendor_id,
                                  },
                                }
                              )
                            }
                            variant="contained"
                          >
                            Add
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </div>
    </>
  );
}
