import React, { useEffect, useState } from "react";
import Breadcrumb from "../BreadCrumb";
import { Formik, Form } from "formik";
import {
  Button,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Grid from "@mui/material/Grid";
import GroupsIcon from "@mui/icons-material/Groups";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import RateReviewIcon from "@mui/icons-material/RateReview";
import star from "../../img/Star 2.png";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import Rating from "@mui/material/Rating";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import toplistingvenue1 from "../../img/venues-part-page-img/places-img/toplisting-img-1.png";
import toplistingvenue2 from "../../img/venues-part-page-img/places-img/toplisting-img-2.png";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router";
import useAxios from "../../useAxios";
import * as Yup from "yup";
import "./VpageDetails.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ROUTINGDATA } from "../ROUTINGDATA";

const VenuesPageDetails = () => {
  var datetime = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const navigate = useNavigate();
  const [openes, setOpenes] = useState(false);
  const location = useLocation();
  const axiosData = useAxios();
  const [venueDetails, setVenueDetails] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const today = moment().subtract(1, "days");
  const [to, setTo] = useState(moment());
  const [viewphotos, setviewphotos] = useState(true);
  const [photodata, setphotodata] = useState([]);
  const [viewVideos, setViewVideos] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setVenueDetails(location?.state?.data);
    fetchVenuesDetails(location?.state.id);
    fetchphotos(location?.state.id);

    if (location.state && location.state.id) {
      fetchVideos(location.state.id);
    }
  }, [location.state]);

  const fetchVenuesDetails = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`venues/${id}`);
      const data = getOneAxios?.data;
      if (data) {
        setVenueDetails(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const fetchphotos = async (id) => {
    try {
      const multiphoto = await axiosData.get(`venues/images/${id}`);
      setImageUrl(multiphoto.data);
      setphotodata(multiphoto.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const fetchVideos = async (id) => {
    try {
      if (!id) {
        console.error("No id provided to fetch videos");
        return;
      }
      const response = await axiosData.get(`venues/videos/${id}`);
      setVideoData(response.data);
    } catch (error) {
      console.error("Error fetching videos:", error);
      setVideoData([]);
    }
  };

  // useEffect(() => {
  //   // setVenueDetails(location?.state?.data)
  //   fetchVenuesDetails(location?.state.id);
  //   fetchphotos(location?.state.id);
  // }, []);
  const [top, settop] = useState("25%");

  const stickyDivStyle = {
    top: top,
  };

  const openabout = () => {
    setviewphotos(false);
    setViewVideos(false);
  };

  const openviewphotoses = () => {
    setviewphotos(true);
    setViewVideos(false);
  };
  const openViewVideos = () => {
    setviewphotos(false);
    setViewVideos(true);
  };

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setOpenImagePreview(true);
  };

  const handleCloseImagePreview = () => {
    setOpenImagePreview(false);
    setCurrentIndex(0);
  };
  const dataAxios = useAxios();

  const validationSchema = Yup.object().shape({
    event_name: Yup.string().required("Name is not required"),
    mobile_number: Yup.string().required("Phone No is not required"),
    email_address: Yup.string()
      .email("Invalid email")
      .required("Email is not required"),
    full_name: Yup.string().required("Name is not required"),
  });
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrl.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageUrl.length) % imageUrl.length
    );
  };

  // let images = [];
  // if (imageUrl) {
  //   images = imageUrl.map((item) => item.url[0]);
  // }
  // let videos = [];
  // if (videoData) {
  //   videos = videoData.map((item) => item.url[0]);
  // }
  return (
    <div>
      <Container>
        {location.pathname.includes("/AdminDashboard/details/") && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Tooltip title="Back" arrow>
              <ArrowCircleLeftOutlinedIcon
                sx={{
                  fontSize: 50,
                  color: "rgb(229, 160, 0)",
                  position: "absolute",
                  left: 0,
                  cursor: "pointer",
                  mt: 1,
                }}
                onClick={() => {
                  navigate(
                    ROUTINGDATA.ADMINDASHBOARD +
                      "/" +
                      ROUTINGDATA.AdminVenuesPage
                  );
                }}
              />
            </Tooltip>
          </div>
        )}
        <div className="path-breadcrumb">
          <Breadcrumb name={venueDetails?.brand_name} />
        </div>
      </Container>
      <Container>
        <div id="venues-details-splited" className="venues-details-splited">
          <div className="venues-details-box-1">
            <div className="img-venues-details-main">
              <img src={venueDetails?.thumbnailUrl} alt="img" />
              <div className="imgbar-venues-details">
                <text
                  style={{
                    cursor: "pointer",
                    backgroundColor: viewphotos ? "orange" : "inherit",
                  }}
                  onClick={openviewphotoses}
                >
                  Photos
                </text>
                <Divider orientation="vertical" flexItem />
                <text
                  style={{
                    cursor: "pointer",
                    backgroundColor: viewVideos ? "orange" : "inherit",
                  }}
                  onClick={openViewVideos}
                >
                  Videos
                </text>
                <Divider orientation="vertical" flexItem />
                <text
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      !viewphotos && !viewVideos ? "orange" : "inherit",
                  }}
                  onClick={openabout}
                >
                  About
                </text>
              </div>
            </div>

            {viewphotos ? (
              <div>
                <div style={{ marginTop: "70px" }}>
                  <Grid container>
                    {imageUrl.length > 0 ? (
                      imageUrl.map((photo, index) => (
                        <Grid
                          sx={{ p: 2 }}
                          xs={3}
                          md={3}
                          key={index}
                          onClick={() => handleImageClick(photo, index)}
                        >
                          <Typography>{photo.name}</Typography>

                          <div className="imges">
                            <img
                              src={photo}
                              alt="venue-img"
                              style={{
                                height: "100px",
                                width: "150px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Grid>
                      ))
                    ) : (
                      <Typography
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        Images not upload
                      </Typography>
                    )}
                  </Grid>
                </div>
                {openImagePreview && (
                  <Dialog
                    open={openImagePreview}
                    onClose={handleCloseImagePreview}
                  >
                    <DialogTitle>
                      {imageUrl[currentIndex] && imageUrl[currentIndex]?.name}
                      {/* {selectedImage && selectedImage.name} */}
                      <IconButton
                        aria-label="close"
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "3px",
                        }}
                        onClick={handleCloseImagePreview}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <img
                          src={imageUrl[currentIndex]}
                          alt=""
                          style={{ maxWidth: "100%" }}
                        />
                        {imageUrl.length > 1 && (
                          <>
                            <IconButton
                              onClick={handlePrev}
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "10px",
                                transform: "translateY(-50%)",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                              }}
                            >
                              <ArrowBackIosIcon />
                            </IconButton>
                            <IconButton
                              onClick={handleNext}
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                              }}
                            >
                              <ArrowForwardIosIcon />
                            </IconButton>
                          </>
                        )}
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            ) : viewVideos ? (
              <div>
                <div style={{ marginTop: "70px" }}>
                  <Grid container>
                    {videoData.length > 0 ? (
                      videoData.map((video, index) => (
                        <Grid sx={{ p: 2 }} xs={3} md={3} key={index}>
                          <div className="videos">
                            <video
                              style={{
                                height: "100px",
                                width: "150px",
                                cursor: "pointer",
                              }}
                              controls
                            >
                              <source src={video} type="video/mp4" />
                            </video>
                          </div>
                        </Grid>
                      ))
                    ) : (
                      <Typography
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        Videos not upload
                      </Typography>
                    )}
                  </Grid>
                </div>
              </div>
            ) : null}
            <div>
              <div className="rating-box-in-mobile">
                <div className="spaces-fristbox">
                  <h3>{venueDetails?.brand_name}</h3>
                  <text>
                    <LocationOnIcon
                      sx={{ color: "#7A5620", fontSize: "23px" }}
                    />
                    {venueDetails?.city}
                  </text>
                  <text>{venueDetails?.address}</text>
                  <p>
                    <GroupsIcon sx={{ color: "#7A5620", fontSize: "23px" }} />
                    Capacity {venueDetails?.capacity_persons} guests{" "}
                  </p>
                  <p className="range-content">
                    <span>₹{venueDetails?.min_price_day} </span>-{" "}
                    <span>₹{venueDetails?.max_price_day}</span> Price range
                  </p>
                </div>
                {/* <div className="btns-actions">
                  <div style={{ borderRadius: "0px 0px 0px 6px" }}>
                    <RateReviewIcon sx={{ color: "#b48a4a" }} />
                    Review
                  </div>
                  <div>
                    <FavoriteBorderOutlinedIcon sx={{ color: "#b48a4a" }} />
                    Shortlist
                  </div>
                  <div style={{ borderRadius: "0px 0px 6px 0px" }}>
                    <ShareIcon sx={{ color: "#b48a4a" }} /> Share
                  </div>
                </div> */}
                {/* <div className="rating-points">
                  <div className="star-img">
                    <img src={star} alt="" />
                    <div className="points-mark">4.5</div>
                  </div>
                  <div className="star-content">
                    <text>Rating</text>
                    <div>15 reviews</div>
                  </div>
                </div> */}
                <div>
                  {" "}
                  <Button
                    className="btn-enquiry"
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#f09200",
                      "&:hover": {
                        bgcolor: "#f09200",
                      },
                      marginTop: "20px",
                    }}
                    fullWidth
                    onClick={() => setOpenes(true)}
                  >
                    <CalendarMonthIcon
                      sx={{ marginRight: 1 }}
                    ></CalendarMonthIcon>
                    Venue Enquiry
                  </Button>
                </div>
              </div>
            </div>

            <div className="second-box-venues-details">
              <h1>About the venues name and location of venue</h1>

              <p>{venueDetails?.description}</p>
            </div>
            <div className="third-box-veneus-details">
              <div className="third-box-veneus-details-split">
                <div className="third-box-content-title">
                  <h3>Room Count</h3>
                  <text>
                    {venueDetails?.rooms_available_in_your_accommodation} rooms
                  </text>
                </div>

                <div className="third-box-content-title">
                  <h3> Parking</h3>
                  <text>{venueDetails?.parking_available}</text>
                </div>

                <div className="third-box-content-title">
                  <h3> Space</h3>
                  <text>{venueDetails?.primary_venue_type}</text>
                </div>
              </div>
            </div>
          </div>
          <div id="venues-details-box-2" className="venues-details-box-2">
            <div className="position-fixed-side-content" style={stickyDivStyle}>
              <div className="frist-box-venues-details-2">
                <div className="spaces-fristbox">
                  <h3>{venueDetails?.brand_name}</h3>
                  <text>
                    <LocationOnIcon
                      sx={{ color: "#7A5620", fontSize: "23px" }}
                    />
                    {venueDetails?.city}
                  </text>
                  <text>{venueDetails?.address}</text>
                  <p>
                    <GroupsIcon sx={{ color: "#7A5620", fontSize: "23px" }} />
                    Capacity {venueDetails?.capacity_persons} guests{" "}
                  </p>
                  <p className="range-content">
                    <span>₹{venueDetails?.min_price_day} </span>-{" "}
                    <span>₹{venueDetails?.max_price_day}</span> Price range
                  </p>
                </div>
                {/* <div className="rating-points">
                  <div className="star-img">
                    <img src={star} alt="" />
                    <div className="points-mark">4.5</div>
                  </div>
                  <div className="star-content">
                    <text>Rating</text>
                    <div>15 reviews</div>
                  </div>
                </div> */}
              </div>
              <div>
                <Button
                  className="btn-enquiry"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#f09200",
                    "&:hover": {
                      bgcolor: "#f09200",
                    },
                    marginTop: "20px",
                  }}
                  fullWidth
                  onClick={() => setOpenes(true)}
                >
                  <CalendarMonthIcon
                    sx={{ marginRight: 1 }}
                  ></CalendarMonthIcon>
                  Venue Enquiry
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Dialog open={openes} onClose={() => setOpenes(false)}>
        <DialogTitle>
          Venue Enquiry Form
          <IconButton
            aria-label="close"
            onClick={() => setOpenes(false)}
            style={{ position: "absolute", right: "8px", top: "8px" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details below to send your enquiry.
          </DialogContentText>
          <Formik
            initialValues={{
              full_name: "",
              email_address: "",
              mobile_number: "",
              event_name: "",
              event_date: moment().format("DD/MM/YYYY"),
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              values.venue_id = venueDetails.id;
              values.venue_email = venueDetails.additional_email_id;
              values.venue_name = venueDetails.brand_name;
              values.venue_number = venueDetails.contact_number;
              const dataToSend = {
                full_name: values.full_name,
                email_address: values.email_address,
                mobile_number: values.mobile_number,
                event_name: values.event_name,
                event_date: values.event_date,
              };
              const response = await dataAxios.post("enquiry", values);
              setSubmitting(false);
              resetForm();
              setOpenes(false);
              setOpenSnackbar(true);
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="event_name"
                  label="Event Name"
                  value={values.event_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="full_name"
                  label="Full Name"
                  value={values.full_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="email_address"
                  label="Email Address"
                  value={values.email_address}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="mobile_number"
                      label="Mobile Number"
                      value={values.mobile_number}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Event Date"
                        sx={{ marginTop: 2 }}
                        format="DD/MM/YYYY"
                        value={moment(values.event_date, "DD/MM/YYYY")}
                        onChange={(date) =>
                          setFieldValue("event_date", date.format("DD/MM/YYYY"))
                        }
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            fullWidth
                            margin="normal"
                            value={values.event_date}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#f09200",
                      "&:hover": {
                        bgcolor: "#f09200",
                      },
                    }}
                    disabled={isSubmitting}
                  >
                    Submit Enquiry
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog open={openSnackbar} onClose={handleSnackbarClose}>
        <DialogTitle>Enquiry Submitted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your venue enquiry has been submitted successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSnackbarClose}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#f09200",
              "&:hover": {
                bgcolor: "#f09200",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VenuesPageDetails;
