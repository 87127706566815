import React, { useEffect, useState, useRef } from "react";
import "../VendorsDashboard/VendorsDashboard.css";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { MenuItem, Select, InputLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { LinearProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxios from "../../useAxios";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useLocation } from "react-router-dom";
import { removeImage, uploadImage } from "../../HelperFunction";
const EditVenues = () => {
  const [venueApplicables, setVenueApplicables] = useState([false]);
  const [venueFilterTypes, setVenueFilterTypes] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  const axiosData = useAxios();
  const [file, setFile] = useState("");
  const [datas, setdatas] = useState(false);
  const [editeData, seteditedData] = useState(false);
  const fileInputField = useRef(null);
  const [divisions, setDivisions] = useState();
  const [city, setCity] = useState("");
  const [address, setAddress] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      venue_type: "",
      email_id: "",
      brand_name: "",
      contact_person_name: "",
      additional_email_id: "",
      contact_number: "",

      description: "",
      postal_code: "",
      city: "",
      address: "",
      division: "",
      parking_available: "",
      booking_amount: "",
      primary_venue_type: "",

      rooms_available_in_your_accommodation: "",
      starting_price_basic_room: "",

      min_price_day: "",
      max_price_day: "",
      capacity_persons: "",
      thumbnail: "",
      thumbnailUrl: "",
    },
    validationSchema: Yup.object({
      venue_type: Yup.string(),
      email_id: Yup.string().required("Email must be Required"),
      brand_name: Yup.string().required("Brand Name must be Required"),
      contact_person_name: Yup.string(),
      additional_email_id: Yup.string(),
      contact_number: Yup.number().required("Contact Number must be Required"),

      description: Yup.string(),
      city: Yup.string().required("City must be Required"),
      address: Yup.string(),
      parking_available: Yup.string(),
      booking_amount: Yup.string(),
      primary_venue_type: Yup.string(),
      // starting_price_for_vegetarian_menu: Yup.string(),
      // starting_price_for_non_vegetarian_menu: Yup.string(),
      rooms_available_in_your_accommodation: Yup.string(),
      starting_price_basic_room: Yup.string(),

      min_price_day: Yup.string(),
      max_price_day: Yup.string(),
      capacity_persons: Yup.string(),
      thumbnail: Yup.mixed()
        .required("Image is required")
        .test(
          "fileSize",
          "File size too large",
          (value) => value && value.size <= 8000000
        ) // Example: 2MB limit
        .test(
          "fileType",
          "Unsupported file type",
          (value) => value && ["image/jpeg", "image/png"].includes(value.type)
        ),
    }),
    onSubmit: (values) => {},
  });
  const fetchEditVenuesDetails = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`venues/${location?.state.id}`);
      const editvalues = getOneAxios.data;

      formik.setFieldValue("brand_name", editvalues.brand_name);
      formik.setFieldValue("venue_type", editvalues.venue_type);
      formik.setFieldValue("contact_number", editvalues.contact_number);
      formik.setFieldValue(
        "contact_person_name",
        editvalues.contact_person_name
      );
      formik.setFieldValue(
        "additional_email_id",
        editvalues.additional_email_id
      );
      formik.setFieldValue("description", editvalues.description);
      formik.setFieldValue("postal_code", editvalues.postal_code);
      formik.setFieldValue("city", editvalues.city);
      formik.setFieldValue("division", editvalues.division);
      formik.setFieldValue("address", editvalues.address);
      formik.setFieldValue("parking_available", editvalues.parking_available);
      formik.setFieldValue("primary_venue_type", editvalues.primary_venue_type);
      formik.setFieldValue(
        "rooms_available_in_your_accommodation",
        editvalues.rooms_available_in_your_accommodation
      );
      formik.setFieldValue(
        "starting_price_basic_room",
        editvalues.starting_price_basic_room
      );
      formik.setFieldValue("min_price_day", editvalues.min_price_day);
      formik.setFieldValue("max_price_day", editvalues.max_price_day);
      formik.setFieldValue("capacity_persons", editvalues.capacity_persons);
      formik.setFieldValue("thumbnail", editvalues.thumbnail);
      formik.setFieldValue("thumbnailUrl", editvalues.thumbnailUrl);
      setPreviewImage(editvalues.thumbnailUrl);
      setdatas(true);

      return editvalues;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const dataAxios = useAxios();
  const fetchpincode = async (event) => {
    formik.setFieldValue("postal_code", event.target.value);
    const pincode = event.target.value;
    try {
      const response = await dataAxios.get(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      if (response.data && response.data.length > 0) {
        const data = response.data[0];
        const places = data?.PostOffice.map((office) => office?.Name).filter(
          (place) => place
        );
        setDivisions([...places]);
        //  setPlaceOptions(places);
        const state = data?.PostOffice[0]?.State || "";
        const Country = data?.PostOffice[0]?.Country || "";
        const district = data?.PostOffice[0]?.District || "";
        setCity(district);
        formik.setFieldValue("city", district);
        // setStateValue(state);
        // setCountryValue(Country);
      } else {
        // setPlaceOptions([]);
        // setStateValue("");
        // setCountryValue("");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // setPlaceOptions([]);
      // setStateValue("");
      // setCountryValue("");
    }
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    formik.setFieldValue("city", city);
  };

  const handleDivisionChange = (event) => {
    const division = event.target.value;
    formik.setFieldValue("division", division);

    // fetchData(`https://api.postalpincode.in/postoffice/${division}`, setPostOffices);
  };
  useEffect(() => {
    fetchEditVenuesDetails(location.state.id);

    formik.setFieldValue("email_id", userEmail);
    setVenueApplicables([]);
  }, []);

  const MAX_FILE_SIZE = 1048576 * 10;

  const handleFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setFile("File is too large");
    }
    seteditedData(e.target.files[0].size);
  };

  const navigate = useNavigate();

  const Editdetails = async (values) => {
    if (isEdit) {
      await removeImage("thumbnail", values.thumbnailUrl);
      const newUrls = await uploadImage("thumbnail", values.thumbnail, true);
      values.thumbnailUrl = newUrls.join(", ");
    }
    values.applicable_things_in_venue = venueApplicables.join(",");
    values.venue_type = venueFilterTypes.join(",");
    await axiosData
      .put(`venues/id/${location.state.id}`, values, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setVenueApplicables([]);
        setVenueFilterTypes([]);
        alert("Your Details Successfully Updated");
      })
      .catch((err) => console.log(err));
  };

  const userEmail = localStorage.getItem("userEmail");
  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("thumbnail", file);
    setPreviewImage(URL.createObjectURL(file));
    setIsEdit(true);
  };

  return (
    <div id="VenuesDashboard-block" className="VenuesDashboard-block">
      <Container>
        <h3>Editing Venue Details for {location.state.VenuesName}</h3>
        {datas && (
          <form onSubmit={formik.handleSubmit}>
            <div className="dashboard-split-dawer">
              <div className="navigation-vendors"></div>
              <div className="form-for-vendors">
                <h3>Venue Registration</h3>
                <div
                  id="from-inpust-box2-dashboard"
                  className="from-inpust-box2-dashboard"
                >
                  <Divider>
                    <div className="form-for-vendors">
                      <h3>Personal Details</h3>
                    </div>
                  </Divider>
                  <div className="dashboard-personal-textfield">
                    <label>Login Email ID</label>

                    <div>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                            borderBottom: "none",
                          },
                        }}
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled
                        value={formik.values.email_id}
                        onChange={formik.handleChange}
                      />
                      <Button variant="contained">Make primary</Button>
                    </div>
                  </div>

                  <div className="dashboard-personal-textfield">
                    <label>Contact person name</label>
                    <div>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                            borderBottom: "none",
                          },
                        }}
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.contact_person_name}
                      />
                    </div>
                  </div>

                  <div
                    className="dashboard-personal-textfield"
                    style={{ padding: "10px 0px" }}
                  >
                    <label>Contact number*</label>
                    <div className="number-field-dashboard">
                      <Button
                        className="numberbtn"
                        endIcon={
                          <ArrowDropDownIcon sx={{ color: "#180806" }} />
                        }
                      >
                        +91
                      </Button>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": { borderRadius: "0px" },
                        }}
                        variant="standard"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("contact_number", value);
                          }
                        }}
                        value={formik.values.contact_number}
                      />

                      <div className="add-more-btn">
                        <text>
                          <AddCircleIcon
                            sx={{ fontSize: 20, color: "#F5AA35" }}
                          />
                          ADD MORE
                        </text>
                      </div>
                    </div>
                  </div>

                  <Divider>
                    <div className="form-for-vendors">
                      <h3> Venue Details</h3>
                    </div>
                  </Divider>

                  <div className="dashboard-personal-textfield">
                    <label>Venue Name*</label>
                    <div>
                      <TextField
                        required
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                            borderBottom: "none",
                          },
                        }}
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("brand_name", value);
                          }
                        }}
                        value={formik.values.brand_name}
                      />
                    </div>
                  </div>
                  <div className="dashboard-personal-textfield">
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      Description About Venue
                      <span style={{ fontSize: "12px" }}>
                        (To update your description, please send a mail to
                        contact@WedMySoul.com)
                      </span>
                    </label>

                    <div>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                            borderBottom: "none",
                          },
                        }}
                        placeholder="Enter your message..."
                        fullWidth
                        size="small"
                        multiline
                        rows={5}
                        variant="outlined"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("description", value);
                          }
                        }}
                        value={formik.values.description}
                      />
                    </div>
                  </div>

                  <div className="dashboard-personal-textfield">
                    <label>Pincode*</label>
                    <div>
                      <TextField
                        id="outlined-basic"
                        placeholder="Pincode"
                        variant="outlined"
                        fullWidth
                        name="postal_code"
                        onBlur={formik.handleBlur}
                        onChange={(e) => fetchpincode(e)}
                        value={formik.values.postal_code}
                      />
                    </div>
                  </div>

                  <div className="dashboard-personal-textfield">
                    <label>City*(Choose your base city here)</label>
                    <div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          labelId="city-label"
                          placeholder="City"
                          name="city"
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          onChange={handleCityChange}
                        >
                          <MenuItem value={city}>{city}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="dashboard-personal-textfield">
                    <label>Area*</label>
                    <div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          labelId="division-label"
                          placeholder="City"
                          name="division"
                          onBlur={formik.handleBlur}
                          value={formik.values.division}
                          onChange={handleDivisionChange}
                        >
                          {divisions &&
                            divisions.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    {/* <Button onClick={handleCheckButtonClick} variant="contained">Check</Button> */}
                  </div>
                  <div
                    className="dashboard-personal-textfield"
                    style={{ padding: "15px 0px" }}
                  >
                    <label>Address</label>
                    {address === false ? (
                      <div
                        className="add-more-btn"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <text
                          onClick={() => {
                            setAddress(true);
                          }}
                        >
                          <AddCircleIcon
                            sx={{ fontSize: 20, color: "#F5AA35" }}
                          />
                          ADD MORE
                        </text>
                      </div>
                    ) : (
                      <div className="dashboard-personal-textfield">
                        <div style={{ width: "100%" }}>
                          <TextField
                            id="outlined-basic"
                            sx={{
                              "& fieldset": {
                                borderRadius: "0px",
                              },
                              width: "100%",
                            }}
                            placeholder="Enter your message..."
                            fullWidth
                            size="small"
                            multiline
                            rows={5}
                            variant="outlined"
                            onChange={(e) => {
                              if (e) {
                                const value = e.target.value;
                                formik.setFieldValue("address", value);
                              }
                            }}
                            value={formik.values.address}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="additional-details-dashboard">
                    <h3>Additional details</h3>
                    <div className="dashboard-personal-textfield">
                      <label>Max price per day</label>
                      <div>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            "& fieldset": {
                              borderRadius: "0px",
                              borderBottom: "none",
                            },
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              formik.setFieldValue("max_price_day", value);
                            }
                          }}
                          value={formik.values.max_price_day}
                        />
                      </div>
                    </div>
                    <div className="dashboard-personal-textfield">
                      <label>Min price per day</label>
                      <div>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            "& fieldset": {
                              borderRadius: "0px",
                              borderBottom: "none",
                            },
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              formik.setFieldValue("min_price_day", value);
                            }
                          }}
                          value={formik.values.min_price_day}
                        />
                      </div>
                    </div>
                    <div className="dashboard-personal-textfield">
                      <label>Capacity(No.of.persons)</label>
                      <div>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            "& fieldset": {
                              borderRadius: "0px",
                            },
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              formik.setFieldValue("capacity_persons", value);
                            }
                          }}
                          value={formik.values.capacity_persons}
                        />
                      </div>
                    </div>

                    <div className="raidio-btnquts">
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          <label>Is parking available at the venue?</label>
                        </FormLabel>
                        <RadioGroup
                          className="radiogroup-title"
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              formik.setFieldValue("parking_available", value);
                            }
                          }}
                          value={formik.values.parking_available}
                        >
                          <FormControlLabel
                            className="sublabel"
                            value="There is sufficient parking available"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">
                                There is sufficient parking available
                              </div>
                            }
                          />
                          <FormControlLabel
                            className="sublabel"
                            value="Parking is available near the venue"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">
                                Parking is available near the venue
                              </div>
                            }
                          />
                          <FormControlLabel
                            className="sublabel"
                            value="No parking available"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">
                                No parking available
                              </div>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="raidio-btnquts">
                      <label>
                        How many rooms are available in your accommodation?
                      </label>
                      <div style={{ marginTop: "20px", width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            "& fieldset": {
                              borderRadius: "0px",
                            },
                          }}
                          size="small"
                          variant="outlined"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              formik.setFieldValue(
                                "rooms_available_in_your_accommodation",
                                value
                              );
                            }
                          }}
                          value={
                            formik.values.rooms_available_in_your_accommodation
                          }
                        />
                      </div>
                    </div>
                    <div className="raidio-btnquts">
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          <label>Primary Venue Type</label>
                        </FormLabel>
                        <RadioGroup
                          className="radiogroup-title"
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              formik.setFieldValue("primary_venue_type", value);
                            }
                          }}
                          value={formik.values.primary_venue_type}
                        >
                          <FormControlLabel
                            className="sublabel"
                            value="Wedding Halls"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">Wedding Halls</div>
                            }
                          />
                          <FormControlLabel
                            className="sublabel"
                            value="Banquet Halls"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">Banquet Halls</div>
                            }
                          />
                          <FormControlLabel
                            className="sublabel"
                            value="Resorts"
                            control={<Radio size="small" />}
                            label={<div className="sublabel">Resorts</div>}
                          />
                          <FormControlLabel
                            className="sublabel"
                            value="Beachside Venues"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">Beachside Venues</div>
                            }
                          />

                          <FormControlLabel
                            className="sublabel"
                            value="Community Halls"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">Community Halls</div>
                            }
                          />
                          <FormControlLabel
                            className="sublabel"
                            value="Open-air Venues"
                            control={<Radio size="small" />}
                            label={
                              <div className="sublabel">Open air Venues</div>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="raidio-btnquts">
                      <div className="dashboard-personal-textfield">
                        <label htmlFor="image">Upload Image:</label>
                        <input
                          id="image"
                          name="image"
                          type="file"
                          onChange={handleImageChange}
                        />
                        {formik.errors.image && formik.touched.image && (
                          <div className="error">{formik.errors.image}</div>
                        )}
                      </div>
                      {previewImage && (
                        <div>
                          <img
                            src={previewImage}
                            alt="Preview"
                            style={{ maxWidth: "200px" }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="save-btn-dashboard">
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={(e) => {
                          Editdetails(formik.values);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Container>
    </div>
  );
};

export default EditVenues;
