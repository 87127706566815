import React from "react";
import heroimg from "../../../img/vendors-part-page-img/hero-img-vendor.png";
import { Container } from "@mui/material";
import photo from "../../../img/vendors-part-page-img/main-page-img/photo-1.png";
import catareing from "../../../img/vendors-part-page-img/main-page-img/caterers-2.png";
import decors from "../../../img/vendors-part-page-img/main-page-img/decores-3.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import icon1 from "../../../img/vendors-part-page-img/main-page-img/icons-1.png";
import icon2 from "../../../img/vendors-part-page-img/main-page-img/icons-2.png";
import icon3 from "../../../img/vendors-part-page-img/main-page-img/icons-3.png";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
const VendorsHomepage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="hero-img-block">
        <img src={heroimg} alt="" />
        <div className="overlay vendors-overlay"></div>
        <div className="search-content vendors-search-content">
          <h3>Create your Dream Wedding</h3>
          <p>Because the best moments in life deserve the best</p>
        </div>
      </div>
      <div id="vendors-catagaious" className="vendors-catagaious">
        <Container>
          <div className="common-header-title vendors-common-header">
            <h3 style={{ textAlign: "left" }}>
              Hire Wedding Vendors in Any Budget
            </h3>
            <p style={{ textAlign: "left" }}>
              Finds experienced wedding professionals for all categories!
            </p>
          </div>
        </Container>
        <div>
          <Container>
            <div className="catagaious-split">
              <div
                onClick={() => {
                  navigate(ROUTINGDATA.VENDORSPHOTOGRAPH);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                style={{ cursor: "pointer" }}
                className="vendors-catagaious-box"
              >
                <div className="vendors-catagaious-box-img">
                  <img src={photo} alt="" />
                  <div className="vendors-catagaious-icon">
                    <CameraAltIcon sx={{ fontSize: 25 }} />
                  </div>
                </div>
                <div className="vendors-catagaious-box-content">
                  <h3>Wedding Photographers / Videographers</h3>
                  <p>
                    Capture the magic of your special day with our wedding
                    photography and videography services
                  </p>
                </div>
              </div>
              <div className="vendors-catagaious-box">
                {" "}
                <div className="vendors-catagaious-box-img">
                  <img src={catareing} alt="" />
                  <div className="vendors-catagaious-icon">
                    <RoomServiceIcon sx={{ fontSize: 27 }} />
                  </div>
                </div>
                <div className="vendors-catagaious-box-content">
                  <h3>Caterers</h3>
                  <p>
                    Indulge your senses with our catering services, where
                    culinary artistry meets impeccable service to elevate your
                    event with a symphony of flavors and a touch of gastronomic
                    delight.
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate(ROUTINGDATA.VENDORSDECPRATORS);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                style={{ cursor: "pointer" }}
                className="vendors-catagaious-box"
              >
                <div className="vendors-catagaious-box-img">
                  <img src={decors} alt="" />
                  <div className="vendors-catagaious-icon">
                    {/* <CameraAltIcon sx={{fontSize:25}} /> */}
                    <img src={icon3} alt="" />
                  </div>
                </div>
                <div className="vendors-catagaious-box-content">
                  <h3>Decorators</h3>
                  <p>
                    Transform your event into an enchanting spectacle with our
                    exquisite decor services, where every detail is meticulously
                    curated to create an ambiance that reflects your unique
                    style and leaves a lasting impression.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default VendorsHomepage;
