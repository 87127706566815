import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../img/logo.png";
import {
  Button,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import vendorheader from "../img/vendor.png";
import hotellist from "../img/hotel_list.png";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./ROUTINGDATA";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { List, ListItem, ListItemText } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InboxIcon from "@mui/icons-material/Inbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAxios from "../useAxios";

const Navigation = () => {
  const navigate = useNavigate();
  const axiosData = useAxios();
  const userEmail = localStorage.getItem("userEmail");

  const location = useLocation();
  const currentUrl = location.pathname;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [activelocation, setactivelocation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [oneVendor, setOneVendor] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteValueFromLocalStorage = () => {
    // Delete a value from local storage
    localStorage.removeItem("userEmail");

    navigate(ROUTINGDATA.VENDORSIGNIN);
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleClose();
  };

  const navitodash = () => {
    navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.CHARACTER}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleClose();
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const getVendor = async () => {
    const vendorDetail = await axiosData.get(`vendors/email/${userEmail}`);
    setOneVendor(vendorDetail.data);
    const subscriptionStatus = await axiosData.get(
      `vendors/check-status/${userEmail}`
    );
    if (!subscriptionStatus.data.isSubscribed) {
      setOneVendor((prev) => ({ ...prev, isSubscribed: false }));
    }
  };
  useEffect(() => {
    const urlArray = currentUrl.split("/");
    if (urlArray[1] === "Venues") {
      setactivelocation(true);
    } else {
      setactivelocation(false);
    }
  }, [currentUrl]);

  useEffect(() => {
    if (userEmail) {
      getVendor();
      const interval = setInterval(() => {
        getVendor();
      }, 24 * 60 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [userEmail]);

  return (
    <div>
      <AppBar position="fixed" className="header-1">
        <AppBar position="static" className="header-2">
          <div className="header-content-split">
            <div style={{ visibility: "hidden" }}>
              adqwdqwdqqdqwdqwdqwdqdqwasasdasdasasdasas
            </div>
            <div>Destination Weddings and Events</div>
          </div>
        </AppBar>
        <Toolbar className="splite-header">
          <div className="logo">
            <img
              style={{ cursor: "pointer" }}
              src={logo}
              alt="logo"
              onClick={() => {
                navigate(ROUTINGDATA.VENUESPAGE);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
          </div>
          <Typography variant="h6" component="div" className="nav-btn">
            <text
              className={
                currentUrl === ROUTINGDATA.LAND + ROUTINGDATA.VENUESPAGE ||
                currentUrl ===
                  ROUTINGDATA.LAND + ROUTINGDATA.VENUESPAGEDETAILS ||
                activelocation
                  ? "navigate-btn-hover-active"
                  : "navigate-btn-hover"
              }
              onClick={() => {
                navigate(ROUTINGDATA.VENUESPAGE);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              VENUES
            </text>

            {/* <text
              className={
                currentUrl === ROUTINGDATA.LAND + ROUTINGDATA.VENDORSPAGE
                  ? "navigate-btn-hover-active"
                  : "navigate-btn-hover"
              }
              onClick={() => {
                navigate(ROUTINGDATA.VENDORSPAGE);
              }}
            >
              VENDORS
            </text> */}
          </Typography>

          {userEmail ? (
            <div>
              <AccountCircleIcon
                sx={{ color: "#f5aa35", fontSize: 40, cursor: "pointer" }}
                onClick={handleClick}
              />

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <text>Email:{userEmail}</text>
                </MenuItem>
                {oneVendor.isSubscribed && (
                  <MenuItem
                    onClick={() => {
                      navitodash();
                    }}
                  >
                    <text>Dashboard</text>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    deleteValueFromLocalStorage();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              {/* <div
                className="sentctions"
                style={{ position: "relative", height: "50px" }}
              >
                <Button
                  size="small"
                  sx={{
                    width: "200px",
                    padding: "4px 8px",
                    fontSize: "12px",
                    position: "absolute",
                    right: "0",
                    zIndex: 2,
                    color: "#715326",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    navigate(ROUTINGDATA.VENDORSIGNUP);
                  }}
                >
                  <img
                    style={{ height: "30px", width: "30px" }}
                    src={vendorheader}
                    alt="img"
                  />
                  Become a vendor
                </Button>
              </div> */}
              <div className="header-btn-login">
                <Button
                  variant="contained"
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => {
                    navigate(ROUTINGDATA.VENDORSIGNIN);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  SIGN IN
                </Button>
              </div>
            </div>
          )}

          <IconButton className="header-btn-mobile-view" onClick={toggleDrawer}>
            <MenuIcon sx={{ color: "#fff", fontSize: "14px" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        classes={{ paper: "customDrawerPaper" }}
      >
        <div
          style={{ width: "100%" }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <div>
            <div className="menu-profile">
              <AccountCircleIcon sx={{ color: "#f5aa35", fontSize: 40 }} />
              <p
                onClick={() => {
                  navigate(ROUTINGDATA.VENDORSIGNIN);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Sign In
              </p>
              /{" "}
              <p
                onClick={() => {
                  navigate(ROUTINGDATA.VENDORSIGNUP);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Sign Up
              </p>
            </div>
            <Divider />
            <Typography className="subheader">Wedding Categories</Typography>
            <List>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography className="listitem-text-mobile">
                    Venues
                  </Typography>
                </ListItemText>
              </ListItemButton>
              {/* <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography className="listitem-text-mobile">
                    Rooms
                  </Typography>
                </ListItemText>
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography className="listitem-text-mobile">
                    Charters
                  </Typography>
                </ListItemText>
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography className="listitem-text-mobile">
                    Planners
                  </Typography>
                </ListItemText>
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography className="listitem-text-mobile">
                    Vendors
                  </Typography>
                </ListItemText>
              </ListItemButton> */}
            </List>
            <Divider />
            {/* <div>
              <List>
                <ListItemButton>
                  <ListItemText>
                    <Typography className="listitem-text-mobile">
                      Write a Review
                    </Typography>
                  </ListItemText>
                </ListItemButton>
                <ListItemButton>
                  <ListItemText>
                    <Typography className="listitem-text-mobile">
                      Blogs
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </List>
            </div>
            <Divider />
            <div>
              <List>
                <ListItemButton>
                  <ListItemText>
                    <Typography className="listitem-text-mobile">
                      About
                    </Typography>
                  </ListItemText>
                </ListItemButton>
                <ListItemButton>
                  <ListItemText>
                    <Typography className="listitem-text-mobile">
                      Terms & Conditions
                    </Typography>
                  </ListItemText>
                </ListItemButton>
                <ListItemButton>
                  <ListItemText>
                    <Typography className="listitem-text-mobile">
                      Privacy & policy
                    </Typography>
                  </ListItemText>
                </ListItemButton>
                <ListItemButton>
                  <ListItemText>
                    <Typography className="listitem-text-mobile">
                      Contact Us
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </List>
            </div> */}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Navigation;
