import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  FormLabel,
  Card,
  Tooltip,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../useAxios";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password should be at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Please confirm your password"),
});

function ResetPassword({ email }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const dataAxios = useAxios();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const email_id = email;
      const password = values.confirmPassword;
      try {
        const pwChange = await dataAxios.put(
          `userslogin/password/${email_id}`,
          {
            password,
          }
        );
        const pwdChange = await dataAxios.put(`vendors/password/${email_id}`, {
          password,
        });
        setOpen(true);
        resetForm();
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    navigate("/");
    setOpen(false);
  };

  const handleCancel = async () => {
    try {
      await dataAxios.delete(`password/${email}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Card sx={{ p: 4 }}>
            <div className="title">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Tooltip title="Back" arrow>
                  <ArrowCircleLeftOutlinedIcon
                    sx={{
                      fontSize: 40,
                      color: "rgb(229, 160, 0)",
                      position: "absolute",
                      left: 0,
                      paddingLeft: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleCancel();
                      navigate("/VendorSignin");
                    }}
                  />
                </Tooltip>
                <Typography variant="h4" align="center" style={{ flexGrow: 1 }}>
                  Change password
                </Typography>
              </div>
            </div>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <FormLabel sx={{ fontSize: "16px", fontWeight: "bold" }}>
                New Password<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                {...formik.getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formik.touched.password && formik.errors.password ? (
                <FormHelperText error>{formik.errors.password}</FormHelperText>
              ) : null}
            </FormControl>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <FormLabel sx={{ fontSize: "16px", fontWeight: "bold" }}>
                Confirm New Password<span style={{ color: "red" }}>*</span>
              </FormLabel>
              <TextField
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                {...formik.getFieldProps("confirmPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <FormHelperText error>
                  {formik.errors.confirmPassword}
                </FormHelperText>
              ) : null}
            </FormControl>
            <Button
              sx={{
                mt: 1,
                textTransform: "lowercase",
                color: "white",
                backgroundColor: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",

                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              variant="outlined"
              type="submit"
            >
              Reset Password
            </Button>
          </Card>
        </Box>
      </form>
      <Dialog open={open} onClick={handleClose}>
        <DialogTitle>Password Changed</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your password has been successfully changed!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              color: "rgb(229, 160, 0);",
              borderColor: "rgb(229, 160, 0);",
              "&:hover": {
                color: "white",
                backgroundColor: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",
              },
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ForgotPassword() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const dataAxios = useAxios();
  const [otps, setOtps] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [open, setOpen] = useState(false);
  const [openWrongOTP, setOpenWrongOTP] = useState(false);
  const [emptyOtp, setEmptyOtp] = useState(false);

  const handleEmailSubmit = async () => {
    const res = await dataAxios.post("userslogin/email", { email: email });
    if (res.data === "") {
      setError("Please provide a registered Email ID");
    } else if (res.data.status === "P") {
      setError("Your profile is pending for approval");
    } else if (res.data.status === "D") {
      setError("Your profile is declined , please contact admin");
    } else {
      setError("");
      await dataAxios.post("password", { email });
      setStep(2);
    }
  };

  const handleChange = (event) => {
    setOtps(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEmptySubmit = () => {
    setEmptyOtp(false);
  };
  const handleEmptySubmitOpen = () => {
    setEmptyOtp(true);
  };
  const handleClickOpenWrongOTP = () => {
    setOpenWrongOTP(true);
  };
  const handleCloseWrongOTP = () => {
    setOtps("");
    setOpenWrongOTP(false);
  };
  const handleCodeSubmit = async () => {
    try {
      const data = await dataAxios.get(`password/${email}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const otpValue = data.data.otp;
      if (otpValue === otps) {
        try {
          await dataAxios.delete(`password/${email}`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setStep(3);
        } catch (err) {
          console.log(err);
        }
      } else if (otps === "") {
        handleEmptySubmitOpen();
      } else if (otpValue === undefined || otpValue === null) {
        handleClickOpen();
      } else {
        handleClickOpenWrongOTP();
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const handleTabClose = async (event) => {
      await dataAxios.delete(`password/${email}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      event.preventDefault();
      event.returnValue = ""; // For older browsers
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          try {
            await dataAxios.delete(`password/${email}`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (err) {
            console.log(err);
          }
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const resendOTP = async () => {
    setMinutes(1);
    setSeconds(30);
    const email_id = `${email}`;
    try {
      await dataAxios.delete(`password/${email_id}`);
      await dataAxios.post("password", { email: email_id });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = async () => {
    try {
      await dataAxios.delete(`password/${email}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setStep(1);
    } catch (err) {
      console.log(err);
    }
  };
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!emailRegex.test(newEmail)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };
  return (
    <Container maxWidth="sm">
      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                User has entered expired OTP!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Please click Resend OTP and enter OTP again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={emptyOtp}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Not Entered!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Please enter the OTP and then click submit.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleEmptySubmit();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={openWrongOTP}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                User has entered Wrong OTP!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Please enter correct OTP.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleCloseWrongOTP();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <div style={{ paddingTop: "4rem" }}>
        {step === 1 && (
          <Box>
            <Card sx={{ p: 4 }}>
              <div className="title">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Tooltip title="Back" arrow>
                    <ArrowCircleLeftOutlinedIcon
                      sx={{
                        fontSize: 40,
                        color: "rgb(229, 160, 0)",
                        position: "absolute",
                        left: 0,
                        paddingLeft: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/VendorSignin");
                      }}
                    />
                  </Tooltip>
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ flexGrow: 1 }}
                  >
                    Reset Password
                  </Typography>
                </div>
              </div>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <FormLabel sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Email<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {error && <div style={{ color: "red" }}>{error}</div>}
              </FormControl>
              <Button
                sx={{
                  mt: 1,
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",

                  fontFamily: "Poppins-Medium",
                  "&:hover": {
                    textTransform: "lowercase",
                    color: "rgb(229, 160, 0);",
                    borderColor: "rgb(229, 160, 0);",
                    fontFamily: "Poppins-Medium",
                  },
                }}
                variant="outlined"
                onClick={handleEmailSubmit}
              >
                Send Code
              </Button>
            </Card>
          </Box>
        )}
        {step === 2 && (
          <Box>
            <Card sx={{ p: 4 }}>
              <div className="title">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Tooltip title="Back" arrow>
                    <ArrowCircleLeftOutlinedIcon
                      sx={{
                        fontSize: 40,
                        color: "rgb(229, 160, 0)",
                        position: "absolute",
                        left: 0,
                        paddingLeft: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleCancel();
                      }}
                    />
                  </Tooltip>
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ flexGrow: 1 }}
                  >
                    Email Verification
                  </Typography>
                </div>
              </div>
              <p style={{ textAlign: "center" }}>
                The OTP is sent to your in your email.
              </p>
              <FormControl sx={{ mt: 2 }} fullWidth>
                <FormLabel sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  OTP<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  id="mobile_number"
                  placeholder="Enter the OTP"
                  type="number"
                  name="otps"
                  value={otps}
                  onChange={handleChange}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                />
                <FormHelperText sx={{ textAlign: "center", marginTop: "10px" }}>
                  {seconds > 0 || minutes > 0 ? (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: " Poppins-Regular",
                      }}
                    >
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: " Poppins-Regular",
                      }}
                    >
                      "Didn't receive OTP?"
                      <span
                        onClick={resendOTP}
                        style={{
                          color: "red",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Resend OTP
                      </span>
                    </Typography>
                  )}
                </FormHelperText>
              </FormControl>
              <Button
                sx={{
                  mt: 1,
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",

                  fontFamily: "Poppins-Medium",
                  "&:hover": {
                    textTransform: "lowercase",
                    color: "rgb(229, 160, 0);",
                    borderColor: "rgb(229, 160, 0);",
                    fontFamily: "Poppins-Medium",
                  },
                }}
                variant="outlined"
                onClick={handleCodeSubmit}
              >
                Verify Code
              </Button>
            </Card>
          </Box>
        )}
        {step === 3 && <ResetPassword email={email} />}
      </div>
    </Container>
  );
}

export default ForgotPassword;
