import React from "react";

function ShippingPolicy() {
  return (
    <div style={styles.container}>
      <h1>Shipping & Delivery Policy</h1>
      <p>
        <strong>Effective Date:</strong> 21/06/2024
      </p>
      <p>
        Shipping is not applicable for our business as WedMySoul is a platform
        for booking wedding venues. All services provided by WedMySoul are
        digital and involve no physical shipping of goods.
      </p>
      <h2>Policy Details</h2>
      <p>
        Since our services are entirely online and involve the digital
        registration and listing of wedding venues, there is no shipping or
        physical delivery associated with any of our services. Upon successful
        registration and payment, vendors will receive confirmation via email
        and access to our platform as per their subscription plan.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about our Shipping & Delivery
        Policy, please contact us at wmsenquiry@usglobalsolutions.com.
      </p>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
};

export default ShippingPolicy;
