import React from "react";

const PricingPolicy = () => {
  return (
    <div style={styles.container}>
      <h1>Pricing Policy</h1>
      <h2>Subscription Plans:</h2>
      <ul>
        <li>3 Months Plan: INR 5,000</li>
        <li>6 Months Plan: INR 10,000</li>
        <li>12 Months Plan: INR 15,000</li>
      </ul>

      <div style={styles.section}>
        <h2>Key Features of Subscription Plans:</h2>
        <ul>
          <li>
            <strong>Flexibility:</strong> Choose a plan based on your venue
            registration needs and duration preferences.
          </li>
          <li>
            <strong>Cost-Effectiveness:</strong> Longer subscription periods
            offer greater savings per month.
          </li>
          <li>
            <strong>Access to Features:</strong> All subscription plans provide
            full access to WedMySoul's venue registration platform and its
            features.
          </li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2>Additional Notes:</h2>
        <ul>
          <li>
            <strong>Payment Methods:</strong> We accept payments through secure
            online transactions.
          </li>
          <li>
            <strong>Renewal:</strong> Subscription plans are auto-renewed at the
            end of the subscription period unless canceled.
          </li>
          <li>
            <strong>Cancellation Policy:</strong> Cancel anytime during the
            subscription period; no refunds for partial periods.
          </li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2>Benefits of Using WedMySoul:</h2>
        <ul>
          <li>
            <strong>Increased Visibility:</strong> Showcase your venue to
            couples planning their weddings.
          </li>
          <li>
            <strong>Easy Registration:</strong> Simple process to list and
            manage your venue details.
          </li>
          <li>
            <strong>Targeted Audience:</strong> Connect with users actively
            searching for wedding venues.
          </li>
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  section: {
    marginTop: "20px",
  },
};

export default PricingPolicy;
