import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1>Privacy Policy</h1>

      <h2>Introduction</h2>
      <p>
        Welcome to WedMySoul! We are committed to protecting your privacy and
        ensuring your personal information is handled in a safe and responsible
        manner. This Privacy Policy outlines how we collect, use, disclose, and
        protect your information when you use our website and related services.
        By accessing or using our website, you agree to the terms of this
        Privacy Policy.
      </p>

      <h2>Information We Collect</h2>
      <h3>Personal Information</h3>
      <p>We may collect the following personal information from you:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Billing address</li>
        <li>Payment information</li>
        <li>Account login details (username and password)</li>
      </ul>

      <h3>Information Provided by Venue Vendors</h3>
      <p>
        Venue vendors who post their venues on WedMySoul may provide additional
        information, including:
      </p>
      <ul>
        <li>Venue name and address</li>
        <li>Contact details</li>
        <li>Venue description and images</li>
        <li>Pricing and availability</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide and maintain our services</li>
        <li>
          To process transactions and send you related information, including
          purchase confirmations and invoices
        </li>
        <li>
          To communicate with you, including responding to your inquiries and
          providing customer support
        </li>
        <li>To personalize your experience on our website</li>
        <li>To improve our website and services</li>
        <li>
          To send you marketing and promotional communications, if you have
          opted in to receive them
        </li>
        <li>
          To comply with legal obligations and enforce our terms and policies
        </li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>
        We may share your information with third parties in the following
        circumstances:
      </p>
      <ul>
        <li>
          With service providers who perform services on our behalf, such as
          payment processing, data analysis, email delivery, hosting services,
          and customer service
        </li>
        <li>
          With venue vendors when you express interest in a venue, request more
          information, or make a booking
        </li>
        <li>
          In response to a legal request, such as a subpoena, court order, or
          government investigation
        </li>
        <li>
          To protect our rights, property, and safety, and the rights, property,
          and safety of our users and others
        </li>
        <li>
          In connection with a business transaction, such as a merger,
          acquisition, or asset sale
        </li>
      </ul>

      <h2>Data Security</h2>
      <p>
        We implement appropriate technical and organizational measures to
        protect your personal information from unauthorized access, use, or
        disclosure. However, no data transmission over the internet or
        electronic storage method is 100% secure, so we cannot guarantee
        absolute security.
      </p>

      <h2>Your Choices</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li>
          <strong>Access:</strong> You can request access to your personal
          information we hold.
        </li>
        <li>
          <strong>Correction:</strong> You can request correction of any
          inaccurate or incomplete personal information.
        </li>
        <li>
          <strong>Deletion:</strong> You can request deletion of your personal
          information, subject to certain exceptions.
        </li>
        <li>
          <strong>Opt-Out:</strong> You can opt-out of receiving marketing
          communications from us by following the unsubscribe instructions
          included in those communications or by contacting us directly.
        </li>
      </ul>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        We will post the updated Privacy Policy on our website and indicate the
        date of the latest revision. Your continued use of our website following
        the posting of changes constitutes your acceptance of such changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        privacy practices, please contact us at:
      </p>
      <p>
        WedMySoul
        <br />
        Email: wmsenquiry@usglobalsolutions.com
        <br />
        Phone: 9080400330
        <br />
        Address: #33/13, Second floor, Kaliamman Koil Street, SBI/Canara bank
        building, Chinmaya Nagar, Chennai, Tamil Nadu 600092
      </p>
      <p>Effective Date: 21/06/2024</p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
};

export default PrivacyPolicy;
