import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useNavigate } from "react-router-dom";
import useAxios from "../../useAxios";
import toplistingvenue1 from "../../img/venues-part-page-img/places-img/toplisting-img-1.png";
import toplistingvenue2 from "../../img/venues-part-page-img/places-img/toplisting-img-2.png";
import toplistingvenue3 from "../../img/venues-part-page-img/places-img/toplisting-img-3.png";

const AdminApprovalVenue = () => {
  const navigate = useNavigate();
  const [topVenueDatas, setTopVenueDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [recordDetails, setRecordDetails] = useState();
  const itemsPerPage = 9;
  const [topListingImgs, setTopListingImgs] = useState([
    toplistingvenue1,
    toplistingvenue2,
    toplistingvenue3,
  ]);

  const axiosData = useAxios();
  const isMobile = useMediaQuery("(max-width:600px)");

  const fetchVenues = async () => {
    setLoading(true);
    try {
      const getOneAxios = await axiosData.post(
        `venues/venues/admin/topListing`,
        {
          limit: itemsPerPage,
          page_number: page,
        }
      );
      const data = getOneAxios?.data;
      setTopVenueDatas(data?.data?.reverse());
      setRecordDetails(getOneAxios);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVenues();
  }, [page]);

  const handleViewClick = (venue, index) => {
    navigate(
      `${ROUTINGDATA.ADMINDASHBOARD}/${ROUTINGDATA.AdminApprovalVenue}/${venue._id}`,
      {
        state: {
          id: venue._id,
          getImage: index === 3 ? topListingImgs[0] : topListingImgs[index],
        },
      }
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {isMobile ? (
        <Box>
          {topVenueDatas.map((venue, index) => (
            <Card
              key={venue.id}
              sx={{ mb: 2, borderLeft: "5px solid #f5aa35" }}
            >
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <img
                    src={venue.thumbnailUrl}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <Button
                    onClick={() => handleViewClick(venue, index)}
                    sx={{
                      textTransform: "lowercase",
                      color: "white",
                      backgroundColor: "#1ABA00",
                      borderColor: "#1ABA00",
                      fontFamily: "Poppins-Medium",
                      "&:hover": {
                        textTransform: "lowercase",
                        color: "#1ABA00",
                        borderColor: "#1ABA00",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                    variant="outlined"
                  >
                    View
                  </Button>
                </Box>
                <Typography variant="h6">{venue.brand_name}</Typography>
                <Typography sx={{ color: "#70614B" }}>
                  {venue.address ? venue.address : venue.city}
                </Typography>
                <Typography sx={{ color: "#70614B" }}>
                  {venue.primary_venue_type}
                </Typography>
                <Typography sx={{ color: "#70614B" }}>
                  Capacity: {venue.capacity_persons}
                </Typography>
                <Typography sx={{ color: "#F5AA35", fontSize: "24px" }}>
                  ₹{venue.min_price_day} - ₹{venue.max_price_day}
                </Typography>
                <Typography sx={{ color: "#70614B" }}>
                  {venue.status}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Thumbnail
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Venue Name
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Venue Type
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Capacity
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topVenueDatas.map((venue, index) => (
                <TableRow key={venue.id}>
                  <TableCell>
                    <img
                      src={venue.thumbnailUrl}
                      alt="img"
                      style={{ height: "50px", width: "50px" }}
                    />
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {venue.brand_name}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {venue.address ? venue.address : venue.city}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {venue.primary_venue_type}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {venue.capacity_persons}
                  </TableCell>
                  <TableCell sx={{ color: "#F5AA35", fontSize: "24px" }}>
                    ₹{venue.min_price_day} - ₹{venue.max_price_day}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {venue.status}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleViewClick(venue, index)}
                      sx={{
                        textTransform: "lowercase",
                        color: "white",
                        backgroundColor: "#1ABA00",
                        borderColor: "#1ABA00",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "#1ABA00",
                          borderColor: "#1ABA00",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      variant="outlined"
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AdminApprovalVenue;
