import React, { useState } from "react";
import { Card, IconButton } from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import useAxios from "../../useAxios";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const axiosData = useAxios();
  const [totalVenue, setTotalVenue] = useState();
  const [totalAppVenue, setTotalAppVenue] = useState();
  const menuItems = [
    {
      name: "Total Venues",
      count: totalVenue,
      icon: <WhereToVoteOutlinedIcon sx={{ fontSize: 32 }} />,
    },
    {
      name: "Approval Request",
      count: totalAppVenue,
      icon: <CheckBoxOutlinedIcon sx={{ fontSize: 32 }} />,
    },
  ];
  const fetchVenueCount = async () => {
    const appCount = await axiosData.get("venues/approve/count");
    setTotalAppVenue(appCount.data.length);
    const count = await axiosData.get("venues/count");
    setTotalVenue(count.data.length);
  };
  fetchVenueCount();
  return (
    <div>
      <div style={{ display: isMobile ? "block" : "flex", gap: 40 }}>
        {menuItems.map((item, index) => (
          <Card
            sx={{
              width: isMobile ? "100%" : "320px",
              mb: isMobile ? 4 : 0,
              p: 2,
              borderLeft:
                index % 2 === 0 ? "8px solid #F5AA35" : "8px solid #EF510A",
              boxShadow:
                "0px 4px 6px rgba(0, 0, 0, 0.1), 0px -4px 6px rgba(0, 0, 0, 0.1), 4px 0px 6px rgba(0, 0, 0, 0.1), -4px 0px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <text style={{ fontSize: "36px", fontWeight: "bold" }}>
                {item.count}
              </text>
              <IconButton
                sx={{
                  color: "black",

                  backgroundColor: "#F5AA35",
                  "&:hover": {
                    backgroundColor: "#F5AA35",
                  },
                }}
              >
                {item.icon}
              </IconButton>
            </div>
            <text style={{ fontSize: "32px", fontWeight: "bold" }}>
              {item.name}
            </text>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
