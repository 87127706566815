import React, { useState } from "react";
import {
  Box,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import BackHandIcon from "@mui/icons-material/BackHand";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import useAxios from "../../useAxios";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import regVendor from "../Images/regVendor.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment, IconButton } from "@mui/material";
import "./Vendorshomepage/btbright.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BottomRightForm(props) {
  const [open, setOpen] = useState(true);
  const [opens, setOpens] = React.useState(true);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((show) => !show);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone No is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    password: "",
  };

  const dataAxios = useAxios();

  const submit = useLocation();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const datas = await dataAxios.post("vendors/registration", {
        name: values.name,
        email: values.email,
        mobilenumber: values.phone,
        password: values.password,
        usertype: "vendor",
      });
      if (datas) {
        setSuccessMsg(true);
        resetForm();
      }
    } catch (err) {
      console.error("Error occurred while posting form data:", err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setSuccessMsg(false);
  };

  return (
    <>
    <div className="containr-box">
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        <div style={{ color: "#70614B" }}></div>

        {open ? (
          <Paper elevation={3} sx={{ padding: 2, width: "300px" }}>
            <CloseIcon
              sx={{ color: "red", float: "right" }}
              onClick={() => {
                setOpen(false);
              }}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, handleChange, values, errors, touched }) => (
                <Form>
                  <h3 style={{ textAlign: "center" }}>Vendor Sign up</h3>

                  <TextField
                    name="name"
                    type="text"
                    placeholder="Name"
                    fullWidth
                    margin="normal"
                    label="Name"
                    variant="standard"
                    onChange={handleChange}
                    value={values.name}
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    name="phone"
                    type="text"
                    placeholder="Phone No"
                    fullWidth
                    margin="normal"
                    label="Phone No"
                    variant="standard"
                    onChange={handleChange}
                    value={values.phone}
                    error={touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                  <TextField
                    name="email"
                    type="email"
                    placeholder="Email"
                    fullWidth
                    margin="normal"
                    label="Email"
                    variant="standard"
                    onChange={handleChange}
                    value={values.email}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    fullWidth
                    margin="normal"
                    label="Password"
                    variant="standard"
                    onChange={handleChange}
                    value={values.password}
                    InputProps={{
                      style: { color: "#867760" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{
                      backgroundColor: "#f09200",
                      "&:hover": {
                        bgcolor: "#f09200",
                      },
                    }}
                  >
                    Sign up
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>
        ) : (
          <div style={{ position: "relative", display: "inline-block" }}>
            <Button
              className="popup"
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#f09200",
                "&:hover": {
                  bgcolor: "#f09200",
                },
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Are you a Vendor ? Register here
            </Button>
            {open && (
              <div className="popup">
                <img src={regVendor} alt="cutout" className="cutout-image" />
              </div>
            )}
          </div>
        )}
      </Box>
      </div>
      <Dialog
        TransitionComponent={Transition}
        open={successMsg}
        // onClose={() => setNoResultPopUp(false)}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#f09200"
          sx={{ backgroundColor: "#f09200", fontWeight: 600 }}
        >
          Successfully !
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            Thank you for registering, Your vendor account is successfully
            created
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "#f09200",
              borderColor: "#f09200",
              "&:hover": {
                color: "#f09200",
                borderColor: "#f09200",
              },
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      </>
    
  );
}

export default BottomRightForm;
