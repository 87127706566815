import React, { useState } from "react";
import "./VendorPassword.css";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../useAxios";

function VendorPassword({ setOpenModal }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const dataAxios = useAxios();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    const res = await dataAxios.post("userslogin/email", { email: email });
    if (res.data === "") {
      setError("Please provide a registered Email ID");
    } else {
      setError("");
      await dataAxios.post("password", { email });
      navigate(ROUTINGDATA.VENDOROTP, {
        state: { email: email },
      });
    }
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="title">
          <h1>Forgot Password</h1>
        </div>
        <div className="email-container">
          <label htmlFor="email">Email</label>
          <input
            className={`input-1 ${error && "input-error"}`}
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        {error && <div className="error">{error}</div>}
        {/* <div className="para-task">
          <p>The email password link</p>
        </div> */}

        <div className="footer1">
          <button onClick={() => navigate(-1)} id="cancelBtn">
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            style={{ margin: 0, color: "black", cursor: "pointer" }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default VendorPassword;
