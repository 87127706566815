import React from "react";

import ConForms from "./Conform";
const Contactus = () => {
 
  return (
    <>
      <div className="Contactus-page">
        <h2 style={{ alignItems: "center" }}>Contact US</h2>
      </div>
      <div className="location-input">
        <ConForms/>
      </div>
    </>
  );
};

export default Contactus;

