import React, { useEffect, useState, useRef } from "react";
import "../VendorsDashboard/VendorsDashboard.css";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { LinearProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxios from "../../useAxios";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { MenuItem, Select, InputLabel } from "@mui/material";
import { uploadImage } from "../../HelperFunction";
const VenuesDashboard = () => {
  const [venueApplicables, setVenueApplicables] = useState([]);
  const [venueFilterTypes, setVenueFilterTypes] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [postOffices, setPostOffices] = useState([]);
  const axiosData = useAxios();
  const [file, setFile] = useState("");

  const fileInputField = useRef(null);
  const [data, setData] = useState({});
  const [address, setAddress] = useState(false);

  const formik = useFormik({
    initialValues: {
      venue_type: "",
      email_id: "",
      brand_name: "",
      contact_person_name: "",
      additional_email_id: "",
      contact_number: "",
      description: "",
      postal_code: "",
      city: "",
      address: "",
      parking_available: "",
      primary_venue_type: "",
      rooms_available_in_your_accommodation: "",
      starting_price_basic_room: "",
      min_price_day: "",
      max_price_day: "",
      capacity_persons: "",
      thumbnail: null,
      thumbnailUrl: "",
    },
    validationSchema: Yup.object({
      venue_type: Yup.string(),
      email_id: Yup.string()
        .required("Email must be Required")
        .email("Invalid Email")
        .matches(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          "Invalid email format"
        ),
      brand_name: Yup.string().required("Brand Name must be Required"),
      contact_person_name: Yup.string().required("must required"),
      additional_email_id: Yup.string().email("invalid Email"),
      contact_number: Yup.string()
        .required("Contact Number must be Required")
        .length(10, "The Number is Invalid")
        .max(10),
      description: Yup.string(),
      // postal_code:Yup.string().required("Pincode Must Be required"),
      // city: Yup.string().required("City must be Required"),
      address: Yup.string(),
      parking_available: Yup.string(),
      primary_venue_type: Yup.string(),
      rooms_available_in_your_accommodation: Yup.string(),
      starting_price_basic_room: Yup.string(),
      min_price_day: Yup.string().required("Enter the Minimum price"),
      max_price_day: Yup.string().required("Enter the Maximum price"),
      capacity_persons: Yup.string(),
      // thumbnail: Yup.mixed()
      //   .required("Image is required")
      //   .test(
      //     "fileSize",
      //     "File size too large",
      //     (value) => value && value.size <= 8000000
      //   ) // Example: 2MB limit
      //   .test(
      //     "fileType",
      //     "Unsupported file type",
      //     (value) => value && ["image/jpeg", "image/png"].includes(value.type)
      //   ),
    }),
    onSubmit: async (values) => {
      if (values.thumbnail) {
        const newUrls = await uploadImage("thumbnail", values.thumbnail, true);
        values.applicable_things_in_venue = venueApplicables.join(",");
        values.venue_type = venueFilterTypes.join(",");
        values.city = values.post_office;
        values.thumbnailUrl = newUrls.join(", ");
        await axiosData
          .post("venues", values, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            formik.handleReset("");
            setVenueApplicables([]);
            setVenueFilterTypes([]);
            navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.PLANNER}`);
            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((err) => console.log(err));
      }
    },
  });
  const MAX_FILE_SIZE = 1048576 * 10;
  const handleFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setFile("File is too large");
    }
    setData(e.target.files[0].size);
  };

  const navigate = useNavigate();

  const userEmail = localStorage.getItem("userEmail");

  useEffect(() => {
    formik.setFieldValue("email_id", userEmail);
    // fetchCountryData();
    setVenueApplicables([]);
  }, []);

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("thumbnail", file);

    setPreviewImage(URL.createObjectURL(file));
  };

  const dataAxios = useAxios();

  const fetchData = async (url, setter) => {
    try {
      const response = await dataAxios.get(url);
      if (response.data) {
        setter(response.data.map((item) => item.Name || item.Division));
      } else {
        console.log("No data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const fetchCountryData = async (pincode) => {
  //   try {
  //     const response = await dataAxios.get(`https://api.postalpincode.in/pincode/${pincode}`);
  //     if (response.data && response.data[0].PostOffice) {
  //       const divisions = response.data.map(({ Division }) => Division);
  //       setDivisions(divisions);
  //     } else {
  //       console.log("No postal codes found for the specified district.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching postal codes:", error.message);
  //   }
  // };

  // const handleCheckButtonClick = () => {
  //   fetchCountryData(formik.values.postal_code);
  // };

  const handleDivisionChange = (event) => {
    const division = event.target.value;
    formik.setFieldValue("division", division);

    // fetchData(`https://api.postalpincode.in/postoffice/${division}`, setPostOffices);
  };
  const fetchpincode = async (event) => {
    formik.setFieldValue("postal_code", event.target.value);
    const pincode = event.target.value;
    try {
      const response = await dataAxios.get(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      if (response.data && response.data.length > 0) {
        const data = response.data[0];
        const places = data?.PostOffice.map((office) => office?.Name).filter(
          (place) => place
        );
        setDivisions([...places]);
        //  setPlaceOptions(places);
        const state = data?.PostOffice[0]?.State || "";
        const Country = data?.PostOffice[0]?.Country || "";
        const distict = data?.PostOffice[0]?.District || "";
        formik.setFieldValue("post_office", distict);
        // setStateValue(state);
        // setCountryValue(Country);
      } else {
        // setPlaceOptions([]);
        // setStateValue("");
        // setCountryValue("");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // setPlaceOptions([]);
      // setStateValue("");
      // setCountryValue("");
    }
  };
  const handlePostOfficeChange = (event) => {
    const postOffice = event.target.value;
    formik.setFieldValue("post_office", postOffice);
  };

  return (
    <div id="VenuesDashboard-block" className="VenuesDashboard-block">
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <div className="dashboard-split-dawer">
            <div className="navigation-vendors"></div>
            <div className="form-for-vendors">
              <h3>Venue Registration</h3>

              <div
                id="from-inpust-box2-dashboard"
                className="from-inpust-box2-dashboard"
              >
                <Divider>
                  <div className="form-for-vendors">
                    <h3>Personal Details</h3>
                  </div>
                </Divider>
                <div className="dashboard-personal-textfield">
                  <label>Login Email ID</label>

                  <div>
                    <TextField
                      id="outlined-basic"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                          borderBottom: "none",
                        },
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled
                      value={formik.values.email_id}
                    />
                    <Button variant="contained">Make primary</Button>
                  </div>
                </div>

                <div className="dashboard-personal-textfield">
                  <label>Contact person name</label>
                  <div>
                    <TextField
                      id="outlined-basic"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                          borderBottom: "none",
                        },
                      }}
                      name="contact_person_name"
                      fullWidth
                      size="small"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.contact_person_name}
                      error={
                        formik.touched.contact_person_name &&
                        Boolean(formik.errors.contact_person_name)
                      }
                      helperText={
                        formik.touched.contact_person_name &&
                        formik.errors.contact_person_name
                      }
                    />
                  </div>
                </div>
                <div className="dashboard-personal-textfield">
                  <label>Venue email ID</label>
                  <div>
                    <TextField
                      id="outlined-basic"
                      sx={{
                        "& fieldset": { borderRadius: "0px" },
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="additional_email_id"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.additional_email_id}
                      error={
                        formik.touched.additional_email_id &&
                        Boolean(formik.errors.additional_email_id)
                      }
                      helperText={
                        formik.touched.additional_email_id &&
                        formik.errors.additional_email_id
                      }
                    />
                  </div>
                </div>
                <div
                  className="dashboard-personal-textfield"
                  style={{ padding: "10px 0px" }}
                >
                  <label>Contact number*</label>
                  <div className="number-field-dashboard">
                    <Button
                      className="numberbtn"
                      endIcon={<ArrowDropDownIcon sx={{ color: "#180806" }} />}
                    >
                      +91
                    </Button>
                    <TextField
                      id="outlined-basic"
                      sx={{
                        "& fieldset": { borderRadius: "0px" },
                      }}
                      fullWidth
                      variant="standard"
                      name="contact_number"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.contact_number &&
                        Boolean(formik.errors.contact_number)
                      }
                      helperText={
                        formik.touched.contact_number &&
                        formik.errors.contact_number
                      }
                      onChange={formik.handleChange}
                      value={formik.values.contact_number}
                    />

                    {/* <div className="add-more-btn">
                      <text>
                        <AddCircleIcon
                          sx={{ fontSize: 20, color: "#F5AA35" }}
                        />
                        ADD MORE
                      </text>
                    </div> */}
                  </div>
                </div>

                <Divider>
                  <div className="form-for-vendors">
                    <h3> Venue Details</h3>
                  </div>
                </Divider>

                <div className="dashboard-personal-textfield">
                  <label>Venue Name*</label>
                  <div>
                    <TextField
                      required
                      id="outlined-basic"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                          borderBottom: "none",
                        },
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="brand_name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.brand_name}
                      error={
                        formik.touched.brand_name &&
                        Boolean(formik.errors.brand_name)
                      }
                      helperText={
                        formik.touched.brand_name && formik.errors.brand_name
                      }
                    />
                  </div>
                </div>
                <div className="dashboard-personal-textfield">
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    Description About Venue
                    <span style={{ fontSize: "12px" }}>
                      (To update your description, please send a mail to
                      contact@WedMySoul.com)
                    </span>
                  </label>

                  <div>
                    <TextField
                      id="outlined-basic"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                          borderBottom: "none",
                        },
                      }}
                      placeholder="Enter your message..."
                      fullWidth
                      size="small"
                      multiline
                      rows={5}
                      variant="outlined"
                      value={formik.values.description}
                      name="description"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </div>
                </div>

                <div>
                  <div className="dashboard-personal-textfield">
                    <label>Pincode*</label>
                    <div>
                      <TextField
                        id="outlined-basic"
                        placeholder="Pincode"
                        variant="outlined"
                        fullWidth
                        name="postal_code"
                        onBlur={formik.handleBlur}
                        onChange={(e) => fetchpincode(e)}
                        value={formik.values.postal_code}
                      />
                    </div>
                  </div>
                  <div className="dashboard-personal-textfield">
                    <label>City*</label>
                    <div>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          labelId="post-office-label"
                          placeholder="City"
                          name="post_office"
                          onBlur={formik.handleBlur}
                          value={formik.values.post_office}
                        ></TextField>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="dashboard-personal-textfield">
                  <label>Area*</label>
                  <div>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        labelId="division-label"
                        placeholder="City"
                        onBlur={formik.handleBlur}
                        value={formik.values.division}
                        onChange={handleDivisionChange}
                      >
                        {divisions &&
                          divisions.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  {/* <Button onClick={handleCheckButtonClick} variant="contained">Check</Button> */}
                </div>

                <div
                  className="dashboard-personal-textfield"
                  style={{ padding: "15px 0px" }}
                >
                  <label>Address</label>
                  {address === false ? (
                    <div
                      className="add-more-btn"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <text
                        onClick={() => {
                          setAddress(true);
                        }}
                      >
                        <AddCircleIcon
                          sx={{ fontSize: 20, color: "#F5AA35" }}
                        />
                        ADD MORE
                      </text>
                    </div>
                  ) : (
                    <div className="dashboard-personal-textfield">
                      <div style={{ width: "100%" }}>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            "& fieldset": {
                              borderRadius: "0px",
                            },
                            width: "100%",
                          }}
                          placeholder="Enter your message..."
                          fullWidth
                          size="small"
                          multiline
                          rows={5}
                          variant="outlined"
                          name="address"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.address &&
                            Boolean(formik.errors.address)
                          }
                          helperText={
                            formik.touched.address && formik.errors.address
                          }
                          value={formik.values.address}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="additional-details-dashboard">
                  <h3>Additional details</h3>
                  <div className="dashboard-personal-textfield">
                    <label>Max price per day</label>
                    <div>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                            borderBottom: "none",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={formik.handleChange}
                        name="max_price_day"
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.max_price_day &&
                          Boolean(formik.errors.max_price_day)
                        }
                        helperText={
                          formik.touched.max_price_day &&
                          formik.errors.max_price_day
                        }
                        value={formik.values.max_price_day}
                      />
                    </div>
                  </div>
                  <div className="dashboard-personal-textfield">
                    <label>Min price per day</label>
                    <div>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                            borderBottom: "none",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        name="min_price_day"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.min_price_day &&
                          Boolean(formik.errors.min_price_day)
                        }
                        helperText={
                          formik.touched.min_price_day &&
                          formik.errors.min_price_day
                        }
                        value={formik.values.min_price_day}
                      />
                    </div>
                  </div>
                  <div className="dashboard-personal-textfield">
                    <label>Capacity(No.of.persons)</label>
                    <div>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={formik.handleChange}
                        name="capacity_persons"
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.capacity_persons &&
                          Boolean(formik.errors.capacity_persons)
                        }
                        helperText={
                          formik.touched.capacity_persons &&
                          formik.errors.capacity_persons
                        }
                        value={formik.values.capacity_persons}
                      />
                    </div>
                  </div>

                  <div className="raidio-btnquts">
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        <label>Is parking available at the venue?</label>
                      </FormLabel>
                      <RadioGroup
                        className="radiogroup-title"
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("parking_available", value);
                          }
                        }}
                        value={formik.values.parking_available}
                      >
                        <FormControlLabel
                          className="sublabel"
                          value="There is sufficient parking available"
                          control={<Radio size="small" />}
                          label={
                            <div className="sublabel">
                              There is sufficient parking available
                            </div>
                          }
                        />
                        <FormControlLabel
                          className="sublabel"
                          value="Parking is available near the venue"
                          control={<Radio size="small" />}
                          label={
                            <div className="sublabel">
                              Parking is available near the venue
                            </div>
                          }
                        />
                        <FormControlLabel
                          className="sublabel"
                          value="No parking available"
                          control={<Radio size="small" />}
                          label={
                            <div className="sublabel">No parking available</div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  {/*                 
                  <div className="raidio-btnquts">
                    <label>
                      What is the starting price for vegetarian menu? (assume
                      250 pax and standard menu)
                    </label>
                    <div style={{ marginTop: "20px", width: "100%" }}>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("starting_price_for_vegetarian_menu", value);
                          }
                        }}
                        value={formik.values.starting_price_for_vegetarian_menu}
                      />
                    </div>
                  </div>
                  <div className="raidio-btnquts">
                    <label>
                      What is the starting price for a non-veg menu? (assume 250
                      pax and standard menu)
                    </label>
                    <div style={{ marginTop: "20px", width: "100%" }}>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("starting_price_for_non_vegetarian_menu", value);
                          }
                        }}
                        value={formik.values.starting_price_for_non_vegetarian_menu}
                      />
                    </div>
                  </div> */}

                  {/* <div className="raidio-btnquts">
                    <label>Venue Type filter</label>
                    <div style={{ marginTop: "10px" }}>
                      <FormGroup
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("venue_type", value);
                          }
                        }}
                        value={formik.values.venue_type}
                      >
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label={<div className="sublabel">Indoor</div>}
                          value="Indoor"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              if (e.target.checked === true) {
                                venueFilterTypes.push(value);
                              } else {
                                venueFilterTypes.splice(
                                  venueFilterTypes.indexOf(`${value}`),
                                  1
                                );
                              }
                            }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label={<div className="sublabel">Outdoor</div>}
                          value="Outdoor"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              if (e.target.checked === true) {
                                venueFilterTypes.push(value);
                              } else {
                                venueFilterTypes.splice(
                                  venueFilterTypes.indexOf(`${value}`),
                                  1
                                );
                              }
                            }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label={<div className="sublabel">Poolside</div>}
                          value="Poolside"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              if (e.target.checked === true) {
                                venueFilterTypes.push(value);
                              } else {
                                venueFilterTypes.splice(
                                  venueFilterTypes.indexOf(`${value}`),
                                  1
                                );
                              }
                            }
                          }}
                        />
                        <FormControlLabel
                          control={<Checkbox size="small" />}
                          label={<div className="sublabel">Terrace</div>}
                          value="Terrace"
                          onChange={(e) => {
                            if (e) {
                              const value = e.target.value;
                              if (e.target.checked === true) {
                                venueFilterTypes.push(value);
                              } else {
                                venueFilterTypes.splice(
                                  venueFilterTypes.indexOf(`${value}`),
                                  1
                                );
                              }
                            }
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div> */}
                  <div className="raidio-btnquts">
                    <label>
                      How many rooms are available in your accommodation?
                    </label>
                    <div style={{ marginTop: "20px", width: "100%" }}>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        name="rooms_available_in_your_accommodation"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched
                            .rooms_available_in_your_accommodation &&
                          Boolean(
                            formik.errors.rooms_available_in_your_accommodation
                          )
                        }
                        helperText={
                          formik.touched
                            .rooms_available_in_your_accommodation &&
                          formik.errors.rooms_available_in_your_accommodation
                        }
                        value={
                          formik.values.rooms_available_in_your_accommodation
                        }
                      />
                    </div>
                  </div>
                  <div className="raidio-btnquts">
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        <label>Primary Venue Type</label>
                      </FormLabel>
                      <RadioGroup
                        className="radiogroup-title"
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value;
                            formik.setFieldValue("primary_venue_type", value);
                          }
                        }}
                        value={formik.values.primary_venue_type}
                      >
                        <FormControlLabel
                          className="sublabel"
                          value="Wedding Halls"
                          control={<Radio size="small" />}
                          label={<div className="sublabel">Wedding Halls</div>}
                        />
                        <FormControlLabel
                          className="sublabel"
                          value="Banquet Halls"
                          control={<Radio size="small" />}
                          label={<div className="sublabel">Banquet Halls</div>}
                        />
                        <FormControlLabel
                          className="sublabel"
                          value="Resorts"
                          control={<Radio size="small" />}
                          label={<div className="sublabel">Resorts</div>}
                        />
                        <FormControlLabel
                          className="sublabel"
                          value="Beachside Venues"
                          control={<Radio size="small" />}
                          label={
                            <div className="sublabel">Beachside Venues</div>
                          }
                        />

                        <FormControlLabel
                          className="sublabel"
                          value="Community Halls"
                          control={<Radio size="small" />}
                          label={
                            <div className="sublabel">Community Halls</div>
                          }
                        />
                        <FormControlLabel
                          className="sublabel"
                          value="Open-air Venues"
                          control={<Radio size="small" />}
                          label={
                            <div className="sublabel">Open air Venues</div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="raidio-btnquts">
                    {/* <label>
                      What is the starting price for a basic room at your hotel?
                    </label>
                    <div style={{ marginTop: "20px", width: "100%" }}>
                      <TextField
                        id="outlined-basic"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                        }}
                        size="small"
                        variant="outlined"
                        name="starting_price_basic_room"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.starting_price_basic_room &&
                          Boolean(formik.errors.starting_price_basic_room)
                        }
                        helperText={
                          formik.touched.starting_price_basic_room &&
                          formik.errors.starting_price_basic_room
                        }
                        value={formik.values.starting_price_basic_room}
                      />
                    </div> */}
                    <div className="dashboard-personal-textfield">
                      <label htmlFor="image">Upload Image:</label>
                      <input
                        id="image"
                        name="image"
                        type="file"
                        // value={formik.values.thumbnail}
                        onChange={handleImageChange}
                      />
                      {formik.errors.image && formik.touched.image && (
                        <div className="error">{formik.errors.image}</div>
                      )}
                    </div>
                    {previewImage && (
                      <div>
                        <img
                          src={previewImage}
                          alt="Preview"
                          style={{ maxWidth: "200px" }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="save-btn-dashboard">
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default VenuesDashboard;
