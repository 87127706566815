import React, { useEffect, useState } from "react";
import Breadcrumb from "../BreadCrumb";
import { Formik, Form } from "formik";
import {
  Button,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import GroupsIcon from "@mui/icons-material/Groups";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import RateReviewIcon from "@mui/icons-material/RateReview";
import star from "../../img/Star 2.png";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import Rating from "@mui/material/Rating";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import toplistingvenue1 from "../../img/venues-part-page-img/places-img/toplisting-img-1.png";
import toplistingvenue2 from "../../img/venues-part-page-img/places-img/toplisting-img-2.png";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router";
import useAxios from "../../useAxios";
import * as Yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { ROUTINGDATA } from "../ROUTINGDATA";

const AdminVenueApproval = () => {
  var datetime = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const navigate = useNavigate();
  const [openes, setOpenes] = useState(false);
  const location = useLocation();
  const axiosData = useAxios();
  const [venueDetails, setVenueDetails] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const today = moment().subtract(1, "days");
  const [to, setTo] = useState(moment());
  const [viewphotos, setviewphotos] = useState(true);
  const [photodata, setphotodata] = useState([]);
  const [viewVideos, setViewVideos] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openDecline, setOpenDecline] = useState(false);
  const [reasonDec, setReasonDec] = useState(false);
  const [reason, setReason] = useState("");
  useEffect(() => {
    setVenueDetails(location?.state?.data);
    fetchVenuesDetails(location?.state.id);
    fetchphotos(location?.state.id);

    if (location.state && location.state.id) {
      fetchVideos(location.state.id);
    }
  }, [location.state]);

  const fetchVenuesDetails = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`venues/${id}`);
      const data = getOneAxios?.data;
      if (data) {
        setVenueDetails(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const fetchphotos = async (id) => {
    try {
      const multiphoto = await axiosData.get(`venues/${id}`);
      setImageUrl(multiphoto?.data?.imageUrl);
      setphotodata(multiphoto.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const fetchVideos = async (id) => {
    try {
      if (!id) {
        console.error("No id provided to fetch videos");
        return;
      }

      // Make sure axiosData is properly imported and available
      const response = await axiosData.get(`venues/${id}`);
      setVideoData(response.data.videoUrl);
    } catch (error) {
      console.error("Error fetching videos:", error);
      // You may want to set an empty array or handle the error differently
      setVideoData([]);
    }
  };

  useEffect(() => {
    // setVenueDetails(location?.state?.data)
    fetchVenuesDetails(location?.state.id);
  }, []);
  const [top, settop] = useState("25%");

  const stickyDivStyle = {
    top: top,
  };

  const openabout = () => {
    setviewphotos(false);
    setViewVideos(false);
  };

  const openviewphotoses = () => {
    setviewphotos(true);
    setViewVideos(false);
  };
  const openViewVideos = () => {
    setviewphotos(false);
    setViewVideos(true);
  };

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setOpenImagePreview(true);
  };

  const handleCloseImagePreview = () => {
    setOpenImagePreview(false);
    setCurrentIndex(0);
  };
  const dataAxios = useAxios();

  const validationSchema = Yup.object().shape({
    event_name: Yup.string().required("Name is not required"),
    mobile_number: Yup.string().required("Phone No is not required"),
    email_address: Yup.string()
      .email("Invalid email")
      .required("Email is not required"),
    full_name: Yup.string().required("Name is not required"),
  });
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  let images = [];
  if (imageUrl.length > 0) {
    imageUrl.forEach((item) => {
      if (item && item.url) {
        images.push(...item.url);
      }
    });
  }
  let videos = [];
  if (videoData.length > 0) {
    videoData.forEach((item) => {
      if (item && item.url) {
        videos.push(...item.url);
      }
    });
  }
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpenDecline(false);
    setReasonDec(false);
  };
  const handleApprove = async () => {
    await axiosData.put(`venues/status/${location?.state.id}`, {
      status: "A",
      venue_name: venueDetails.brand_name,
      email: venueDetails.additional_email_id,
      venue_number: venueDetails.contact_number,
    });
    setOpen(false);
  };
  const handleDecline = () => {
    setReasonDec(true);
    setOpenDecline(false);
  };
  const handleDeclineSubmit = async () => {
    await axiosData.put(`venues/status/${location?.state.id}`, {
      status: "D",
      reason: reason,
      venue_name: venueDetails.brand_name,
      email: venueDetails.additional_email_id,
    });
    setReason("");
    setReasonDec(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
            width: "500px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Confirm Approval
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "green" }}
            >
              Are you sure you want to approve this venue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                borderColor: "rgb(229, 160, 0);",
                backgroundColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                color: "white",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleApprove();
              }}
            >
              Approve
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={openDecline}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
            width: "500px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Confirm Decline
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Are you sure you want to decline this venue? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                borderColor: "rgb(229, 160, 0);",
                backgroundColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                color: "white",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleDecline();
              }}
            >
              Decline
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={reasonDec}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
            width: "500px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Reason
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Give the reason of the decline :
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              label="Reason for Decline"
              type="text"
              fullWidth
              variant="outlined"
              sx={{ mt: 2, fontFamily: "Poppins-Medium" }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0)",
                borderColor: "rgb(229, 160, 0)",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "rgb(229, 160, 0)",
                  borderColor: "rgb(229, 160, 0)",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                borderColor: "rgb(229, 160, 0)",
                backgroundColor: "rgb(229, 160, 0)",
                fontFamily: "Poppins-Medium",
                color: "white",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0)",
                  borderColor: "rgb(229, 160, 0)",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleDeclineSubmit();
              }}
            >
              Decline
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Tooltip title="Back" arrow>
            <ArrowCircleLeftOutlinedIcon
              sx={{
                fontSize: 50,
                color: "rgb(229, 160, 0)",
                position: "absolute",
                left: 0,
                cursor: "pointer",
                mt: 1,
              }}
              onClick={() => {
                navigate(
                  ROUTINGDATA.ADMINDASHBOARD +
                    "/" +
                    ROUTINGDATA.ADMINVenueApproval
                );
              }}
            />
          </Tooltip>
        </div>
      </Container>
      <Container sx={{ mt: 5 }}>
        <div id="venues-details-splited" className="venues-details-splited">
          <div className="venues-details-box-1">
            <div className="img-venues-details-main">
              <img src={venueDetails?.thumbnailUrl} alt="img" />
              <div className="imgbar-venues-details">
                <text
                  style={{
                    cursor: "pointer",
                    backgroundColor: viewphotos ? "orange" : "inherit",
                  }}
                  onClick={openviewphotoses}
                >
                  Photos
                </text>
                <Divider orientation="vertical" flexItem />
                <text
                  style={{
                    cursor: "pointer",
                    backgroundColor: viewVideos ? "orange" : "inherit",
                  }}
                  onClick={openViewVideos}
                >
                  Videos
                </text>
                <Divider orientation="vertical" flexItem />
                <text
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      !viewphotos && !viewVideos ? "orange" : "inherit",
                  }}
                  onClick={openabout}
                >
                  About
                </text>
              </div>
            </div>

            {viewphotos ? (
              <div>
                <div style={{ marginTop: "70px" }}>
                  <Grid container>
                    {images.length > 0 ? (
                      images.map((photo, index) => (
                        <Grid
                          sx={{ p: 2 }}
                          xs={12}
                          md={3}
                          key={index}
                          onClick={() => handleImageClick(photo, index)}
                        >
                          <Typography>{photo.name}</Typography>

                          <div className="imges">
                            <img
                              src={photo}
                              alt=""
                              style={{
                                height: "100px",
                                width: "150px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Grid>
                      ))
                    ) : (
                      <Typography
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        Images not upload
                      </Typography>
                    )}
                  </Grid>
                </div>
                {openImagePreview && (
                  <Dialog
                    open={openImagePreview}
                    onClose={handleCloseImagePreview}
                  >
                    <DialogTitle>
                      {images[currentIndex] && images[currentIndex]?.name}
                      {/* {selectedImage && selectedImage.name} */}
                      <IconButton
                        aria-label="close"
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "3px",
                        }}
                        onClick={handleCloseImagePreview}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <img
                          src={images[currentIndex]}
                          alt=""
                          style={{ maxWidth: "100%" }}
                        />
                        {images.length > 1 && (
                          <>
                            <IconButton
                              onClick={handlePrev}
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "10px",
                                transform: "translateY(-50%)",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                              }}
                            >
                              <ArrowBackIosIcon />
                            </IconButton>
                            <IconButton
                              onClick={handleNext}
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                              }}
                            >
                              <ArrowForwardIosIcon />
                            </IconButton>
                          </>
                        )}
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            ) : viewVideos ? (
              <div>
                <div style={{ marginTop: "70px" }}>
                  <Grid
                    sx={{ height: 10 }}
                    container
                    style={{ width: "100%", height: "0%" }}
                    spacing={{ xs: 1, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {videos.length > 0 ? (
                      videos.map((video, index) => (
                        <Grid sx={{ p: 2 }} xs={3} md={3} key={index}>
                          <div className="videos">
                            <video
                              style={{
                                height: "100px",
                                width: "150px",
                                cursor: "pointer",
                              }}
                              controls
                            >
                              <source src={video} type="video/mp4" />
                            </video>
                          </div>
                        </Grid>
                      ))
                    ) : (
                      <Typography
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        Videos not upload
                      </Typography>
                    )}
                  </Grid>
                </div>
              </div>
            ) : null}
            <div>
              <div className="rating-box-in-mobile">
                <div className="spaces-fristbox">
                  <h3>{venueDetails?.brand_name}</h3>
                  <text>
                    <LocationOnIcon
                      sx={{ color: "#7A5620", fontSize: "23px" }}
                    />
                    {venueDetails?.city}
                  </text>
                  <text>{venueDetails?.address}</text>
                  <p>
                    <GroupsIcon sx={{ color: "#7A5620", fontSize: "23px" }} />
                    Capacity {venueDetails?.capacity_persons} guests{" "}
                  </p>
                  <p className="range-content">
                    <span>₹{venueDetails?.min_price_day} </span>-{" "}
                    <span>₹{venueDetails?.max_price_day}</span> Price range
                  </p>
                </div>
                {/* <div className="btns-actions">
                  <div style={{ borderRadius: "0px 0px 0px 6px" }}>
                    <RateReviewIcon sx={{ color: "#b48a4a" }} />
                    Review
                  </div>
                  <div>
                    <FavoriteBorderOutlinedIcon sx={{ color: "#b48a4a" }} />
                    Shortlist
                  </div>
                  <div style={{ borderRadius: "0px 0px 6px 0px" }}>
                    <ShareIcon sx={{ color: "#b48a4a" }} /> Share
                  </div>
                </div> */}
                <div
                  style={{
                    padding: "1rem",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      textTransform: "lowercase",
                      color: "#565656",
                      backgroundColor: "#D1D1D1",
                      borderColor: "#D1D1D1",

                      fontFamily: "Poppins-Medium",
                      "&:hover": {
                        textTransform: "lowercase",
                        color: "#565656",
                        borderColor: "#D1D1D1",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                    onClick={() => {
                      setOpenDecline(true);
                    }}
                    variant="outlined"
                    disabled={venueDetails?.status === "D"}
                  >
                    Decline
                  </Button>
                  <Button
                    sx={{
                      textTransform: "lowercase",
                      color: "white",
                      backgroundColor: "#1ABA00",
                      borderColor: "#1ABA00",

                      fontFamily: "Poppins-Medium",
                      "&:hover": {
                        textTransform: "lowercase",
                        color: "#1ABA00",
                        borderColor: "#1ABA00",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                    variant="outlined"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={venueDetails?.status === "A"}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            </div>

            <div className="second-box-venues-details">
              <h1>About the venues name and location of venue</h1>

              <p>{venueDetails?.description}</p>
            </div>
            <div className="third-box-veneus-details">
              <div className="third-box-veneus-details-split">
                <div className="third-box-content-title">
                  <h3>Room Count</h3>
                  <text>
                    {venueDetails?.rooms_available_in_your_accommodation} rooms
                  </text>
                </div>

                <div className="third-box-content-title">
                  <h3> Parking</h3>
                  <text>{venueDetails?.parking_available}</text>
                </div>

                <div className="third-box-content-title">
                  <h3> Space</h3>
                  <text>{venueDetails?.primary_venue_type}</text>
                </div>
              </div>
            </div>
          </div>
          <div id="venues-details-box-2" className="venues-details-box-2">
            <div className="position-fixed-side-content" style={stickyDivStyle}>
              <div className="frist-box-venues-details-2">
                <div className="spaces-fristbox">
                  <h3>{venueDetails?.brand_name}</h3>
                  <text>
                    <LocationOnIcon
                      sx={{ color: "#7A5620", fontSize: "23px" }}
                    />
                    {venueDetails?.city}
                  </text>
                  <text>{venueDetails?.address}</text>
                  <p>
                    <GroupsIcon sx={{ color: "#7A5620", fontSize: "23px" }} />
                    Capacity {venueDetails?.capacity_persons} guests{" "}
                  </p>
                  <p className="range-content">
                    <span>₹{venueDetails?.min_price_day} </span>-{" "}
                    <span>₹{venueDetails?.max_price_day}</span> Price range
                  </p>
                </div>
                {/* <div className="btns-actions">
                  <div style={{ borderRadius: "0px 0px 0px 6px" }}>
                    <RateReviewIcon sx={{ color: "#b48a4a" }} />
                    Review
                  </div>
                  <div>
                    <FavoriteBorderOutlinedIcon sx={{ color: "#b48a4a" }} />
                    Shortlist
                  </div>
                  <div style={{ borderRadius: "0px 0px 6px 0px" }}>
                    <ShareIcon sx={{ color: "#b48a4a" }} /> Share
                  </div>
                </div> */}
                <div
                  style={{
                    padding: "1rem",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      textTransform: "lowercase",
                      color: "#565656",
                      backgroundColor: "#D1D1D1",
                      borderColor: "#D1D1D1",

                      fontFamily: "Poppins-Medium",
                      "&:hover": {
                        textTransform: "lowercase",
                        color: "#565656",
                        borderColor: "#D1D1D1",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                    onClick={() => {
                      setOpenDecline(true);
                    }}
                    variant="outlined"
                    disabled={venueDetails?.status === "D"}
                  >
                    Decline
                  </Button>
                  <Button
                    sx={{
                      textTransform: "lowercase",
                      color: "white",
                      backgroundColor: "#1ABA00",
                      borderColor: "#1ABA00",

                      fontFamily: "Poppins-Medium",
                      "&:hover": {
                        textTransform: "lowercase",
                        color: "#1ABA00",
                        borderColor: "#1ABA00",
                        fontFamily: "Poppins-Medium",
                      },
                    }}
                    variant="outlined"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={venueDetails?.status === "A"}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Dialog open={openes} onClose={() => setOpenes(false)}>
        <DialogTitle>
          Venue Enquiry Form
          <IconButton
            aria-label="close"
            onClick={() => setOpenes(false)}
            style={{ position: "absolute", right: "8px", top: "8px" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details below to send your enquiry.
          </DialogContentText>
          <Formik
            initialValues={{
              full_name: "",
              email_address: "",
              mobile_number: "",
              event_name: "",
              event_date: moment().format("DD/MM/YYYY"),
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              values.venue_id = venueDetails.id;
              values.venue_email = venueDetails.additional_email_id;
              values.venue_name = venueDetails.brand_name;
              values.venue_number = venueDetails.contact_number;
              const dataToSend = {
                full_name: values.full_name,
                email_address: values.email_address,
                mobile_number: values.mobile_number,
                event_name: values.event_name,
                event_date: values.event_date,
              };
              const response = await dataAxios.post("enquiry", values);
              setSubmitting(false);
              resetForm();
              setOpenes(false);
              setOpenSnackbar(true);
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="event_name"
                  label="Event Name"
                  value={values.event_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="full_name"
                  label="Full Name"
                  value={values.full_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="email_address"
                  label="Email Address"
                  value={values.email_address}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="mobile_number"
                      label="Mobile Number"
                      value={values.mobile_number}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Event Date"
                        sx={{ marginTop: 2 }}
                        format="DD/MM/YYYY"
                        value={moment(values.event_date, "DD/MM/YYYY")}
                        onChange={(date) =>
                          setFieldValue("event_date", date.format("DD/MM/YYYY"))
                        }
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            fullWidth
                            margin="normal"
                            value={values.event_date}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#f09200",
                      "&:hover": {
                        bgcolor: "#f09200",
                      },
                    }}
                    disabled={isSubmitting}
                  >
                    Submit Enquiry
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog open={openSnackbar} onClose={handleSnackbarClose}>
        <DialogTitle>Enquiry Submitted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your venue enquiry has been submitted successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSnackbarClose}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#f09200",
              "&:hover": {
                bgcolor: "#f09200",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminVenueApproval;
