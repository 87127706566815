import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Collapse, Slide, useMediaQuery } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Logo from "../../img/logo.png";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(isMobile
        ? { marginLeft: 0 } // In mobile view, no margin adjustments
        : {
            marginLeft: `-${drawerWidth}px`,
            ...(open && {
              transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
              marginLeft: 0,
            }),
          }),
    };
  }
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isMobile
      ? {
          width: "100%", // Keep AppBar full width on mobile
          marginLeft: 0, // No margin adjustments on mobile
        }
      : open
      ? {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }
      : {
          width: "100%", // Keep AppBar full width when drawer is closed
          marginLeft: 0,
        }),
  };
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function AdminDashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isMobile);
  const [selectedMenu, setSelectedMenu] = React.useState("Dashboard");
  const [expandedItems, setExpandedItems] = React.useState({});
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const savedSelectedMenu = localStorage.getItem("selectedMenu");
    if (savedSelectedMenu) {
      setSelectedMenu(savedSelectedMenu);
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (menuName, link) => {
    setSelectedMenu(menuName);
    localStorage.setItem("selectedMenu", menuName);
    if (link) {
      navigate(ROUTINGDATA.ADMINDASHBOARD + link);
    }
    if (isMobile) {
      setOpen(false);
    }
  };

  const handleItemClick = (itemName) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [itemName]: !prevExpandedItems[itemName],
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem("selectedMenu");
    localStorage.removeItem("adminEmail");
    navigate("/");
  };

  const menuItems = [
    { name: "Dashboard", icon: <DashboardIcon />, link: "/" },
    {
      name: "Vendors",
      icon: <WhereToVoteOutlinedIcon />,
      link: "/AdminVenuesPage",
    },
    {
      name: "Approval",
      icon: <CheckBoxOutlinedIcon />,
      subItems: [
        { name: "Venue", link: "/VenueApproval/Venue" },
        { name: "Photo & Video", link: "/VenueApproval/Photo" },
      ],
    },
    {
      name: "Logout",
      icon: <LogoutIcon />,
      action: () => setLogoutDialogOpen(true),
    },
  ];

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar sx={{ backgroundColor: "white" }} position="fixed" open={open}>
          <Toolbar sx={{ width: "100%" }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && !isMobile && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}
            >
              {selectedMenu}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant={isMobile ? "temporary" : "persistent"}
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
        >
          <DrawerHeader>
            <img
              src={Logo}
              alt="logo"
              style={{ marginRight: "20px" }}
              height="40px"
              width="150px"
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((item) => (
              <div key={item.name}>
                <ListItem disablePadding>
                  <ListItemButton
                    component="a"
                    onClick={() => {
                      if (item.action) {
                        item.action();
                      } else {
                        handleMenuClick(item.name, item.link);
                        if (item.subItems) {
                          handleItemClick(item.name);
                        }
                      }
                    }}
                    sx={{
                      ...(selectedMenu === item.name
                        ? {
                            backgroundColor: "#FFF7EA",
                            color: "#F5AA35",
                            borderLeft: "4px solid #F5AA35",
                          }
                        : { color: "#180806", fontWeight: "bold" }),
                      "&:hover": {
                        backgroundColor: "#fff0d7",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          selectedMenu === item.name ? "#F5AA35" : "inherit",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ fontWeight: "bold" }}
                    />
                    {item.subItems ? (
                      expandedItems[item.name] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null}
                  </ListItemButton>
                </ListItem>
                {item.subItems && (
                  <Collapse
                    in={expandedItems[item.name]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) => (
                        <ListItem key={subItem.name} disablePadding>
                          <ListItemButton
                            component="a"
                            onClick={() => {
                              handleMenuClick(subItem.name, subItem.link);
                            }}
                            sx={{
                              ...(selectedMenu === subItem.name
                                ? {
                                    backgroundColor: "#FFF7EA",
                                    color: "#F5AA35",
                                    borderLeft: "4px solid #F5AA35",
                                  }
                                : {
                                    color: "#180806",
                                    fontWeight: "bold",
                                  }),
                              "&:hover": {
                                backgroundColor: "#fff0d7",
                              },
                            }}
                          >
                            <ListItemText
                              primary={subItem.name}
                              primaryTypographyProps={{
                                fontWeight: "bold",
                                ml: 7.3,
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </div>
            ))}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>

      <Dialog
        TransitionComponent={Transition}
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#f09200"
          sx={{ backgroundColor: "#f09200", fontWeight: 600 }}
        >
          Logout
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            sx={{
              color: "#f09200",
              borderColor: "#f09200",
              "&:hover": {
                color: "#f09200",
                borderColor: "#f09200",
              },
            }}
            onClick={() => setLogoutDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "#f09200",
              borderColor: "#f09200",
              "&:hover": {
                color: "#f09200",
                borderColor: "#f09200",
              },
            }}
            onClick={() => {
              setLogoutDialogOpen(false);
              handleLogout();
            }}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
