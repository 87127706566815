import React, { useState } from "react";
import Button from "@mui/material/Button";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Contact.css";
import useAxios from "../../useAxios";

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
const ConForms = () => {
  const [open, setopen] = useState();
  const [focusEmail, setFocusEmail] = useState(false);
  const [focusNo, setFocusNo] = useState(false);
  const axiosData = useAxios();
  const formik = useFormik({
    initialValues: {
      mobile_number: "",
      full_name: "",
      email_id: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string()
        .max(50, "Must_be_50_characters_or_less")
        .required("Name is required"),
      mobile_number: Yup.number()
        .min(1000000000, "Not_Valid_Mobile_Number!")
        .max(9999999999, "Not_Valid_Mobile_Number!")
        .required("Phone number is required"),
      email_id: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Invalid_email_format"
        )
        .required("Email is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values) => {
      await axiosData.post("/contactUs", values);
    },
  });

  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="herotextimg-1">
        <div className="maintextforhero-1">
          <div className="Contactuspage-center">
            <h2>{"Contact Us"}</h2>
          </div>
        </div>
      </div>
      {/* <div className="contactusbackpage">
        <div className="contactusbackpagefonts">
          <span className="backbtn">{"Home"}</span>/{" "}
          <span style={{ color: "black" }}>{"Contact Us"}</span>
        </div>
      </div> */}

      <div className="contactpage-map">
        <iframe
          title="Map of our location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6143837830923!2d80.19310057398044!3d13.060199912931836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266b6e31bec61%3A0x3279e4cbf4f17af3!2sUS%20Global%20Solutions!5e0!3m2!1sen!2sin!4v1688541507709!5m2!1sen!2sin"
          width="80%"
          height="350"
          style={{ border: 0, marginLeft: "100px" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div
        className="contactusform"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          padding: "50px 30px",
        }}
      >
        <div
          className="contactusformdetails"
          style={{
            borderRadius: "10px",
            boxShadow: "#f7ab1f",
            display: "grid",
            gridTemplateColumns: "40% 60%",
            width: "80%",
          }}
        >
          <div
          // className="detailscontact"
          // style={{
          //   backgroundColor: "#f7ab1f",
          //   borderRadius: "10px 0 0 10px",
          //   padding: "15px",
          // }}
          >
            <div
              className="contacticonbox"
              style={{
                alignItems: "center",
                backgroundColor: "#ffbf5a",
                borderRadius: "10px",
                boxShadow: "#bd7d1a",
                color: "#3b2400",
                display: "flex",
                gap: "10px",
                margin: "20px 15px",
                padding: "10px",
              }}
            >
              <div>
                <LocationOnIcon
                  sx={{
                    fontSize: "25px",
                    border: "1px solid #3b2400",
                    padding: "7px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div>
                <h4>{"Location"}:</h4>
                <p>
                  {
                    "#33/13, Second floor, Kaliamman Koil Street, SBI/Canara bank building, Chinmaya Nagar, Chennai, Tamil Nadu 600092"
                  }
                </p>
              </div>
            </div>
            <div
              className="contacticonbox"
              style={{
                alignItems: "center",
                backgroundColor: "#ffbf5a",
                borderRadius: "10px",
                boxShadow: "#bd7d1a",
                color: "#3b2400",
                display: "flex",
                gap: "10px",
                margin: "20px 15px",
                padding: "10px",
              }}
            >
              <div>
                <EmailIcon
                  sx={{
                    fontSize: "25px",
                    border: "1px solid #3b2400",
                    padding: "7px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div>
                <h4>{"Email"}:</h4>
                <p>contactus@usglobalsolutions.com</p>
              </div>
            </div>
            <div
              className="contacticonbox"
              style={{
                alignItems: "center",
                backgroundColor: "#ffbf5a",
                borderRadius: "10px",
                boxShadow: "#bd7d1a",
                color: "#3b2400",
                display: "flex",
                gap: "10px",
                margin: "20px 15px",
                padding: "10px",
              }}
            >
              <div>
                <SmartphoneIcon
                  sx={{
                    fontSize: "25px",
                    border: "1px solid #3b2400",
                    padding: "7px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div>
                <h4>{"Call"}:</h4>
                <p>+91 9080400330</p>
              </div>
            </div>
            <div
              className="contacticonbox"
              style={{
                alignItems: "center",
                backgroundColor: "#ffbf5a",
                borderRadius: "10px",
                boxShadow: "#bd7d1a",
                color: "#3b2400",
                display: "flex",
                gap: "10px",
                margin: "20px 15px",
                padding: "10px",
              }}
            >
              <div>
                <AccessTimeIcon
                  sx={{
                    fontSize: "25px",
                    border: "1px solid #3b2400",
                    padding: "7px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div>
                <h4>{"Open hours"}:</h4>
                <p>{"Mon-Sat: 9.30AM - 6.30PM"}</p>
              </div>
            </div>
          </div>
          <div
            className="inputsforcontactdetails"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              padding: "15px",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <div className="centerpace">{/* Your form fields */}</div>
              <div
                className={
                  open ? "backsideofcenterpace" : "backsideofcenterpace-1"
                }
              ></div>
              <div className="centerpace">
                <div
                  className="spacet0p"
                  style={{ display: "flex", gap: "10px", paddingTop: "40px" }}
                >
                  {" "}
                  <TextField
                    id="outlined-basic"
                    label={"Your Name"}
                    variant="outlined"
                    name="full_name"
                    error={formik.touched.full_name && formik.errors.full_name}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (e) {
                        const name = e.target.value.replace(/[^a-z'. ]/gi, "");
                        formik.setFieldValue("full_name", name);
                      }
                    }}
                    value={formik.values.full_name}
                    helperText={
                      formik.touched.full_name && formik.errors.full_name
                        ? formik.errors.full_name
                        : null
                    }
                    fullWidth
                  />
                  <TextField
                    id="filled basic"
                    label={"Email"}
                    variant="outlined"
                    error={
                      formik.touched.email_id &&
                      formik.errors.email_id &&
                      focusEmail
                    }
                    name="email_id"
                    onBlur={formik.handleBlur}
                    onBlurCapture={() => {
                      setFocusEmail(true);
                    }}
                    onChange={(e) => {
                      setFocusEmail(false);
                      const value = e.target.value.replace(/[^a-z0-9@.]/gi, "");
                      formik.setFieldValue("email_id", value);
                    }}
                    value={formik.values.email_id}
                    helperText={
                      formik.touched.email_id &&
                      formik.errors.email_id &&
                      focusEmail
                        ? formik.errors.email_id
                        : null
                    }
                    fullWidth
                  />
                </div>
                <div className="spaced-1-1" style={{ paddingTop: "25px" }}>
                  <TextField
                    id="filled-basic"
                    label={"Phone number"}
                    variant="outlined"
                    type="number"
                    error={
                      formik.touched.mobile_number &&
                      formik.errors.mobile_number &&
                      focusNo
                    }
                    name="mobile_number"
                    onWheel={(e) => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    onBlurCapture={() => {
                      setFocusNo(true);
                    }}
                    onBlur={formik.handleBlur}
                    onChange={handleChange}
                    value={formik.values.mobile_number}
                    helperText={
                      formik.touched.mobile_number &&
                      formik.errors.mobile_number &&
                      focusNo
                        ? formik.errors.mobile_number
                        : null
                    }
                    fullWidth
                  />
                </div>
                <div
                  className="space-1"
                  style={{ padding: "25px 0", textAlign: "center" }}
                >
                  <TextField
                    id="filled-basic"
                    label={"Subject"}
                    variant="outlined"
                    error={formik.touched.subject && formik.errors.subject}
                    name="subject"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                    helperText={
                      formik.touched.subject && formik.errors.subject
                        ? formik.errors.subject
                        : null
                    }
                    style={{
                      color:
                        formik.touched.subject && formik.errors.subject
                          ? "red"
                          : "inherit",
                    }}
                    fullWidth
                  />
                </div>
                <div className="space-2">
                  <TextField
                    id="outlined-multiline-static"
                    label={"Message"}
                    multiline
                    error={formik.touched.message && formik.errors.message}
                    name="message"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    fullWidth
                    helperText={
                      formik.touched.message && formik.errors.message
                        ? formik.errors.message
                        : null
                    }
                    style={{
                      color:
                        formik.touched.message && formik.errors.message
                          ? "red"
                          : "inherit",
                    }}
                    rows={5}
                  />
                </div>
                <div
                  className="space-3"
                  style={{ padding: "30px 0", textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={formik.isValid && formik.dirty ? false : true}
                  >
                    {"Send Message"}
                  </Button>
                </div>
                <div
                  className={
                    open ? "backsideofcenterpace" : "backsideofcenterpace-1"
                  }
                ></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConForms;
