import React, { useState, useEffect } from "react";
import vendorSigninImg from "../../img/vendorSigninImg.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  TextField,
  Box,
  Grid,
  FormControl,
  Autocomplete,
  Button,
  InputAdornment,
  Typography,
  Tooltip,
  IconButton,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import useAxios from "../../useAxios";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import PersonIcon from "@mui/icons-material/Person";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const defaultTheme = createTheme();
// const [open setOpen] = useState (false);

export default function VendorSignin() {
  const [emailExists, setEmailExists] = useState(false);
  const navigate = useNavigate();

  const [showPassword, setOldShowPassword] = useState(false);

  const [vendorType, setVendorType] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const toggleOldPasswordVisibility = () => {
    setOldShowPassword((show) => !show);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Vendor name is required"),

    vendorType: Yup.string().required("Vendor type is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format"
      )
      .max(255, "Email must be at most 255 characters"),
    phoneNumber: Yup.string().required("Phone number is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      usertype: "vendor",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log(values);
    },
  });

  const dataAxios = useAxios();

  const submit = useLocation();

  const Continues = async () => {
    try {
      const datas = await dataAxios.post("vendors/registration", {
        name: formik.values.name,

        email: formik.values.email,
        mobilenumber: formik.values.phoneNumber,
        password: formik.values.password,
        usertype: "vendor",
        countrycode: "+91",
      });
      if (datas) {
        setSuccessMsg(true);
      }
    } catch (err) {
      console.error("Error occurred while posting form data:", err);
    }
  };

  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const handleChange1 = (e) => {
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  const handleChange2 = (e) => {
    const { value } = e.target;
  };

  useEffect(() => {
    if (formik.values.email) {
      checkEmailExists();
    }
  }, [formik.values.email]);

  const checkEmailExists = async () => {
    try {
      const response = await dataAxios.get(
        `/vendors/emailExist/${formik.values.email}`
      );
      setEmailExists(response.data);
    } catch (error) {
      console.error("Error checking email existence:", error);
    }
  };
  const handleClose = () => {
    setSuccessMsg(false);
    navigate(ROUTINGDATA.VENDORSIGNIN);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container className="mainGridSignIn">
        <CssBaseline />
        <Grid item xs={false} sm={0} md={4.5}>
          <img
            src={vendorSigninImg}
            alt="vendorSigninImg"
            height="600px"
            width="100%"
            className="signinImage"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7.5}
          sx={{
            border: "1px solid orange",
            height: "600px",
          }}
        >
          <Box
            className="signInbox"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <p
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                color: "#3B2400",
                fontFamily: "Poppins-Medium",
                marginBottom: 0,
              }}
              className="sigInHeader"
            >
              Grow your business with WedMySoul
            </p>

            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
                width: "100%",
              }}
              onSubmit={formik.handleSubmit}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <FormControl
                  fullWidth
                  error={formik.touched.name && Boolean(formik.errors.name)}
                >
                  <TextField
                    variant="standard"
                    label="Name"
                    {...formik.getFieldProps("name")}
                  />
                  <FormHelperText>
                    {formik.touched.name && formik.errors.name ? (
                      <Typography
                        sx={{ color: "red", fontSize: "13px", ml: "-13px" }}
                      >
                        {formik.errors.name}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "13px",
                          visibility: "hidden",
                        }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  fullWidth
                  error={formik.touched.email && Boolean(formik.errors.email)}
                >
                  <TextField
                    variant="standard"
                    label="Email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    {...formik.getFieldProps("email")}
                  />
                  <FormHelperText>
                    {formik.touched.email && formik.errors.email ? (
                      <Typography
                        sx={{ color: "red", fontSize: "13px", ml: "-13px" }}
                      >
                        {formik.errors.email}
                      </Typography>
                    ) : emailExists ? (
                      <Typography
                        sx={{ color: "red", fontSize: "13px", ml: "-13px" }}
                      >
                        User already exists
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "13px",
                          visibility: "hidden",
                        }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  sx={{ mt: 2 }}
                  fullWidth
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      width: "100%",
                    }}
                  >
                    {/* <div
                    style={{
                      borderBottom: "1px solid gray",
                      marginRight: "5px",
                    }}
                  >
                    <img
                      width="25"
                      height="25"
                      src="https://img.icons8.com/color/48/india.png"
                      alt="india"
                    />
                  </div> */}
                    <div style={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Enter number"
                        {...formik.getFieldProps("phoneNumber")}
                        onChange={handleChange1}
                        InputProps={{
                          style: { color: "#867760" },
                          startAdornment: (
                            <InputAdornment position="start">
                              +91
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText>
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <Typography
                            sx={{ color: "red", fontSize: "13px", ml: "-13px" }}
                          >
                            {formik.errors.phoneNumber}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "13px",
                              visibility: "hidden",
                            }}
                          >
                            none
                          </Typography>
                        )}
                      </FormHelperText>
                    </div>
                  </Box>
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    {...formik.getFieldProps("password")}
                    InputProps={{
                      style: { color: "#867760" },
                      startAdornment: (
                        <InputAdornment position="start">
                          <PasswordIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleOldPasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText>
                    {formik.touched.password && formik.errors.password ? (
                      <Typography
                        sx={{ color: "red", fontSize: "13px", ml: "-13px" }}
                      >
                        {formik.errors.password}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "red",
                          fontSize: "13px",
                          visibility: "hidden",
                        }}
                      >
                        none
                      </Typography>
                    )}
                  </FormHelperText>
                </FormControl>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={() => {
                    Continues();
                  }}
                  sx={{
                    color: "black",
                    backgroundColor: "#F5AA35",
                    borderColor: "#F5AA35",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    width: "200px",
                    borderRadius: "4px",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#F5AA35",
                      borderColor: "#F5AA35",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      width: "200px",
                      borderRadius: "4px",
                    },
                  }}
                >
                  Continue
                </Button>
                <p
                  style={{
                    margin: 0,
                    color: "#604720",
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                  }}
                >
                  Already have a account ?
                  <span
                    onClick={() => {
                      navigate(ROUTINGDATA.VENDORSIGNIN);
                    }}
                    style={{ margin: 0, color: "#F5AA35", cursor: "pointer" }}
                  >
                    {" "}
                    sign in
                  </span>
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                {/* <Button
                  className="signInCustomerButtons"
                  sx={{
                    color: "#3B2400",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    borderRadius: 0,
                    "&:hover": {
                      color: "#3B2400",
                      borderColor: "#A38D6B",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      borderRadius: 0,
                    },
                  }}
                >
                  Are you a customer?
                </Button> */}
                {/* <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(ROUTINGDATA.SIGNIN);
                  }}
                  className="signInCustomerButtons"
                  sx={{
                    color: "black",
                    backgroundColor: "#F5AA35",
                    borderColor: "#F5AA35",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    borderRadius: "4px",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#F5AA35",
                      borderColor: "#F5AA35",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      borderRadius: "4px",
                    },
                  }}
                >
                  Customer sign in
                </Button> */}
              </div>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        TransitionComponent={Transition}
        open={successMsg}
        // onClose={() => setNoResultPopUp(false)}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#f09200"
          sx={{ backgroundColor: "#f09200", fontWeight: 600 }}
        >
          Successfully !
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            Thank you for registering. Please wait for admin approval.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "#f09200",
              borderColor: "#f09200",
              "&:hover": {
                color: "#f09200",
                borderColor: "#f09200",
              },
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
