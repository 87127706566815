import React, { useState } from "react";
import vendorSigninImg from "../../img/vendorSigninImg.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Box,
  Grid,
  FormControl,
  FormLabel,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import useAxios from "../../useAxios";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";

const defaultTheme = createTheme();
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function VendorSignup() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const axiosData = useAxios();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const logindata = await axiosData.post("userslogin/login", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (logindata.data === "") {
        setPending(true);
        setError("User does not exist");
      } else if (logindata.data.status === "P") {
        setPending(true);
        setError("Your profile is under review for Approval");
      } else if (logindata.data.status === "A") {
        if (logindata.data.usertype === "vendor") {
          const getVendor = await axiosData.get(
            `vendors/email/${logindata.data.email}`
          );
          const subscriptionStatus = await axiosData.get(
            `vendors/check-status/${logindata.data.email}`
          );
          if (!subscriptionStatus.data.isSubscribed) {
            getVendor.data.isSubscribed = false;
          }
          if (getVendor.data.isSubscribed === false) {
            navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.SUBSCRIPTIONPLAN}`, {
              state: { data: getVendor.data },
            });
          } else if (getVendor.data.isSubscribed === true) {
            navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.CHARACTER}`);
          }
          localStorage.setItem("userEmail", logindata.data.email);
        } else if (logindata.data.usertype === "admin") {
          navigate(ROUTINGDATA.ADMINDASHBOARD);
          localStorage.setItem("adminEmail", logindata.data.email);
        }
      }
    } catch (e) {
      if (e.message === "Network Error") {
        setPending(true);
        setError("Server is not acceptable please contact administration!");
      } else {
        setPending(true);
        setError(e.response.data.message);
      }
    }
  };
  const handleClose = () => {
    setPending(false);
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container className="mainGridSignIn">
        <CssBaseline />
        <Grid item xs={false} sm={0} md={4.5}>
          <img
            src={vendorSigninImg}
            alt="vendorSigninImg"
            height="500px"
            width="100%"
            className="signinImage"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7.5}
          sx={{
            border: "1px solid orange",
            height: "500px",
          }}
        >
          <Box className="signInbox">
            <p
              style={{
                fontWeight: "bold",
                color: "#3B2400",
                fontFamily: "Poppins-Medium",
                marginBottom: "60px",
              }}
              className="sigInHeader"
            >
              Grow your business with WedMySoul
            </p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email")
                  .required("Email is required"),
                password: Yup.string().required("Password is required"),
              })}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div style={{ marginBottom: "15px" }}>
                    <FormControl sx={{ width: "100%", mb: 4 }} fullWidth>
                      <FormLabel sx={{ fontWeight: "bold" }}></FormLabel>
                      <TextField
                        sx={{ gridTemplateColumns: "5% 5%" }}
                        name="email"
                        size="small"
                        placeholder="Email"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  </div>
                  <p
                    style={{
                      margin: 0,
                      marginTop: "5px",
                      color: "#604720",
                      textAlign: "left",
                      width: "100%",
                      fontSize: "13px",
                      fontFamily: "Poppins-Medium",
                    }}
                  ></p>
                  <TextField
                    name="password"
                    placeholder="Password"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PasswordIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    }
                    sx={{ marginBottom: "20px" }} // Add margin bottom here
                  />
                  <br></br>
                  <div style={{ textAlign: "right" }}>
                    <span
                      onClick={() => {
                        navigate(ROUTINGDATA.FORGOTPASSWORD);
                      }}
                      style={{ color: "#F5AA35", cursor: "pointer" }}
                    >
                      {" "}
                      Forgot Password?
                    </span>
                  </div>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="outlined"
                    sx={{
                      color: "black",
                      backgroundColor: "#F5AA35",
                      borderColor: "#F5AA35",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      width: "100%",
                      borderRadius: 0,
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#F5AA35",
                        borderColor: "#F5AA35",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        width: "100%",
                        borderRadius: 0,
                      },
                    }}
                  >
                    {isSubmitting ? "Submitting..." : "Continue"}
                  </Button>
                  <p
                    style={{
                      margin: "10px 0",
                      marginTop: "5px",
                      color: "#604720",
                      textAlign: "center",
                      width: "100%",
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      cursor: "pointer",
                    }}
                  >
                    Register as a vendor ?
                    <span
                      onClick={() => {
                        navigate(ROUTINGDATA.VENDORSIGNUP);
                      }}
                      style={{ margin: 0, color: "#F5AA35", cursor: "pointer" }}
                    >
                      {" "}
                      sign up
                    </span>
                  </p>
                </Form>
              )}
            </Formik>
            <div>
              <div>
                <div
                  style={{
                    color: "#3B2400",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    width: "100%",
                  }}
                >
                  {/* <Divider>OR</Divider> */}
                </div>
                {/* <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#3B2400",
                    fontFamily: "Poppins-Medium",
                    marginTop: "0",
                    textAlign: "center",
                  }}
                >
                  Continue With
                </p> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  {/* <Button
                    variant="outlined"
                    className="signInButtons"
                    sx={{
                      color: "#403421",
                      borderColor: "#A38D6B",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      borderRadius: 0,
                      py: 1,
                      "&:hover": {
                        color: "#403421",
                        borderColor: "#A38D6B",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        borderRadius: 0,
                        py: 1,
                      },
                    }}
                  >
                    <img
                      width="25"
                      height="25"
                      src="https://img.icons8.com/cute-clipart/25/facebook-new.png"
                      alt="facebook-new"
                      style={{ marginRight: "5px" }}
                    />
                    Facebook
                  </Button> */}
                  {/* <Button
                    variant="outlined"
                    className="signInButtons"
                    sx={{
                      color: "#403421",
                      borderColor: "#A38D6B",
                      fontFamily: "Poppins-Medium",
                      transition: "all 0.3s",
                      borderRadius: 0,
                      py: 1,
                      "&:hover": {
                        color: "#403421",
                        borderColor: "#A38D6B",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        borderRadius: 0,
                        py: 1,
                      },
                    }}
                  >
                    <img
                      width="25"
                      height="25"
                      src="https://img.icons8.com/color/25/google-logo.png"
                      alt="google-logo"
                      style={{ marginRight: "5px" }}
                    />
                    Google
                  </Button> */}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              {/* <Button
                className="signInCustomerButtons"
                sx={{
                  color: "#3B2400",
                  fontFamily: "Poppins-Medium",
                  transition: "all 0.3s",
                  borderRadius: 0,
                  "&:hover": {
                    color: "#3B2400",
                    borderColor: "#A38D6B",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    borderRadius: 0,
                  },
                }}
              >
                Are you a Customer?
              </Button>
              <Button
                variant="outlined"
                className="signInCustomerButtons"
                sx={{
                  color: "black",
                  backgroundColor: "#F5AA35",
                  borderColor: "#F5AA35",
                  fontFamily: "Poppins-Medium",
                  transition: "all 0.3s",
                  borderRadius: "4px",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#F5AA35",
                    borderColor: "#F5AA35",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    borderRadius: "4px",
                  },
                }}
                onClick={() => {
                  navigate(ROUTINGDATA.SIGNIN);
                }}
              >
                Customer Sign in
              </Button> */}
            </div>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        TransitionComponent={Transition}
        open={pending}
        onClose={() => setPending(false)}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#f09200"
          sx={{ backgroundColor: "#f09200", fontWeight: 600 }}
        >
          Alert !
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "#f09200",
              borderColor: "#f09200",
              "&:hover": {
                color: "#f09200",
                borderColor: "#f09200",
              },
            }}
            onClick={() => handleClose()}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
