import { createClient } from "@supabase/supabase-js";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_ANON_KEY;
export const supabase = createClient(
  "https://nhkguwovhcddhscsntuq.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5oa2d1d292aGNkZGhzY3NudHVxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkyOTY5MTAsImV4cCI6MjAzNDg3MjkxMH0.Mr0Lpa-BIa2dkkY3GM05s2rdSq3y7bfcetYCAtNwbuY"
);
const useSupabase = () => {
  return supabase;
};

export default useSupabase;
