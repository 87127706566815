import React, { useEffect, useState } from "react";
import useAxios from "../useAxios";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  Container,
  Box,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "./ROUTINGDATA";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SubscriptionPlan = () => {
  const axiosData = useAxios();
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const vendorDetails = location.state.data;

  const getButtonVariant = (index) => {
    if (index === 1) {
      return "contained";
    } else {
      return "outlined";
    }
  };

  const fetchPlan = async () => {
    try {
      const getOneAxios = await axiosData.get(
        `subscription/initialSubscription`
      );
      const data = getOneAxios?.data;
      if (data) {
        setPlans(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handlePayment = (plan) => {
    const monthsToAdd = parseInt(plan.duration.split(" ")[0]);
    const today = new Date();
    const expiryDate = new Date(today);
    expiryDate.setMonth(expiryDate.getMonth() + monthsToAdd);
    const formatISO8601 = (date) => {
      const pad = (num) => String(num).padStart(2, "0");
      const year = date.getFullYear();
      const month = pad(date.getMonth() + 1); // Months are zero-indexed
      const day = pad(date.getDate());
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());
      const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
      const timezoneOffset = -date.getTimezoneOffset();
      const sign = timezoneOffset >= 0 ? "+" : "-";
      const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
      const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`;
    };
    const formattedExpiryDate = formatISO8601(expiryDate);
    const priceDetail = Math.round(plan.price * (1 - plan.discount / 100));
    try {
      const invoiceId = uuidv4();
      var options = {
        key: "rzp_live_iKpiRjaZRDRPJ4",
        key_secret: "GN2GAiObT9O0RoktLI641TBC",
        amount: priceDetail * 100,
        currency: "INR",
        name: "WedMySoul",
        description: `${plan.planName} plan`,
        prefill: {
          name: vendorDetails.name,
          email: vendorDetails.email,
          mobilenumber: vendorDetails.mobilenumber,
        },
        handler: async function (response) {
          const paymentId = response.razorpay_payment_id;
          try {
            await axiosData.put(`vendors/email/${vendorDetails.email}`, {
              subscribe_type: plan.planName,
              subscribe_amount: priceDetail,
              isSubscribed: true,
              expiryDate: formattedExpiryDate,
              subscribeDate: today,
            });
            await axiosData.post("subscribeDetail", {
              email: vendorDetails.email,
              subscriptionType: plan.planName,
              expiryDate: formattedExpiryDate,
              actualRate: plan.price,
              discountRate: priceDetail,
              discount: plan.discount,
              paymentId: paymentId,
            });
            setOpen(true);
          } catch (err) {
            console.log(err);
          }
        },
        theme: {
          color: "#ffbf5a",
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error("Error inserting data:", error.message);
    }
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.CHARACTER}`);
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "9rem",
          background:
            "linear-gradient(to bottom, #4232FF 0%, #BD00FF 50%, #ffffff 50%)",
          "@media (max-width: 960px)": {
            background:
              "linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #ffffff 50%)",
          },
        }}
      >
        <Container>
          <Grid
            container
            spacing={3}
            sx={{
              px: 14,
              "@media (max-width: 960px)": {
                px: 1,
              },
            }}
          >
            {plans.map((plan, index) => (
              <Grid
                sx={{ display: "flex", justifyContent: "center", mt: 4 }}
                item
                xs={12}
                sm={6}
                md={4}
                key={plan._id}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderColor: plan.color,
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderRadius: "15px",
                    py: 2,
                    width: "300px",
                    height: index === 1 ? "100%" : "auto",
                    boxShadow:
                      index === 1
                        ? "0px -1px 10px rgba(255, 152, 0, 0.8)"
                        : "none",
                    transform: index === 1 ? "translateY(-20px)" : "none",
                    "@media (max-width: 960px)": {
                      height: "auto",
                      boxShadow: "none",
                      transform: "none",
                    },
                    "&:hover": {
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
                      transform:
                        index === 1 ? "translateY(-25px)" : "translateY(-10px)",
                    },
                  }}
                >
                  <CardContent>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          backgroundColor: plan.color,
                          py: 1,
                          my: 0.7,
                          color: "white",
                        }}
                        variant="h5"
                        component="div"
                      >
                        {plan.planName}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ color: "#EF510A" }}
                      >
                        <del>₹{plan.price}</del>
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        <span
                          style={{
                            color: "black",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          ₹{Math.round(plan.price * (1 - plan.discount / 100))}
                        </span>
                        ({plan.duration})
                      </Typography>
                    </div>
                    <ul>
                      {plan.features.map((feature, idx) => (
                        <Typography
                          component="li"
                          sx={{
                            color: "#434343",
                            fontWeight: 500,
                            "&::marker": {
                              color: plan.color,
                              fontSize: "1.5rem",
                            },
                          }}
                          key={idx}
                        >
                          {feature}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: -3,
                    }}
                  >
                    <Button
                      variant={getButtonVariant(index)}
                      sx={{
                        backgroundColor:
                          index === 1 ? plan.color : "transparent", // Set background color for contained button
                        color: index === 1 ? "white" : "#ff9800", // Set text color for contained button
                        borderColor: "#ff9800", // Set border color for outlined button
                        "&:hover": {
                          backgroundColor:
                            index === 1 ? plan.color : "transparent", // Set hover background color for contained button
                          color: index === 1 ? "white" : "#ff9800",
                          borderColor: "#ff9800", // Set hover text color for contained button
                        },
                      }}
                      onClick={() => {
                        handlePayment(plan);
                      }}
                    >
                      Buy now
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
      >
        <DialogTitle
          bgcolor="#f09200"
          sx={{ backgroundColor: "#f09200", fontWeight: 600 }}
        >
          Success
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText sx={{ fontWeight: 600 }}>
            Thanks for subscribe , your payment is successful
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            sx={{
              color: "#f09200",
              borderColor: "#f09200",
              "&:hover": {
                color: "#f09200",
                borderColor: "#f09200",
              },
            }}
            onClick={() => handleClose()}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubscriptionPlan;
