import React from "react";

const PurchasePolicy = () => {
  return (
    <div style={styles.container}>
      <h1>Purchase Policy</h1>

      <h2>Introduction</h2>
      <p>
        Welcome to WedMySoul, your premier platform for booking wedding venues.
        We aim to make your venue selection process as seamless and enjoyable as
        possible. This purchase policy outlines the terms and conditions for
        booking venues through our platform. By using WedMySoul, you agree to
        abide by these terms.
      </p>

      <h2>1. Booking Process</h2>
      <h3>1.1 Venue Selection</h3>
      <ul>
        <li>Browse through our extensive list of venues.</li>
        <li>
          Filter venues based on location, capacity, amenities, and price.
        </li>
        <li>
          View detailed descriptions, images, and customer reviews for each
          venue.
        </li>
      </ul>

      <h3>1.2 Availability Check</h3>
      <ul>
        <li>
          Check the availability of your chosen venue on your preferred dates.
        </li>
        <li>
          Enter your event details including the date and any special
          requirements.
        </li>
      </ul>

      <h3>1.3 Reservation</h3>
      <ul>
        <li>Reserve your selected venue by completing the booking form.</li>
        <li>
          Provide accurate and complete information to ensure a smooth booking
          process.
        </li>
      </ul>

      <h2>2. Payment Terms</h2>
      <h3>2.1 Pricing</h3>
      <ul>
        <li>The price for each venue is listed on the venue's detail page.</li>
        <li>
          Prices are subject to change without prior notice, but the price at
          the time of booking will be honored.
        </li>
      </ul>

      <h3>2.2 Payment Methods</h3>
      <ul>
        <li>
          Payments can be made via credit card, debit card, or other available
          online payment methods.
        </li>
        <li>
          All payments are processed securely through our payment gateway.
        </li>
      </ul>

      <h2>3. Changes to Booking</h2>
      <h3>3.1 Date Changes</h3>
      <ul>
        <li>
          Requests to change the event date are subject to venue availability.
        </li>
        <li>
          Date changes made more than 30 days before the event will incur no
          additional fee.
        </li>
        <li>
          Date changes made less than 30 days before the event may incur an
          additional fee.
        </li>
      </ul>

      <h3>3.2 Other Changes</h3>
      <ul>
        <li>
          Any other changes to the booking (e.g., number of guests, amenities)
          must be communicated as soon as possible.
        </li>
        <li>
          Changes are subject to venue approval and may incur additional costs.
        </li>
      </ul>

      <h2>4. Client Responsibilities</h2>
      <ul>
        <li>
          The client is responsible for ensuring that all information provided
          during the booking process is accurate and up to date.
        </li>
        <li>
          The client must adhere to the venue’s rules and regulations during the
          event.
        </li>
        <li>
          Any damages caused to the venue during the event will be the
          responsibility of the client.
        </li>
      </ul>

      <h2>5. Liability</h2>
      <ul>
        <li>
          WedMySoul is not liable for any personal injury, loss, or damage to
          personal property that occurs at the venue.
        </li>
        <li>
          WedMySoul is not responsible for the actions or omissions of the
          venue.
        </li>
      </ul>

      <h2>6. Contact Information</h2>
      <p>
        For any questions or concerns regarding your booking, please contact our
        customer service team:
      </p>
      <ul>
        <li>Email: wmsenquiry@usglobalsolutions.com</li>
        <li>Phone: +91 9080400330</li>
        <li>
          Address: #33/13, Second floor, Kaliamman Koil Street, SBI/Canara bank
          building, Chinmaya Nagar, Chennai, Tamil Nadu 600092
        </li>
      </ul>

      <h2>7. Amendments</h2>
      <p>
        WedMySoul reserves the right to amend this policy at any time. Any
        changes will be communicated to clients via email and updated on our
        website.
      </p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
};

export default PurchasePolicy;
