import React, { useEffect, useState } from "react";
import "./VendorOtp.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../ROUTINGDATA";
import PropTypes from "prop-types";
import useAxios from "../../useAxios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import { styled } from "@mui/system";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));
function VendorPassword({ setOpenModal, onCancel }) {
  const navigate = useNavigate();
  const dataAxios = useAxios();
  const location = useLocation();
  const [otps, setOtps] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [open, setOpen] = useState(false);
  const [openWrongOTP, setOpenWrongOTP] = useState(false);
  const [emptyOtp, setEmptyOtp] = useState(false);

  const handleCancel = async () => {
    try {
      await dataAxios.delete(`password/${location.state.email}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (event) => {
    setOtps(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEmptySubmit = () => {
    setEmptyOtp(false);
  };
  const handleEmptySubmitOpen = () => {
    setEmptyOtp(true);
  };
  const handleClickOpenWrongOTP = () => {
    setOpenWrongOTP(true);
  };
  const handleCloseWrongOTP = () => {
    setOtps("");
    setOpenWrongOTP(false);
  };
  const handleSubmit = async () => {
    try {
      const data = await dataAxios.get(`password/${location.state.email}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const otpValue = data.data.otp;
      if (otpValue === otps) {
        try {
          await dataAxios.delete(`password/${location.state.email}`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          navigate(`${ROUTINGDATA.VENDORCONFORM}`, {
            state: { email: location.state.email },
          });
        } catch (err) {
          console.log(err);
        }
      } else if (otps === "") {
        handleEmptySubmitOpen();
      } else if (otpValue === undefined || otpValue === null) {
        handleClickOpen();
      } else {
        handleClickOpenWrongOTP();
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const handleTabClose = async (event) => {
      await dataAxios.delete(`password/${location.state.email}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      event.preventDefault();
      event.returnValue = ""; // For older browsers
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  useEffect(() => {
    const interval = setInterval(async () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          try {
            await dataAxios.delete(`password/${location.state.email_id}`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (err) {
            console.log(err);
          }
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const resendOTP = async () => {
    setMinutes(1);
    setSeconds(30);
    const email_id = `${location.state.email}`;
    try {
      await dataAxios.delete(`password/${email_id}`);
      await dataAxios.post("password", { email: email_id });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="title">
          <h1>Email Verification</h1>
        </div>
        <div className="title">
          <p>The OTP is sent to your in your email.</p>
        </div>
        <div className="otp-inputs">
          <CustomTextField
            type="number"
            name="otps"
            value={otps}
            onChange={handleChange}
            label="One Time Password"
            variant="outlined"
          />
        </div>
        <FormHelperText sx={{ textAlign: "center", marginTop: "10px" }}>
          {/* {formik.touched.otp && formik.errors.otp ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.otp}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )} */}
          {seconds > 0 || minutes > 0 ? (
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: " Poppins-Regular",
              }}
            >
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: " Poppins-Regular",
              }}
            >
              "Didn't receive OTP?"
              <span
                onClick={resendOTP}
                style={{
                  color: "red",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              >
                Resend OTP
              </span>
            </Typography>
          )}
        </FormHelperText>

        <div className="footer1">
          <button
            type="button"
            className="btn btn-cancel"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            style={{ margin: 0, color: "black", cursor: "pointer" }}
          >
            Submit
          </button>
          <Dialog
            open={open}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 10px",
              },
            }}
          >
            <Box>
              <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
                <div
                  style={{ display: "flex", gap: "3px", alignItems: "center" }}
                >
                  <ErrorSharpIcon
                    sx={{ mt: -0.3, color: "crimson" }}
                    fontSize="large"
                  />
                  <Typography variant="h5" sx={{ ml: 0.3 }}>
                    User has entered expired OTP!
                  </Typography>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
                >
                  Please click Resend OTP and enter OTP again.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "lowercase",
                    color: "rgb(229, 160, 0);",
                    borderColor: "rgb(229, 160, 0);",
                    fontFamily: "Poppins-Medium",
                    "&:hover": {
                      textTransform: "lowercase",
                      color: "white",
                      backgroundColor: "rgb(229, 160, 0);",
                      borderColor: "rgb(229, 160, 0);",
                      fontFamily: "Poppins-Medium",
                    },
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
          <Dialog
            open={emptyOtp}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 10px",
              },
            }}
          >
            <Box>
              <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
                <div
                  style={{ display: "flex", gap: "3px", alignItems: "center" }}
                >
                  <ErrorSharpIcon
                    sx={{ mt: -0.3, color: "crimson" }}
                    fontSize="large"
                  />
                  <Typography variant="h5" sx={{ ml: 0.3 }}>
                    Not Entered!
                  </Typography>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
                >
                  Please enter the OTP and then click submit.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "lowercase",
                    color: "rgb(229, 160, 0);",
                    borderColor: "rgb(229, 160, 0);",
                    fontFamily: "Poppins-Medium",
                    "&:hover": {
                      textTransform: "lowercase",
                      color: "white",
                      backgroundColor: "rgb(229, 160, 0);",
                      borderColor: "rgb(229, 160, 0);",
                      fontFamily: "Poppins-Medium",
                    },
                  }}
                  onClick={() => {
                    handleEmptySubmit();
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
          <Dialog
            open={openWrongOTP}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px 10px",
              },
            }}
          >
            <Box>
              <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
                <div
                  style={{ display: "flex", gap: "3px", alignItems: "center" }}
                >
                  <ErrorSharpIcon
                    sx={{ mt: -0.3, color: "crimson" }}
                    fontSize="large"
                  />
                  <Typography variant="h5" sx={{ ml: 0.3 }}>
                    User has entered Wrong OTP!
                  </Typography>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
                >
                  Please enter correct OTP.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "lowercase",
                    color: "rgb(229, 160, 0);",
                    borderColor: "rgb(229, 160, 0);",
                    fontFamily: "Poppins-Medium",
                    "&:hover": {
                      textTransform: "lowercase",
                      color: "white",
                      backgroundColor: "rgb(229, 160, 0);",
                      borderColor: "rgb(229, 160, 0);",
                      fontFamily: "Poppins-Medium",
                    },
                  }}
                  onClick={() => {
                    handleCloseWrongOTP();
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

VendorPassword.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default VendorPassword;
