import React, { useEffect, useState } from "react";
import heroimg from "../../../img/photograph/image 38.png";
import {
  Autocomplete,
  Button,
  Container,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import "../../../components/services/ServicesPages.css";
import venue1 from "../../../img/photograph/Rectangle 87.png";
import venue2 from "../../../img/photograph/Rectangle 87 (1).png";
import venue3 from "../../../img/photograph/Rectangle 87 (2).png";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import toplistingvenue1 from "../../../img/photograph/Rectangle 87.png";
import toplistingvenue2 from "../../../img/photograph/Rectangle 87 (1).png";
import toplistingvenue3 from "../../../img/photograph/Rectangle 87 (2).png";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../useAxios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

// import './styles.css';
import { Navigation } from "swiper/modules";
import { ROUTINGDATA } from "../../ROUTINGDATA";

const VendorsPhotograph = () => {
  const placeholderStyle = {
    fontFamily: "Poppins-Medium",
    padding: " 5px 15px", // Change this to your desired font family
  };

  const navigate = useNavigate();
  const [topVenueDatas, setTopVenueDatas] = useState([]);
  const [bestVenueDatas, setBestVenueDatas] = useState([]);
  const [topListingImgs, setTopListingImgs] = useState([
    toplistingvenue1,
    toplistingvenue2,
    toplistingvenue3,
  ]);
  const [venueImages, setVenueImages] = useState([venue1, venue2, venue3]);
  const axiosData = useAxios();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  const fetchVenues = async () => {
    try {
      const getOneAxios = await axiosData.get(`venues/venues/topListing`);
      const data = getOneAxios?.data;
      if (data) {
        data.reverse();
        setTopVenueDatas(data);
        try {
          const getOneAxios2 = await axiosData.get(
            `venues/venues/bestVenues/15Venues`
          );
          const data2 = getOneAxios2?.data;
          if (data2) {
            data2.reverse();
            setBestVenueDatas(data2);
          }
          return data2;
        } catch (err) {
          console.log("Error", err);
        }
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    fetchVenues();
  }, []);
  return (
    <div>
      <div className="hero-img-block-2">
        <img src={heroimg} alt="heroimg" />
        <div className="overlay"></div>
        <div className="venuespage-search-content">
          <div className="search-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              //   defaultValue="Price per plate"
              //   options={top100Films}
              sx={{ "& fieldset": { border: "none" }, width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: placeholderStyle,
                  }}
                  placeholder="Price per plate"
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              //   defaultValue="Price per plate"
              //   options={top100Films}
              sx={{ "& fieldset": { border: "none" }, width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: placeholderStyle,
                  }}
                  placeholder="Space"
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              //   defaultValue="Price per plate"
              //   options={top100Films}
              sx={{ "& fieldset": { border: "none" }, width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: placeholderStyle,
                  }}
                  placeholder="Count"
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              //   defaultValue="Price per plate"
              //   options={top100Films}
              sx={{ "& fieldset": { border: "none" }, width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: placeholderStyle,
                  }}
                  placeholder="Rating"
                />
              )}
            />
          </div>
        </div>
      </div>
      {/* <div id="places-block" className="places-block">
        <div className="common-header-title">
          <h3>Top Destinations</h3>
        </div>

        <Swiper
          slidesPerView={6}
          spaceBetween={30}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 40,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide className="slide-box">
            <div className="venues-place-img-content">
              <div className="venues-place-img">
                <img src={venuesplace1} alt="" />
              </div>
              <div className="title-details">
                <h3>Amalfi Coast</h3>
                <h3>100 venues</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-place-img-content">
              <div className="venues-place-img">
                <img src={venuesplace2} alt="" />
              </div>
              <div className="title-details">
                <h3>London</h3>
                <h3>80 venues</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-place-img-content">
              <div className="venues-place-img">
                <img src={venuesplace3} alt="" />
              </div>
              <div className="title-details">
                <h3>Phuket</h3>
                <h3>70 venues</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-place-img-content">
              <div className="venues-place-img">
                <img src={venuesplace4} alt="" />
              </div>
              <div className="title-details">
                <h3>Rome</h3>
                <h3>60 venues</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-place-img-content">
              <div className="venues-place-img">
                <img src={venuesplace5} alt="" />
              </div>
              <div className="title-details">
                <h3>Paris</h3>
                <h3>50 venues</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-place-img-content">
              <div className="venues-place-img">
                <img src={venuesplace6} alt="" />
              </div>
              <div className="title-details">
                <h3>Venice</h3>
                <h3>90 venues</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-box">
            <div className="venues-place-img-content">
              <div className="venues-place-img">
                <img src={venuesplace1} alt="" />
              </div>
              <div className="title-details">
                <h3>Paris</h3>
                <h3>75 venues</h3>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div id="Listings-block" className="Listings-block">
        <div className="common-header-title">
          <h3>Top Listings</h3>
        </div>
        <Container>
          <div className="split-venues-box">
            {topVenueDatas &&
              topVenueDatas.map((data, index) => (
                <div
                  className="venues-box"
                  // onClick={() => {
                  //   navigate("/" + ROUTINGDATA.VENUESPAGEDETAILS, {
                  //     state: {
                  //       id: data._id,
                  //       getImage:
                  //         index === 3
                  //           ? topListingImgs[0]
                  //           : topListingImgs[index],
                  //     },
                  //   });
                  // }}
                >
                  <div className="img-venue">
                    <img
                      src={
                        index === 3 ? topListingImgs[0] : topListingImgs[index]
                      }
                      alt=""
                    />
                  </div>
                  <div className="venue-content">
                    <h3>{data.brand_name}</h3>
                    <div className="venue-para1">
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <StarRateRoundedIcon
                          sx={{
                            fontSize: 19,
                            color: "#F5AA35",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      <text>
                        4.5<span>(10)</span>
                      </text>{" "}
                      <span className="lastword-venue"> . {data.address}</span>
                    </div>
                    <div className="venue-para2">
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <Groups2RoundedIcon
                          sx={{
                            fontSize: 23,
                            color: "#3F3F3F",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      Capacity {data.capacity_persons} guest .
                    </div>
                    <div className="venue-para3">
                      From <span>${data.min_price_day}</span>
                      to<span>${data.max_price_day}</span>
                    </div>
                  </div>
                  <div className="bage-name-venues">
                    {" "}
                    {data.primary_venue_type.toLowerCase().includes("lawn")
                      ? "Lawn"
                      : data.primary_venue_type
                          .toLowerCase()
                          .includes("banquet")
                      ? "Banquet"
                      : data.primary_venue_type
                          .toLowerCase()
                          .includes("resort") ||
                        data.primary_venue_type
                          .toLowerCase()
                          .includes("restaurant")
                      ? "Resort"
                      : "Party Hall"}
                  </div>
                </div>
              ))}
            {/* <div className="venues-box" onClick={()=>{
             navigate("/"+ROUTINGDATA.VENUESPAGEDETAILS)
            }}>
              <div className="img-venue">
                <img src={toplistingvenue1} alt="" />
              </div>
              <div className="venue-content">
                <h3>Place name</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div> */}
            {/* <div className="venues-box">
              <div className="img-venue">
                <img src={toplistingvenue2} alt="" />
              </div>
              <div className="venue-content">
                <h3>Place name</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={toplistingvenue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>Place name</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location </span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text>+2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
      <div id="Best-Venues-block" className="Best-Venues-block">
        <div className="common-header-title">
          <h3>Best photographers</h3>
        </div>
        <Container>
          <div className="split-venues-box">
            {bestVenueDatas &&
              bestVenueDatas.map((data, index) => (
                <div
                  className="venues-box"
                  // onClick={() => {
                  //   navigate("/" + ROUTINGDATA.VENUESPAGEDETAILS, {
                  //     state: {
                  //       id: data._id,
                  //       getImage:
                  //         index === 3 ? venueImages[0] : venueImages[index],
                  //     },
                  //   });
                  // }}
                >
                  <div className="img-venue">
                    <img
                      src={index === 3 ? venueImages[0] : venueImages[index]}
                      alt=""
                    />
                  </div>
                  <div className="venue-content">
                    <h3>{data.brand_name}</h3>
                    <div className="venue-para1">
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <StarRateRoundedIcon
                          sx={{
                            fontSize: 19,
                            color: "#F5AA35",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      <text>
                        4.5<span>(10)</span>
                      </text>{" "}
                      <span className="lastword-venue"> . {data.address}</span>
                    </div>
                    <div className="venue-para2">
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <Groups2RoundedIcon
                          sx={{
                            fontSize: 23,
                            color: "#3F3F3F",
                            marginRight: "5px",
                          }}
                        />
                      </div>
                      Capacity {data.capacity_persons} guest .
                    </div>
                    <div className="venue-para3">
                      From <span>${data.min_price_day}</span>
                      to<span>${data.max_price_day}</span>
                    </div>
                  </div>
                  <div className="bage-name-venues">
                    <text>
                      {" "}
                      {data.primary_venue_type.toLowerCase().includes("lawn")
                        ? "Lawn"
                        : data.primary_venue_type
                            .toLowerCase()
                            .includes("banquet")
                        ? "Banquet"
                        : data.primary_venue_type
                            .toLowerCase()
                            .includes("resort") ||
                          data.primary_venue_type
                            .toLowerCase()
                            .includes("restaurant")
                        ? "Resort"
                        : "Party Hall"}
                    </text>
                  </div>
                </div>
              ))}
            {/* <div className="venues-box">
              <div className="img-venue">
                <img src={venue1} alt="" />
              </div>
              <div className="venue-content">
                <h3>BlissfulBarns</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div> */}
            {/* <div className="venues-box">
              <div className="img-venue">
                <img src={venue2} alt="" />
              </div>
              <div className="venue-content">
                <h3>HarmonyHaven</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={venue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>EnchantingEstate</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text>+2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div> */}
            {/* <div className="venues-box">
              <div className="img-venue">
                <img src={bestvenue1} alt="" />
              </div>
              <div className="venue-content">
                <h3>BlissfulBarns</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={bestvenue2} alt="" />
              </div>
              <div className="venue-content">
                <h3>HarmonyHaven</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={bestvenue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>EnchantingEstate</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text>+2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={bestvenue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>BlissfulBarns</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={bestvenue2} alt="" />
              </div>
              <div className="venue-content">
                <h3>HarmonyHaven</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={bestvenue1} alt="" />
              </div>
              <div className="venue-content">
                <h3>EnchantingEstate</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text>+2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={venue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>BlissfulBarns</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={venue2} alt="" />
              </div>
              <div className="venue-content">
                <h3>HarmonyHaven</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={venue1} alt="" />
              </div>
              <div className="venue-content">
                <h3>EnchantingEstate</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text>+2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={venue1} alt="" />
              </div>
              <div className="venue-content">
                <h3>BlissfulBarns</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={bestvenue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>HarmonyHaven</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text> +2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div>
            <div className="venues-box">
              <div className="img-venue">
                <img src={venue3} alt="" />
              </div>
              <div className="venue-content">
                <h3>EnchantingEstate</h3>
                <div className="venue-para1">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <StarRateRoundedIcon
                      sx={{
                        fontSize: 19,
                        color: "#F5AA35",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <text>
                    4.5<span>(10)</span>
                  </text>{" "}
                  <span className="lastword-venue"> . Area, location</span>
                </div>
                <div className="venue-para2">
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Groups2RoundedIcon
                      sx={{
                        fontSize: 23,
                        color: "#3F3F3F",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  300 to 500 guest <text>+2more</text>
                </div>
                <div className="venue-para3">
                  From <span>$1000</span>
                  to<span>$3000</span>
                </div>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
      <div className="pagination-part">
        <Pagination
          count={10}
          sx={{
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "#F5AA35",
              color: "#fff",
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#F5AA35",
              color: "#fff",
            },
            "& .MuiPaginationItem-root": {
              color: "#3B2400",
            },
          }}
        />
      </div>
    </div>
  );
};

export default VendorsPhotograph;
