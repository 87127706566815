import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import useAxios from "../../useAxios";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const AdminVenuesPage = () => {
  const [vendors, setVendors] = useState([]);
  const [oneVendor, setOneVendor] = useState("");
  const [open, setOpen] = useState(false);
  const [reasonDec, setReasonDec] = useState(false);
  const [reason, setReason] = useState("");

  const axiosData = useAxios();
  const isMobile = useMediaQuery("(max-width:600px)");

  const fetchSearch = async () => {
    try {
      const getOneAxios = await axiosData.get(`vendors`);
      const data = getOneAxios?.data;

      if (data) {
        setVendors(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    fetchSearch();
  }, []);

  const handleFullView = async (email) => {
    const getOneVendor = await axiosData.get(`vendors/email/${email}`);
    setOneVendor(getOneVendor.data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDecline = async (email) => {
    await axiosData.put(`userslogin/${email}`, {
      status: "D",
    });
    await axiosData.put(`vendors/${email}`, {
      status: "D",
      reason: reason,
      name: oneVendor.name,
    });
    setReasonDec(false);
    setReason("");
    setOpen(false);
    fetchSearch();
  };

  const handleApprove = async (email) => {
    await axiosData.put(`userslogin/${email}`, {
      status: "A",
    });
    await axiosData.put(`vendors/${email}`, {
      status: "A",
      name: oneVendor.name,
      password: oneVendor.password,
    });
    setOpen(false);
    fetchSearch();
  };

  return (
    <>
      {isMobile ? (
        <Box>
          {vendors.map((vendor) => (
            <Card
              key={vendor.id}
              sx={{ mb: 2, borderLeft: "5px solid #f5aa35" }}
            >
              <CardContent>
                <Typography variant="h6">
                  <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                  {vendor.name}
                </Typography>
                <Typography sx={{ color: "#70614B" }}>
                  <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                  {vendor.email}
                </Typography>
                <Typography sx={{ color: "#70614B" }}>
                  <span style={{ fontWeight: "bold" }}> Mobile number:</span>{" "}
                  {vendor.mobilenumber}
                </Typography>
                {vendor.subscribe_type ? (
                  <Typography sx={{ color: "#70614B" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Subscription type:
                    </span>{" "}
                    {vendor.subscribe_type}
                  </Typography>
                ) : null}
                <Typography sx={{ color: "#70614B" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Subscription amount:
                  </span>{" "}
                  {vendor.subscribe_amount}
                </Typography>
                <Typography sx={{ color: "#70614B" }}>
                  <span style={{ fontWeight: "bold" }}> Status:</span>{" "}
                  {vendor.status}
                </Typography>
                <Button onClick={() => handleFullView(vendor.email)}>
                  <OpenInNewIcon />
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Mobile number
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Subscription type
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Subscription amount
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #BCB5AA",
                    fontWeight: "bold",
                    color: "#5F3F3B",
                    fontSize: "17px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendors.map((vendor) => (
                <TableRow key={vendor.id}>
                  <TableCell>{vendor.name}</TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {vendor.email}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {vendor.mobilenumber}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {vendor.subscribe_type}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {vendor.subscribe_amount}
                  </TableCell>
                  <TableCell sx={{ color: "#70614B" }}>
                    {vendor.status}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => handleFullView(vendor.email)}>
                      <OpenInNewIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
            width: "500px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <Typography
                variant="h5"
                sx={{ ml: 0.3, color: "rgb(229, 160, 0);" }}
              >
                Confirm Approval
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            {oneVendor ? (
              <DialogContentText id="dialog-description" component="div">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Name:</strong>
                      </td>
                      <td>{oneVendor.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Email:</strong>
                      </td>
                      <td>{oneVendor.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mobile Number:</strong>
                      </td>
                      <td>{oneVendor.mobilenumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Subscribe Type:</strong>
                      </td>
                      <td>{oneVendor.subscribe_type}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Subscribe Amount:</strong>
                      </td>
                      <td>{oneVendor.subscribe_amount}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Status:</strong>
                      </td>
                      <td>{oneVendor.status}</td>
                    </tr>
                  </tbody>
                </table>
              </DialogContentText>
            ) : (
              <DialogContentText id="dialog-description">
                Loading vendor details...
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0);",
                borderColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => setReasonDec(true)}
              disabled={oneVendor.status === "D"}
            >
              Decline
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                borderColor: "rgb(229, 160, 0);",
                backgroundColor: "rgb(229, 160, 0);",
                fontFamily: "Poppins-Medium",
                color: "white",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0);",
                  borderColor: "rgb(229, 160, 0);",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => handleApprove(oneVendor.email)}
              disabled={oneVendor.status === "A"}
            >
              Approve
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={reasonDec}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px 10px",
            width: "500px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                Reason
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Give the reason of the decline :
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              label="Reason for Decline"
              type="text"
              fullWidth
              variant="outlined"
              sx={{ mt: 2, fontFamily: "Poppins-Medium" }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "rgb(229, 160, 0)",
                borderColor: "rgb(229, 160, 0)",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "rgb(229, 160, 0)",
                  borderColor: "rgb(229, 160, 0)",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                setReasonDec(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                borderColor: "rgb(229, 160, 0)",
                backgroundColor: "rgb(229, 160, 0)",
                fontFamily: "Poppins-Medium",
                color: "white",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "rgb(229, 160, 0)",
                  borderColor: "rgb(229, 160, 0)",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                handleDecline(oneVendor.email);
              }}
            >
              Decline
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AdminVenuesPage;
