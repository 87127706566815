import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { HomeRounded as HomeRoundedIcon } from "@mui/icons-material";
import { ROUTINGDATA } from "./ROUTINGDATA";

const Breadcrumb = ({ name }) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link
        color="inherit"
        className="breadcrumbs"
        to={ROUTINGDATA.LAND}
        onClick={() => sessionStorage.setItem("active", "dash")}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Poppins-Regular",
          }}
        >
          <HomeRoundedIcon sx={{ marginRight: "10px", fontSize: 20 }} />{" "}
          <p style={{ textDecoration: "none", fontSize: "14px" }}>Home</p>
        </div>
      </Link>

      <Typography
        color="text.primary"
        sx={{
          fontFamily: "Poppins-Regular",
          fontSize: "14px",
          color: "#3B2400",
        }}
      >
        {name}
      </Typography>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
