export const ROUTINGDATA = {
  LAND: "/",
  HOMEPAGE: "Home",
  VENUESPAGE: "",
  VENUESPAGEDETAILS: "Venues/VenueDetailsPage/:id",
  VENUESPAGEDETAILSID: "Venues/VenueDetailsPage",
  VENDORSPAGE: "Vendor",
  VENDORSPHOTOGRAPH: "/VendorsPhotograph",
  VENDORSDECPRATORS: "/VendorsDecorators",
  CONTACTS: "/Contactus",
  CONTACTS: "/Contactus",
  // VENDORS DASHBOARD
  CHARACTER: "Vendorpage",
  PLANNER: "AddPortfolio",
  ADDPHOTOS: "Addphotos",
  EDITS: "Edits",
  EDITS: "Edits",

  VENUESDASHBOARD: "VenuesDashboard",
  SIGNIN: "/SignIn",
  VENDORSIGNIN: "/VendorSignin",
  VENDORSIGNUP: "/VendorSignup",
  VENDORPASSWORD: "/VendorPassword",
  VENDOROTP: "/VendorOtp",
  VENDORCONFORM: "/VendorConform",

  //Policies pages
  PRICINGPOLICY: "/pricingpolicy",
  PRIVACYPOLICY: "/privacypolicy",
  PURCHASEPOLICY: "/purchasepolicy",
  REFUNDPOLICY: "/refundpolicy",
  TERMSANDCONDITIONS: "/termsandconditions",
  SHIPPING: "shippingpolicy",
  SUBSCRIPTIONPLAN: "SubscriptionPlan",
  FORGOTPASSWORD: "/forgotPassword",
  ADMINDASHBOARD: "/AdminDashboard",
  Dashboard: "Dashboard",
  AdminVenuesPage: "AdminVenuesPage",
  ADMINVENUESPAGEDETAILS: "details",
  ADMINVenueApproval: "VenueApproval/Venue",
  ADMINVenueApprovalPhoto: "VenueApproval/Photo",
  AdminApprovalVenue: "AdminApprovalVenue",
  ApprovalPhotoVideo: "ApprovalPhotoVideo",
};
